<button type="button" style="margin: 10px" class="btn btn-primary" (click)="onEdit()" *ngIf="adminRole() && !editMode">
    Edit
</button>

<button type="button" style="margin: 10px" class="btn btn-warning" (click)="onCancel()" *ngIf="adminRole() && editMode">
    Cancel
</button>

<button type="button" style="margin: 10px" class="btn btn-success" (click)="onUpdate()" *ngIf="adminRole() && editMode">
    Update
</button>

<div class="col-md-12" style="width: 100%;padding-top: 20px;">
    <div class="col-md-6 no-padding" style="padding-bottom: 25px; display: block; overflow: auto;"
        *ngFor="let n of news | SortByDate:'ASC';index as i; trackBy: trackArray">


        <div *ngIf="!editMode">

            <div class="col-sm-6" style="padding:0px; height:320px; overflow:hidden">
                <iframe *ngIf="n.id == 54" src="https://www.youtube.com/embed/q0ngfwbw3NM" title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen style="min-height:320px; width:100%; object-fit: cover; "></iframe>

                <img src="{{'/assets/images/news/' + n.id + '.jpg'}}" *ngIf="n.id !== 54"
                    style="min-height:320px; width:100%; object-fit: cover; " />

            </div>

            <div class="col-sm-6" style="padding:0px; height:320px">

                <div class="card-white-box" style="max-height: 320px;">
                    <div class="col-xs-2" style="padding: 0; overflow:hidden;">
                        <img src="/assets/images/star2.png" style="max-width: 100%" />
                    </div>
                    <div class="col-xs-10" style="padding: 0">
                        <div style="padding-left:10px; padding-right:10px">
                            <h4 style="margin-top:5px;"><b>{{ n.title
                                    }}</b></h4>
                        </div>

                    </div>

                    <div class="col-xs-12 style-2"
                        style="width:100%; max-height: 230px; overflow-y: auto; padding:20px 0px 20px 0px;">
                        <div [innerHtml]="n.content"></div>

                    </div>
                    <button type="button" style=" position:absolute; bottom:20px;right:20px;" class="btn btn-danger"
                        (click)="onDelete(n.id)" *ngIf="adminRole()">
                        Delete
                    </button>


                </div>
            </div>
        </div>
        <div *ngIf="editMode">

            <div class="col-sm-6" style="padding:0px; height:320px; overflow:hidden">
                <label for="file-input">
                    <img src="{{'/assets/images/news/' + n.id + '.jpg'}}"
                        style="min-height:320px; max-width:100%; object-fit: cover; " />
                </label>
                <input #file id="file-input" style="display: none" type="file" multiple />
                <!-- (change)="upload(file.files)" /> -->

            </div>

            <div class="col-sm-6" style="padding:0px; height:320px">

                <div class="card-white-box">
                    <div class="col-sm-2" style="padding: 0; overflow:hidden;">
                        <img src="/assets/images/star2.png" style="max-width: 100%" />
                    </div>
                    <div class="col-sm-10" style="padding: 0">
                        <div style="padding-left:10px; padding-right:10px">
                            <h4 style="margin-top:5px;">
                                <b>
                                    <input style="background: transparent; border:0" type="text" [(ngModel)]="n.title"
                                        required>
                                </b>
                            </h4>
                        </div>

                    </div>

                    <div class="col-xs-12" style="width:100%; padding:20px 0px 20px 0px">
                        <div>
                            <textarea style=" width: 100%;  word-wrap: break-word;
                                    height:150px; background: transparent; border:0" #textbox type="text"
                                [(ngModel)]="n.content" required></textarea>

                        </div>
                        <!--<span style="font-weight:bold;color:green;" *ngIf="message"></span>-->
                    </div>
                    <button type="button" style=" position:absolute; bottom:20px;right:20px;" class="btn btn-danger"
                        (click)="onDelete(n.id)" *ngIf="adminRole()">
                        Delete
                    </button>

                </div>

            </div>

            <h3>
                <input type="date" [ngModel]="n.date | date:'yyyy-MM-dd'" (ngModelChange)="n.date = $event" required>
            </h3>
        </div>
    </div>

</div>