import { Injectable } from '@angular/core';
import { Resolve, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { AccountingPayment } from '../payment';
import { AccountingService } from '../accounting.service';

@Injectable({
  providedIn: 'root'
})

//TODO: Change to generic payments resolver
export class AccountingPaymentResolver implements Resolve<AccountingPayment> {

  constructor(
    private accountingService: AccountingService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<AccountingPayment> {
    const paymentId = route.paramMap.get('paymentId');
    // const instructorId = route.parent.data["instructor"].id;
    return this.accountingService.getOutcome(paymentId);
  }
}
