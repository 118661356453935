import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Place } from '../place';
import { TrainingType } from '../../trainings/trainingType';
import { PlaceService } from '../place.service';
import { Currency, PaymentInfo } from '../../models/paymentInfo'
import { parseDate } from '../../utils';

@Component({
  templateUrl: './place-edit.component.html'
})
export class PlaceEditComponent implements OnInit {
  pageTitle = 'Place Edit';
  errorMessage = '';

  private currentPlace: Place;
  private originalPlace: Place;
  private dataIsValid: boolean = false;

  parseDate = parseDate;

  get isDirty(): boolean {
    return JSON.stringify(this.originalPlace) !== JSON.stringify(this.currentPlace);
  }

  get place(): Place {
    return this.currentPlace;
  }

  set place(value: Place) {
    this.currentPlace = value;
    // Clone the object to retain a copy
    this.originalPlace = Object.assign({}, value);
  }

  public trainingTypes = Object.values(TrainingType);
  public currencies = Object.values(Currency);
  constructor(private route: ActivatedRoute,
    private router: Router,
    private placeService: PlaceService) { }

  ngOnInit(): void {
    // Watch for changes to the resolve data
    this.route.data.subscribe(data => {
      const dataName = 'place';
      this.onPlaceRetrieved(data[dataName]);
    });
  }

  onPlaceRetrieved(place: Place): void {
    if (place === undefined) {
      this.place = {
        name: "",
        id: "",
        singleTicketPrice: new PaymentInfo()
      } as Place;
      this.pageTitle = 'Dodaj sezon';
    }
    else {
      this.place = place;
      this.pageTitle = `Edytuj sezon: ${this.place.name}`;
    }
  }

  isAddPage(): boolean {
    return this.place.id === "";
  }

  deletePlace(): void {
    if (!this.place.id) {
      // Don't delete, it was never saved.
      this.onSaveComplete(`${this.place.name} was deleted`);
    } else {
      if (confirm(`Czy na pewno usunąć miejsce '${this.place.name}'?`)) {
        this.placeService.deletePlace(this.place.id).subscribe(
          () => this.onSaveComplete(`${this.place.name} was deleted`),
          (error) => this.errorMessage = error
        );
      }
    }
  }

  isValid(): boolean {
    this.validate();
    return this.dataIsValid;
  }

  savePlace(): void {
    if (this.isValid()) {
      this.placeService.savePlace(this.place).subscribe(
        () => this.onSaveComplete(`${this.place.name} was saved`),
        (error) => this.errorMessage = error
      );
    } else {
      this.errorMessage = 'Please correct the validation errors.';
    }
  }

  onSaveComplete(message?: string): void {
    console.log(message);
    this.reset();
    // Navigate back to the place list
    this.router.navigate(['/manager/places']);
  }

  // Reset the data
  // Required after a save so the data is no longer seen as dirty.
  reset(): void {
    this.dataIsValid = null;
    this.currentPlace = null;
    this.originalPlace = null;
  }

  validate(): void {
    // Clear the validation object
    this.dataIsValid = false;
    if (this.place.name &&
      this.place.name.length >= 3 &&
      this.place.name.length <= 50
    ) {
      this.dataIsValid = true;
    } else {
      this.dataIsValid = false;
    }
  }
}
