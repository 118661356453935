<div class="card">
  <div class="card-header">
    {{pageTitle}}
  </div>
  <br>
  <div class="card-body">
    <div class="row" *ngIf="isAddPage()">
      <label class="col-md-2">Pakiet</label>
      <select class="jumpcamp-input" matNativeControl [(ngModel)]="sub.bundleId">
        <option *ngFor="let bundle of bundles" [value]="bundle.id">
          <!-- [selected]="sub.bundleId === bundle.id"> -->
          {{bundle.name}}
        </option>
      </select>
    </div>
    <div class="row" *ngIf="!isAddPage()">
      <label class="col-md-2">Pakiet</label>
      {{sub.bundleName}}
    </div>
    <div class="row">
      <label class="col-md-2">Od:</label>
      <input class="jumpcamp-input" type="date" [value]="sub.from | date:'yyyy-MM-dd'"
        (input)="sub.from = parseDate($event)" required>
    </div>

    <div class="row">
      <label class="col-md-2">Do:</label>
      <input class="jumpcamp-input" type="date" [value]="sub.to | date:'yyyy-MM-dd'"
        (input)="sub.to = parseDate($event)" required>
    </div>
    <br>

    <div class="row">
      <div class="col-md-4">
        <button class="btn btn-success mr-3" type="button" style="width:80px" [disabled]="!isValid()"
          (click)="saveSub()">
          Zapisz
        </button>
        <button class="btn btn-warning mr-3" type="button" style="width:80px" [routerLink]="[getReturnRoute()]"
          [relativeTo]="route">
          Anuluj
        </button>
        <!-- <button class="btn btn-danger" type="button" style="width:80px" (click)="deleteSub()" *ngIf="!isAddPage()">
          Usuń
        </button> -->
      </div>
    </div>
  </div>
</div>

<div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
</div>