<div class="card">
  <div class="card-header">
    {{pageTitle}}
  </div>
  <br>
  <div class="card-body">
    <div class="row">
      <label class="col-md-2" for="inputPaymentDate">Data</label>
      <input class="jumpcamp-input" id="inputPaymentDate" type="datetime-local" [(ngModel)]="payment.date"
        (ngModelChange)="payment.date = $event" required>
    </div>
    <div class="row">
      <label class="col-md-2">Kwota</label>
      <input class="jumpcamp-input" type="number" min="0" max="100000" required mdInput placeholder="Kwota (wymagane)"
        [(ngModel)]="payment.amount.amount">

      <select class="jumpcamp-input" matNativeControl [(ngModel)]="payment.amount.currency">
        <option *ngFor="let currency of currencies" [value]="currency">
          {{currency}}
        </option>
      </select>
    </div>
    <div class="row">
      <div class="col-md-2">Za co?</div>
      <input class="col-md-2 jumpcamp-input" type="text" placeholder="Cel" maxlength="50"
        [(ngModel)]="payment.description" name="ngModel" />
    </div>
    <div class="row">
      <label class="col-md-2">Sakiewka</label>
      <select class="jumpcamp-input" matNativeControl [compareWith]="compareFn" [ngModel]="payment.wallet"
        (ngModelChange)="onWalletChange($event)">
        <option *ngFor="let wallet of wallets" [ngValue]="wallet">
          {{wallet.name}}
        </option>
      </select>
    </div>
    <div class="row">
      <label class="col-md-2">Kategoria</label>
      <select class="jumpcamp-input" matNativeControl [compareWith]="compareFn" [ngModel]="payment.category"
        (ngModelChange)="onCategoryChange($event)">
        <option *ngFor="let category of categories" [ngValue]="category">
          {{category.name}}
        </option>
      </select>
    </div>
    <br>

    <div class="row">
      <div class="col-md-4">
        <button class="btn btn-success mr-3" type="button" style="width:80px" [disabled]="!isValid()"
          (click)="savePayment()">
          Zapisz
        </button>
        <button class="btn btn-warning mr-3" type="button" style="width:80px" [routerLink]="[getReturnRoute()]"
          [relativeTo]="route">
          Anuluj
        </button>
        <button class="btn btn-danger" type="button" style="width:80px" (click)="deletePayment()" *ngIf="!isAddPage()">
          Usuń
        </button>
      </div>
    </div>
  </div>
</div>

<div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
</div>