<nav id="menu" class="navbar navbar-inverse navbar-top">
    <div id="top" class="container">
        <div class="row box">
            <div class="col-md-1"></div>
            <div class="col-md-4 navbar-header no-padding">
                <a class="nav-link" routerLink="/">
                    <img id="logo" class="col-xs-6 col-sm-3 col-md-2" style="margin-top: 10px; padding: 0"
                        src="/assets/images/logo.png" alt="logo">
                </a>
                <button type="button" class="navbar-toggle mobile-menu" data-toggle="collapse"
                    data-target=".navbar-collapse">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
            </div>

            <div class="col-md-6 navbar-collapse collapse menu-bar dd">
                <ul class="nav navbar-nav right">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/home" routerLinkActive="active">home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/news" routerLinkActive="active">aktualności</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/about" routerLinkActive="active">o nas</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/offer" routerLinkActive="active">oferta</a>
                    </li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle"
                            [ngClass]="{ 'active': rla1.isActive || rla2.isActive || rla3.isActive || rla4.isActive|| rla5.isActive }"
                            routerLinkActive="active" id="navbarDropdownMenuLink" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <span class="nav-label">wyjazdy</span>
                            <span class="caret"></span>
                        </a>
                        <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                            <li class="nav-item">
                                <a class="nav-link text-color-turquoise" #rla1="routerLinkActive"
                                    routerLinkActive="active" style="text-align:center"
                                    [routerLink]="['/travel/soelden-11-2022']">Sölden 9-13.11.2022</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-color-turquoise" #rla2="routerLinkActive"
                                    routerLinkActive="active" style="text-align:center"
                                    routerLink="/travel/kreischberg-01-2023">Kreischberg
                                    14-21.01.2023</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-color-turquoise" #rla3="routerLinkActive"
                                    routerLinkActive="active" style="text-align:center"
                                    routerLink="/travel/zwardon-03-2023">Zwardoń
                                    03-05.03.2023</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-color-turquoise" #rla4="routerLinkActive"
                                    routerLinkActive="active" style="text-align:center"
                                    routerLink="/travel/lyngen-03-2023">Lyngen 12-19.03.2023</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link text-color-turquoise" #rla5="routerLinkActive"
                                    routerLinkActive="active" style="text-align:center"
                                    routerLink="/travel/kaprun-04-2023">Kaprun 29.04-3.05.2023</a>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/gallery" routerLinkActive="active">galeria</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/contact" routerLinkActive="active">kontakt</a>
                    </li>
                    <li *ngIf="authService.isLoggedIn()" class="nav-item">
                        <a class="nav-link" routerLink="/manager" routerLinkActive="active">manager</a>
                    </li>
                    <li *ngIf="authService.isLoggedOut()" class="nav-item">
                        <a class="nav-link" routerLink="/login" routerLinkActive="active">
                            <img class="lock" src="assets/images/icons/lock.png" alt="Log in">
                        </a>
                    </li>
                    <li *ngIf="authService.isLoggedIn()" class="nav-item">
                        <a class="nav-link" (click)="onLogout()">wyloguj</a>
                    </li>
                </ul>
            </div>
            <div class="col-md-1"></div>
        </div>
    </div>
</nav>