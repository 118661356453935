<div class="card" *ngIf="instructor">
  <div class="card-header">
    {{ instructor.firstName }} {{ instructor.lastName }}
  </div>
  <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
  </div>
  <season-component (seasonChange)="onSeasonChange($event)"></season-component>

  <div *ngIf="(bills && bills.length) || (payments && payments.length)">
    Bilans:
    <span *ngIf="currencies.length"> <strong>{{ balance[currencies[0]] | number : '1.2-2'}} {{ currencies[0]
        }}</strong></span>
    <span *ngFor="let currency of currencies | slice:1"> <strong> | {{ balance[currency] | number : '1.2-2'}} {{
        currency
        }}</strong></span>
  </div>

  <div class="table-responsive">
    <table class="jumpcamp-table">
      <caption>
        <div class="col-md-2">Rachunki</div>
        <div class="col-md-4">
          <button class="btn btn-primary" [routerLink]="['bills', 'add']" [relativeTo]="route"
            [queryParams]="{seasonId: selectedSeasonId}">
            Dodaj rachunek
          </button>
        </div>
      </caption>
      <ng-container *ngIf="bills && bills.length">
        <thead>
          <tr>
            <th>Data</th>
            <th>Nazwa</th>
            <th>Kwota</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let bill of sortedBills'>
            <td>{{ bill.creationDate | date:'yyyy-MM-dd HH:mm' }}</td>
            <td>{{ bill.name }}</td>
            <td>{{ bill.amount.amount | number : '1.2-2'}} {{ bill.amount.currency }}</td>
            <td>
              <button class="btn btn-primary" [routerLink]="['bills', bill.id, 'edit']" [relativeTo]="route">
                Edytuj
              </button>
              <button class="btn btn-danger" (click)="onBillDelete(bill.id)">
                Usuń
              </button>
            </td>
          </tr>
        </tbody>
      </ng-container>
    </table>
  </div>
  <div class="table-responsive">
    <table class="jumpcamp-table">
      <caption>
        <div class="col-md-2">Wypłaty</div>
        <div class="col-md-4">
          <button class="btn btn-primary" [routerLink]="['payments', 'add']" [relativeTo]="route"
            [queryParams]="{seasonId: selectedSeasonId}">
            Zarejestruj wypłatę
          </button>
        </div>
      </caption>
      <ng-container *ngIf="payments && payments.length">
        <thead>
          <tr>
            <th>Data</th>
            <th>Kwota</th>
            <th>Za co?</th>
            <th>Sakiewka</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let payment of sortedPayments'>
            <td>{{ payment.date | date:'yyyy-MM-dd HH:mm' }}</td>
            <td>{{ payment.amount.amount | number : '1.2-2'}} {{ payment.amount.currency }}</td>
            <td>{{ payment.description }}</td>
            <td>{{ payment.wallet?.name }}</td>
            <td>
              <button class="btn btn-primary" [routerLink]="['payments', payment.id, 'edit']" [relativeTo]="route">
                Edytuj
              </button>
              <button class="btn btn-danger" (click)="onPaymentDelete(payment.id)">
                Usuń
              </button>
            </td>
          </tr>
        </tbody>
      </ng-container>
    </table>
  </div>
</div>