<div class="card">
  <!-- <div class="card-header">
    Instruktorzy
  </div> -->

  <!-- Filter the Title   -->
  <div class="card-body">
    <div class="row">
      <div class="col-md-2">Szukaj:</div>
      <div class="col-md-4">
        <input class="jumpcamp-input" type="text" [(ngModel)]="listFilter" />
      </div>
      <button class="btn btn-primary" [routerLink]="['/manager/instructors/add']">
        Dodaj Instruktora
      </button>
    </div>

    <!-- <div class="row"
         *ngIf="listFilter">
      <div class="col-md-6">
        <h4>Instructors filtered by: {{listFilter}}</h4>
      </div>
    </div> -->

    <div class="table-responsive">
      <table class="jumpcamp-table" *ngIf="instructors && instructors.length">
        <thead>
          <tr>
            <!-- <td>
              <button type="button"
                      class="btn btn-outline-primary"
                      (click)="toggleImage()">
                {{showImage ? "Hide" : "Show"}} Poster
              </button>
            </td> -->
            <th>Imię Nazwisko</th>
            <!-- <td>Nazwisko</td> -->
            <!-- <td>Director</td> -->
            <!-- <td>Release Date</td>
            <td>MPAA Rating</td>
            <td>DVD Price</td>
            <td>5 Star Rating</td>
            <td>Audience Approval</td> -->
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let instructor of filteredInstructors'>
            <!-- <td>
              <img *ngIf="showImage && instructor.imageurl"
                   [style.width.px]="50"
                   [style.margin.px]="2"
                   [src]="instructor.imageurl"
                   [title]="instructor.title">
            </td> -->
            <td>
              <a [routerLink]="['/manager/instructors', instructor.id, 'details']">
                {{ instructor.firstName }} {{ instructor.lastName }}
              </a>
            </td>
            <!-- <td>{{ instructor.lastName }}</td> -->
            <!-- <td>{{ instructor.releaseDate | date}}</td> -->
            <!-- <td>{{ instructor.mpaa | uppercase }}</td>
            <td>{{ instructor.price | currency:'USD':'symbol'}}</td> -->
            <!-- <td>
              <mh-star [rating]="instructor.starRating"></mh-star>
            </td> -->
            <!-- <td>{{ instructor.approvalRating | percent: '1.0-0'}}</td> -->
            <td>
              <button class="btn btn-primary" [routerLink]="['/manager/instructors', instructor.id, 'edit']">
                Edytuj
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class='alert alert-danger' *ngIf='errorMessage'>
    Error: {{ errorMessage }}
  </div>
</div>