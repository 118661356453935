import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InstructorService } from '../../instructor.service';

import { Instructor } from '../../instructor';
import { Rate } from '../../../seasons/season';

@Component({
  templateUrl: './instructor-detail-rates.component.html'
})
export class InstructorDetailRatesComponent implements OnInit {
  pageTitle = 'Instructor Rates';
  instructor: Instructor;
  errorMessage: string;
  selectedSeasonId: string;
  rates: Rate[];

  constructor(private route: ActivatedRoute,
    private instructorService: InstructorService
  ) {
  }

  ngOnInit(): void {
    this.route.parent.data.subscribe(data => {
      const dataName = 'instructor';
      this.instructor = data[dataName];
    });
  }

  getRates(): void {
    this.instructorService.getRates(this.selectedSeasonId, this.instructor.id)
      .subscribe(
        (rates: Rate[]) => {
          // this.instructors = this.performSearch(instructors);
          this.rates = rates;
        },
        (error: any) => this.errorMessage = error
      );
  }

  onSeasonChange(seasonId: string): void {
    this.selectedSeasonId = seasonId;
    this.getRates();
  }
}
