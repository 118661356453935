<div class="col-xs-12 no-padding">
    <div class="col-xs-12 visible-xs-block">
        <div style="margin: auto; width: 210px; padding-top: 20px;">
            <a href="tel:+48513158285">
                <img style="width: 100px; margin-right: 5px;" src="assets/images/icons/phone.png" alt="children">
            </a>
            <a href="mailto:info@jump-camp.pl">
                <img style="width: 100px; margin-left: 5px;" src="assets/images/icons/mail.png" alt="children">
            </a>
        </div>
    </div>
    <div class="col-xs-12 col-md-7 col-lg-6 contact-content">
        <br>
        <h2>Kontakt</h2>
        <br />
        <h3>JUMP CAMP - klub sportowy dla dzieci</h3>

        <h3>Biuro - adres korespondencyjny.</h3>
        <p>
            Jump Camp Sp.z o.o. <br />
            ul. Szczęśliwa 24<br />
            43-400 Bielsko-Biała<br />
            NIP: 9372718916<br>
            REGON: 38221915900000<br>
            KRS: 0000765415<br>
            e-mail: <a href="mailto:info@jump-camp.pl">info@jump-camp.pl</a>
        </p>

        <h3>Telefon komórkowy:</h3>
        <p>
            <a href="tel:+48513158285">+48 513 158 285</a><br />
            <a href="tel:+48505675235">+48 505 675 235</a>
        </p>

        <h3>Dane do faktury:</h3>
        <p>
            Jump Camp Sp.z o.o. <br />
            ul. Szczęśliwa 24<br />
            43-400 Bielsko-Biała<br />
            NIP: 9372718916<br>
        </p>

        <h3>Dane rachunku bankowego</h3>
        <p>
            Jump Camp Sp.z o.o.<br />
            56 2530 0008 2048 1049 7044 0001<br />
        </p>
    </div>
    <div class=" col-xs-12 col-md-5 col-lg-6">
        <div class="row">
            <div class="col-xs-12">
                <img class="contact-chiled" src="assets/images/children/chiled6.png" alt="children">
            </div>
            <div class="col-xs-12 hidden-xs">
                <a href="tel:+48513158285">
                    <img style="width: 150px; position: absolute;left: -50px;bottom: 30px;"
                        src="assets/images/icons/phone.png" alt="children">
                </a>
                <a href="mailto:info@jump-camp.pl">
                    <img style="width: 150px; position: absolute;left: -250px;bottom: 30px;"
                        src="assets/images/icons/mail.png" alt="children">
                </a>
            </div>
        </div>
    </div>
</div>