import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Debt } from '../accounting/debt';
import { AccountingService } from '../accounting/accounting.service';
import { ClientParameterService } from '../clients/client-parameter.service';
import { Currency, PaymentInfo } from '../models/paymentInfo'

@Component({
  templateUrl: './debts-list.component.html'
})

export class DebtsListComponent implements OnInit {
  pageTitle = 'Debts List';
  filteredDebts: Debt[];
  debts: Debt[];
  errorMessage: string;

  get listFilter(): string {
    return this.clientParameterService.filterBy;
  }

  set listFilter(value: string) {
    this.clientParameterService.filterBy = value;
    this.filteredDebts = this.performFilter(this.listFilter);
  }

  constructor(private accountingService: AccountingService,
    private clientParameterService: ClientParameterService,
    public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.pageTitle = 'Debts List';
      if (Object.keys(params).length) {
        this.listFilter = null;
      }
      this.getDebts();
    });
  }

  getDebts(): void {
    this.accountingService.getDebts()
      .subscribe(
        (debts: Debt[]) => {
          this.debts = debts;
          this.filteredDebts = this.performFilter(this.listFilter);
        },
        (error: any) => this.errorMessage = error
      );
  }

  performFilter(filterBy: string): Debt[] {
    if (filterBy) {
      filterBy = filterBy.toLocaleLowerCase();
      return this.debts.filter((debt: Debt) =>
        (debt.clientGroup.name && debt.clientGroup.name.toLocaleLowerCase().indexOf(filterBy)) !== -1)
    } else {
      return this.debts;
    }
  }

  public currencies = Object.values(Currency);

  getAmount(amounts: PaymentInfo[], currency: string) { //TODO: Move to shared lib
    let result = null
    amounts.forEach(amount => {
      if (amount.currency == currency) {
        result = amount;
      }
    });
    return result
  }
}
