<button type="button" style="margin: 10px" class="btn btn-primary" (click)="onEdit()" *ngIf="adminRole() && !editMode">
    Edit
</button>

<button type="button" style="margin: 10px" class="btn btn-warning" (click)="onCancel()" *ngIf="adminRole() && editMode">
    Cancel
</button>

<button type="button" style="margin: 10px" class="btn btn-success" (click)="onUpdate()" *ngIf="adminRole() && editMode">
    Update
</button>

<button type="button" style="margin: 10px" class="btn btn-primary" (click)="onAddNew()" *ngIf="adminRole() && editMode">
    Add New
</button>

<div class="box2">
    <div class="col-sm-6 col-lg-3" style="margin-top: 50px; margin-bottom: 50px; display: block; overflow: auto;"
        *ngFor="let a of albums | SortByDate:'ASC';index as i; trackBy: trackArray">

        <div class="album-cover">
            <div *ngIf="!editMode">
                <figure (click)="openAlbum(a.folderName)">
                    <img src="{{'/assets/gallery/' + a.folderName + '/0.jpg' + random}}" />
                </figure>

                <div class="white-box album-description">
                    <h4>
                        {{a.title}} {{a.date | date: 'dd.MM.yyyy'}}
                    </h4>
                </div>
            </div>
            <div *ngIf="editMode">
                <label [attr.for]="'file-input_'+a.id">
                    <img src="{{'/assets/gallery/' + a.folderName + '/0.jpg' + random}}"
                        style="min-height:300px; max-width:100%; object-fit: cover; " />

                </label>
                <input #file id="file-input_{{a.id}}" style="display: none" type="file" multiple
                    (change)="upload(file.files, a.folderName)" />

                <div class="white-box album-description">
                    <h4>
                        {{a.title}} {{a.date | date: 'dd.MM.yyyy'}}
                    </h4>
                </div>
                <button type="button" style=" position:absolute; top:20px;right:20px;" class="btn btn-danger"
                    (click)="onDelete(a.id)">
                    Delete
                </button>
            </div>
        </div>
        <div *ngIf="editMode">
            <h3>
                <input type="text" [(ngModel)]="a.title" required>
            </h3>
            <h3>
                <input type="date" [ngModel]="a.date | date:'yyyy-MM-dd'" (ngModelChange)="a.date = $event" required>
            </h3>
            <h3>
                <input type="text" [(ngModel)]="a.folderName" required>
            </h3>
        </div>
    </div>
</div>