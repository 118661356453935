import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { News } from "./news";

@Injectable()
export class NewsService {

    constructor(private http: HttpClient) {
    }

    public news: Array<News> = new Array<News>();
    public userName: string;

    loadNews(): Observable<boolean> {
        return this.http.get("/content/news")
            .pipe(
                map((data: any) => {
                    this.news = data as News[];
                    //this.sortNews();
                    return true;
                }));
    }

    public addNews(newNews: News) {
        var newsItem: News = new News();
        newsItem.id = newNews.id;
        newsItem.title = newNews.title;
        newsItem.content = newNews.content;
        newsItem.date = newNews.date;

        this.saveNews(newsItem)
            .subscribe(success => {
                if (success) {
                    this.news.unshift(newsItem);
                    alert("News added");
                    //this.sortNews();
                }
            })

    }

    public updateNews(modifiedNews: News): Observable<boolean> {
        let url = "/content/news/" + modifiedNews.id;
        return this.http.put(url, modifiedNews).pipe(map((res) => {
            console.log(res);
            let newsIndex = this.news.findIndex(a => a.id == modifiedNews.id);
            this.news[newsIndex] = modifiedNews;
            return true;
        }));
    }

    saveNews(item: News): Observable<boolean> {
        return this.http.post("/content/news", item)
            .pipe(
                map((response) => {
                    item.id = (response as News).id;
                    //TODO Change image file name here by calling api instead of in /api/news POST
                    return true;
                }));
    }

    public deleteNews(id: number) {
        let url = "/content/news/" + id;
        return this.http.delete(url).pipe(map((res) => {
            console.log(res);
            this.news = this.news.filter(function (obj) {
                return obj.id !== id;
            });
            return true;
        }));
    }
}