import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Season } from '../season';

@Component({
  templateUrl: './season-details.component.html',
  styleUrls: ['./season-details.component.css'] //TODO: Move to generic place
})
export class SeasonDetailsComponent implements OnInit {
  pageTitle = 'Season Details';
  season: Season;
  errorMessage: string;

  constructor(private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.parent.data.subscribe(data => {
      const dataName = 'season';
      this.onSeasonRetrieved(data[dataName]);
    });
  }

  onSeasonRetrieved(season: Season): void {
    this.season = season;
  }

  // onBack(): void {
  //   this.router.navigate(['/seasons']);
  // }
}
