<div class="card" *ngIf="clientGroup">
  <div class="card-header">
    <!-- {{ clientGroup.firstName }} {{ clientGroup.lastName }} -->
    {{ clientGroup.name }}
  </div>
  <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
  </div>
  <season-component (seasonChange)="onSeasonChange($event)"></season-component>

  <div class="table-responsive">
    <table class="jumpcamp-table">
      <caption>
        <div class="col-md-2">Polecenia</div>
        <div class="col-md-4">
          <button class="btn btn-primary" [routerLink]="['add']" [relativeTo]="route"
            [queryParams]="{seasonId: selectedSeasonId}">
            Dodaj polecenie
          </button>
        </div>
      </caption>
      <ng-container *ngIf="recommendations && recommendations.length">
        <thead>
          <tr>
            <th>Rodzina</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let recommendation of sortedRecommendations'>
            <td>{{ recommendation.whom.name }}</td>
            <td>
              <button class="btn btn-danger" (click)="onRecommendationDelete(recommendation.id)">
                Usuń
              </button>
            </td>
          </tr>
        </tbody>
      </ng-container>
    </table>
  </div>
</div>