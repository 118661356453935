import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Instructor } from '../instructor';

@Component({
  templateUrl: './instructor-detail.component.html',
  styleUrls: ['./instructor-detail.component.css']
})
export class InstructorDetailComponent implements OnInit {
  pageTitle = 'Instructor Detail';
  instructor: Instructor;
  errorMessage: string;

  constructor(private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      const dataName = 'instructor';
      this.onInstructorRetrieved(data[dataName]);
    });
  }

  onInstructorRetrieved(instructor: Instructor): void {
    this.instructor = instructor;
  }

  // onBack(): void {
  //   this.router.navigate(['/instructors']);
  // }
}
