import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Currency, PaymentInfo } from '../../../models/paymentInfo'
import { Payment, Wallet, AccountingCategory } from '../../../seasons/season';
import { InstructorService } from '../../instructor.service';
import { WalletService } from '../../../accounting/wallet.service';
import { AccountingCategoryService } from '../../../accounting/accounting-category.service';

@Component({
  templateUrl: './instructor-detail-payment-edit.component.html'
})
export class InstructorDetailPaymentEditComponent implements OnInit {
  pageTitle = 'Payment Edit';
  errorMessage = '';

  private currentPayment: Payment;
  private originalPayment: Payment;
  private dataIsValid: boolean = false;

  public instructorId: string;
  private seasonId: string;

  public wallets: Wallet[];
  public categories: AccountingCategory[];

  get isDirty(): boolean {
    return JSON.stringify(this.originalPayment) !== JSON.stringify(this.currentPayment);
  }

  get payment(): Payment {
    return this.currentPayment;
  }

  set payment(value: Payment) {
    this.currentPayment = value;
    // Clone the object to retain a copy
    this.originalPayment = Object.assign({}, value);
  }

  constructor(private route: ActivatedRoute,
    private router: Router,
    private instructorService: InstructorService,
    private walletService: WalletService,
    private accountingCategoryService: AccountingCategoryService
  ) { }

  public currencies = Object.values(Currency);

  ngOnInit(): void {
    // Watch for changes to the resolve data
    this.route.data.subscribe(data => {
      this.instructorId = data['instructor'].id;
      const dataName = 'payment';
      this.onPaymentRetrieved(data[dataName]);
      this.getWallets();
      if (this.isAddPage()) {
        this.route.queryParams.subscribe(params => {
          this.seasonId = params['seasonId'];
          this.getCategories();
        });
      }
      else {
        this.seasonId = this.payment.season.id;
        this.getCategories();
      }
    });
  }

  getWallets(): void {
    this.walletService.getWallets()
      .subscribe(
        (wallets: Wallet[]) => {
          this.wallets = wallets;
        },
        (error: any) => this.errorMessage = error
      );
  }

  onWalletChange(wallet: Wallet) {
    this.payment.wallet = wallet;
  }

  onCategoryChange(category: AccountingCategory) {
    this.payment.category = category;
  }

  compareFn = this._compareFn.bind(this);

  _compareFn(a, b) {
    // Handle compare logic (eg check if unique ids are the same)
    return (a && b && a.id === b.id) || (!a && !b);
  }

  getCategories(): void {
    this.accountingCategoryService.getCategories(this.seasonId)
      .subscribe(
        (categories: AccountingCategory[]) => {
          this.categories = categories;
        },
        (error: any) => this.errorMessage = error
      );
  }

  onPaymentRetrieved(payment: Payment): void {
    if (payment === undefined) {
      this.payment = {
        id: "",
        date: new Date(),
        amount: new PaymentInfo()
      } as Payment;
      this.pageTitle = 'Nowa wypłata';
    }
    else {
      this.payment = payment;
      this.pageTitle = `Edytuj wypłatę: ${this.payment.id}`;
    }
  }

  isAddPage(): boolean {
    return this.payment.id === null || this.payment.id === "";
  }

  deletePayment(): void {
    if (!this.payment.id) {
      // Don't delete, it was never saved.
      this.onSaveComplete(`${this.payment.id} was deleted`);
    } else {
      if (confirm(`Czy na pewno usunąć wypłatę z dnia '${this.payment.date}'?`)) {
        this.instructorService.deletePayment(this.instructorId, this.payment.id).subscribe(
          () => this.onSaveComplete(`${this.payment.id} was deleted`),
          (error) => this.errorMessage = error
        );
      }
    }
  }

  isValid(): boolean {
    this.validate();
    return this.dataIsValid;
  }

  savePayment(): void {
    if (this.isValid()) {
      this.instructorService.savePayment(this.instructorId, this.seasonId, this.payment).subscribe(
        () => this.onSaveComplete(`${this.payment.id} was saved`),
        (error) => this.errorMessage = error
      );
    } else {
      this.errorMessage = 'Please correct the validation errors.';
    }
  }

  onSaveComplete(message?: string): void {
    console.log(message);
    this.reset();
    // Navigate back to the payments list
    this.router.navigate(['/manager/instructors', this.instructorId, 'details', 'accounting']);
  }

  // Reset the data
  // Required after a save so the data is no longer seen as dirty.
  reset(): void {
    this.dataIsValid = null;
    this.currentPayment = null;
    this.originalPayment = null;
  }

  validate(): void {
    // Clear the validation object
    this.dataIsValid = false;
    if (this.payment.date
      //TODO: Add validation
    ) {
      this.dataIsValid = true;
    } else {
      this.dataIsValid = false;
    }
  }
}
