import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ClientGroup } from '../client';

@Component({
  templateUrl: './client-group-detail.component.html',
  styleUrls: ['./client-group-detail.component.css']
})
export class ClientGroupDetailComponent implements OnInit {
  pageTitle = 'Client Group Detail';
  clientGroup: ClientGroup;
  errorMessage: string;

  constructor(private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      const dataName = 'clientGroup';
      this.onClientGroupRetrieved(data[dataName]);
    });
  }

  onClientGroupRetrieved(clientGroup: ClientGroup): void {
    this.clientGroup = clientGroup;
  }

  // onBack(): void {
  //   this.router.navigate(['/clients']);
  // }
}
