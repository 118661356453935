<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-md-2">Szukaj:</div>
      <div class="col-md-4">
        <input class="jumpcamp-input" type="text" [(ngModel)]="listFilter" />
      </div>
      <button class="btn btn-primary" [routerLink]="['/manager/seasons/add']">
        Dodaj Sezon
      </button>
    </div>

    <div class="table-responsive">
      <table class="jumpcamp-table" *ngIf="seasons && seasons.length">
        <thead>
          <tr>
            <th>Nazwa</th>
            <th>Od</th>
            <th>Do</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let season of filteredSeasons'>
            <td><a [routerLink]="['/manager/seasons', season.id, 'details']">
                {{ season.name }}
              </a></td>
            <td>
              {{ season.from | date: 'dd.MM.yyyy' }}
            </td>
            <td>
              {{ season.to | date: 'dd.MM.yyyy' }}
            </td>
            <td>
              <button class="btn btn-primary" [routerLink]="['/manager/seasons', season.id, 'edit']">
                Edytuj
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

  <div class='alert alert-danger' *ngIf='errorMessage'>
    Error: {{ errorMessage }}
  </div>
</div>