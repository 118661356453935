<div class="card" *ngIf="clientGroup">
  <div class="card-header">
    {{ clientGroup.name }}
  </div>
  <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
  </div>
  <season-component (seasonChange)="onSeasonChange($event)"></season-component>
  <br>
  <div *ngFor='let client of clientGroup.clients'>
    <client-subs [client]=client [seasonId]=selectedSeasonId></client-subs>
    <br>
  </div>
</div>