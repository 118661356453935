<div class="col-md-12">
    <div style="width: 100%">
        <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
        </div>
        <div class="col-md-6" style="padding-bottom: 25px; display: block; overflow: auto;">
            <div class="col-xs-12" style="width:100%; padding:20px 0px 20px 0px">
                <div>
                    <textarea class="jumpcamp-input" style=" width: 100%;  word-wrap: break-word;
                                    height:150px; border:0" #textbox type="text" [(ngModel)]="info.content"
                        required></textarea>
                </div>
            </div>
            <button type="button" style=" position:absolute; bottom:20px;right:20px;" class="btn btn-primary"
                (click)="onSave()" *ngIf="info.content">
                Zapisz
            </button>
        </div>

        <div class="col-md-6" style="padding-bottom: 25px; display: block; overflow: auto;">
            <div class="col-xs-12" style="width:100%; padding:20px 0px 20px 0px">
                <div [innerHtml]="info.content">
                </div>
            </div>
        </div>
    </div>
</div>