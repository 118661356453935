import { Pipe, PipeTransform } from '@angular/core';
// import { Album } from './album';

@Pipe({
    name: 'SortByDate'
})
export class SortPipe implements PipeTransform {

    transform(values: Array<{ date: Date }>, args?: string): any {

        if (args === 'ASC')
            values = values.sort(this.compare);
        else
            values = values.sort(this.compare).reverse();

        return values;
    }

    private compare(a: { date: Date }, b: { date: Date }) {
        if (a.date < b.date)
            return 1;
        if (a.date > b.date)
            return -1;
        return 0;
    }
}