<div class="col-xs-12 no-padding" style="margin-top: 50px;    margin-bottom: 50px;">
    <div class="col-xs-5">
        <img class="about-text" src="/assets/images/instructors/about.png">
    </div>
    <div class="col-xs-7">
        <div class="row instructor-row-main">
            <div class="col-xs-12 col-sm-6">
                <img class="instructor-image" src="/assets/images/instructors/Aga_Ziemska.png" />
            </div>
            <div class="col-xs-12 col-sm-6">
                <img class="instructor-image" src="/assets/images/instructors/Bartek_Ziemski.png" />
            </div>
        </div>
        <div class="row instructor-row hidden-xs">
            <div class="col-xs-6 hidden-xs">
                <img class="instructor-image" src="/assets/images/instructors/Emi_Matlak.png" />
            </div>
            <div class="col-xs-6 hidden-xs">
                <img class="instructor-image" src="/assets/images/instructors/Mikołaj_Matoga.png" />
            </div>
        </div>
    </div>
    <div class="col-xs-12">
        <div class="row instructor-row hidden-xs">
            <div class="col-xs-3 hidden-xs">
                <img class="instructor-image" src="/assets/images/instructors/Julia_Matlak.png" />
            </div>
            <div class="col-xs-3 hidden-xs">
                <img class="instructor-image" src="/assets/images/instructors/Karolina_Matlak.png" />
            </div>
            <div class="col-xs-3 hidden-xs">
                <img class="instructor-image" src="/assets/images/instructors/Tomek_Grossmann.png" />
            </div>
            <div class="col-xs-3 hidden-xs">
                <img class="instructor-image" src="/assets/images/instructors/Karola_Haratek.png" />
            </div>
        </div>
    </div>
    <div class="col-xs-12 visible-xs" style="margin-bottom: 20px">
        <div class="row instructor-row">
            <div class="col-xs-6">
                <img class="instructor-image" src="/assets/images/instructors/Emi_Matlak.png" />
            </div>
            <div class="col-xs-6">
                <img class="instructor-image" src="/assets/images/instructors/Mikołaj_Matoga.png" />
            </div>
        </div>
        <div class="row instructor-row">
            <div class="col-xs-6">
                <img class="instructor-image" src="/assets/images/instructors/Julia_Matlak.png" />
            </div>
            <div class="col-xs-6">
                <img class="instructor-image" src="/assets/images/instructors/Karolina_Matlak.png" />
            </div>
        </div>
        <div class="row instructor-row">
            <div class="col-xs-6">
                <img class="instructor-image" src="/assets/images/instructors/Tomek_Grossmann.png" />
            </div>
            <div class="col-xs-6">
                <img class="instructor-image" src="/assets/images/instructors/Karola_Haratek.png" />
            </div>
        </div>
    </div>
</div>