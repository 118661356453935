<div class="col-xs-12 no-padding">
    <div class="col-xs-12 col-sm-6 col-md-6 offer-content holiday-content" style="height: 80vh; padding-top: 0px;">
        <div class="row style-2" style="overflow: auto; height: 100%; direction: rtl; padding-bottom: 20px;">
            <div style="direction: ltr; padding-left: 10px;">
                <!-- <p> -->
                <br />

                <h1 class="holiday-title" style="color: #0071b9;">14-21.01.2023</h1>
                <h2 class="holiday-title" style="color: #0071b9">jumpowe ferie :)</h2>

                <!-- </p> -->
                <br />
                <p>
                    <b>zakwaterowanie w apartamentach + skipass 6 dni + ubezpieczenie:</b>
                </p>
                <p>
                    osoba dorosła:&ensp;&ensp;&ensp; &emsp; &emsp;<span
                        class="hidden-xs">&emsp;&emsp;&emsp;&emsp;</span> 578 eur*<br />
                    junior (2004-2006):&emsp;&emsp;<span class="hidden-xs">&emsp;&emsp;&emsp;&emsp;</span> 511
                    eur*<br />
                    dziecko (2007-2016): &emsp;<span class="hidden-xs">&emsp;&emsp;&emsp;&emsp;</span> 404 eur*<br />
                    dziecko (≥2017):&ensp;&emsp;&emsp;&emsp;<span class="hidden-xs">&emsp;&emsp;&emsp;&emsp;</span>253
                    eur*<br />

                </p>
                <p>
                    *Cena przy pełnym obłożeniu wybranego apartamentu.
                </p>
                <br>
                <p>
                    <b>szkolenie narciarskie: &emsp;320 eur</b><br />
                    6 dni szkolenia w małych grupach (max 6 osób)<br />
                    z treningiem na tyczkach dla dzieci oraz dorosłych.<br />
                </p>
                <br>
                <p>
                    <b>przykładowy plan dnia:</b><br />
                    9:00-12:00 zbiórka na stoku, rozgrzewka, trening<br />
                    12:00-13:00 przerwa w restauracji z rodzicami<br />
                    13:00-15:30 trening<br />
                    19:00-20:00 wideoanaliza lub zajęcia ogólnorozwojowe<br />
                </p>
                <br>
                <p>
                    <b>zakwaterowanie:</b><br />
                    w apartamentach <a
                        href="https://www.alps-resorts.com/urlaubsresorts/details/ferienpark-kreischberg">Ferien Park
                        Kreischberg</a>,<br />
                    500m od dolnej stacji gondoli, basen i wellness w oddzielnym budynku.<br />
                </p>
                <br>
                <p>
                    <b>do wyboru:</b><br />
                    <br />
                    <b><a
                            href="https://booking.alps-resorts.com/en/rentals/house-st-georgen-am-kreischberg-reihenhaus-35-m-troadkasten-ferienpark-kreischberg-220874.html">Troadkasten
                            35 m²</a></b> dla 3 osób<br />
                    (pokój dzienny z aneksem, sypialnia z podwójnym łóżkiem i łóżkiem na antresoli, łazienka)<br />
                    <br />
                    <b><a
                            href="https://booking.alps-resorts.com/en/rentals/house-st-georgen-am-kreischberg-reihenhaus-41-m-troadkasten-ferienpark-kreischberg-220946.html">Troadkasten
                            41 m²</a></b> dla 4 osób<br />
                    (pokój dzienny z aneksem, sypiania z piętrowym łóżkiem, antresola z podwójnym łóżkiem,
                    łazienka)<br />
                    <br />
                    <b><a
                            href="https://booking.alps-resorts.com/en/rentals/house-st-georgen-am-kreischberg-reihenhaus-49-m-troadkasten-typ-1-ferienpark-kreischberg-221456.html?fbclid=IwAR36MjnGwj27eiL6AmVOcQ2GVLkcoGbudQnMFUrH21IXaY9rCkdP8cV_dSQ">Troadkasten
                            49 m²</a></b> dla 5 osób<br />
                    (pokój dzienny z aneksem, sypialnia z podwójnym łóżkiem, sypialnia z 3 łóżkami pojedynczymi na
                    piętrze, łazienka)<br />
                    <br />
                    <b><a
                            href="https://booking.alps-resorts.com/en/rentals/house-st-georgen-ob-murau-ferienhaus-16-60m-ferienpark-kreischberg-221705.html">House
                            60 m²</a></b> dla 6 osób<br />
                    (pokój dzienny z aneksem, sypialnia z podwójnym łóżkiem, sypialnia z 2 łóżkami piętrowymi na
                    piętrze, łazienka)<br />
                    <br />
                    <b><a
                            href="https://booking.alps-resorts.com/vermietung/haus-st-georgen-am-kreischberg-ferienhaus-lilly-22-ferienpark-kreischberg-226118.html?fbclid=IwAR0aozSGAowB73WCqL5u6cmhpfMDfiua1cnXiyr98WDKALcbrE19GJ7ClKE">House
                            120 m²</a></b> dla 8-10 osób<br />
                    (pokój dzienny z aneksem, 2 sypialnie z podwójnymi łóżkami, sypialnia z 2 łóżkami pojedynczymi,
                    sypialnia z 2 łóżkami pojedynczymi i rozkładaną sofą, 2 łazienki 2 dodatkowe wc)<br />
                </p>
                <br />
                <p>
                    <b>ośrodek narciarski:</b><br />
                    <a href="https://www.bergfex.pl/kreischberg/">Kreischberg/Murau</a>
                </p>
                <br>
                <button style="display: block; margin-left: auto; margin-right: auto;" id="join-us-button"
                    class="button-round color-blue">jadę!</button>
            </div>
        </div>
    </div>
    <div class="col-sm-6 col-md-6 hidden-xs" style="height: 80vh; padding-left: 0px;">
        <img style="display: block; width:100%; height: 100%; object-fit: cover; margin: auto; margin-top: 0px; mari !important;"
            class="hidden-xs" src="/assets/images/icons/kreischberg_1.jpeg" alt="children">
        <br />
        <!-- <button style="display: block; margin: auto;" id="join-us-button"
            class="button-round color-blue">jadę!</button> -->
    </div>
</div>