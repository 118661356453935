import { PaymentInfo } from '../models/paymentInfo'
import { Instructor } from '../instructors/instructor'

/* Defines the instructor entity */
export class InstructorTraining {
  id: string | null;
  firstName: string;
  lastName: string;
  paymentCorrection: PaymentInfo = new PaymentInfo();
}

export function ToInstructorTraining(instructor: Instructor): InstructorTraining {
  return {
    id: instructor.id,
    firstName: instructor.firstName,
    lastName: instructor.lastName,
    paymentCorrection: new PaymentInfo()
  } as InstructorTraining;
}