import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Album } from "./album";

@Injectable()
export class DataService {

    constructor(private http: HttpClient) {
    }

    public albums: Array<Album> = new Array<Album>();

    public deleteAlbum(id: number): Observable<boolean> {
        let url = "/content/albums/" + id;
        return this.http.delete(url).pipe(map((res) => {
            console.log(res);
            this.albums = this.albums.filter(function (obj) {
                return obj.id !== id;
            });
            return true;
        }));
    }

    public updateAlbum(modifiedAlbum: Album): Observable<boolean> {
        let url = "/content/albums/" + modifiedAlbum.id;
        return this.http.put(url, modifiedAlbum).pipe(
            map((res) => {
                console.log(res);
                let albumIndex = this.albums.findIndex(a => a.id == modifiedAlbum.id);
                if (albumIndex == -1) {
                    this.albums.unshift(res as Album);
                }
                else {
                    this.albums[albumIndex] = res as Album;
                }
                return true;
            }));
    }

    loadAlbums(): Observable<boolean> {
        return this.http.get("/content/albums")
            .pipe(
                map((data) => {
                    this.albums = data as Album[];
                    return true;
                }));
    }
}