import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../../shared/auth.service'
import { Router } from "@angular/router";
@Component({
  selector: 'manager-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})

export class ManagerNavigationComponent implements OnInit {
  constructor(
    public authService: AuthService,
    private router: Router,
    private _changeDetectorRef: ChangeDetectorRef) {
  }

  ngAfterViewChecked(): void {
    this._changeDetectorRef.detectChanges();
  }

  ngOnInit() {
  }

  onLogout() {
    this.authService.logout();
    this.router.navigate(["/login"])
  }

}
