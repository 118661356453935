import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Season } from '../season';
import { SeasonService } from '../season.service';
import { SeasonParameterService } from './season-parameter.service';

@Component({
  templateUrl: './season-list.component.html'
})
export class SeasonListComponent implements OnInit {
  pageTitle = 'Season List';
  filteredSeasons: Season[];
  seasons: Season[];
  errorMessage: string;

  get listFilter(): string {
    return this.seasonParameterService.filterBy;
  }
  set listFilter(value: string) {
    this.seasonParameterService.filterBy = value;
    this.filteredSeasons = this.performFilter(this.listFilter);
  }

  constructor(private seasonService: SeasonService,
    private seasonParameterService: SeasonParameterService,
    public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.pageTitle = 'Season List';
      // If parameters are passed in,
      // clear any existing filter
      if (Object.keys(params).length) {
        this.listFilter = null;
      }
      this.getSeasons();
    });
  }

  getSeasons(): void {
    this.seasonService.getSeasons()
      .subscribe(
        (seasons: Season[]) => {
          this.seasons = seasons;
          this.filteredSeasons = this.performFilter(this.listFilter);
        },
        (error: any) => this.errorMessage = error
      );
  }

  // Local filter
  performFilter(filterBy: string): Season[] {
    if (filterBy) {
      filterBy = filterBy.toLocaleLowerCase();
      return this.seasons.filter((season: Season) =>
        season.name.toLocaleLowerCase().indexOf(filterBy) !== -1)
    } else {
      return this.seasons;
    }
  }
}
