import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { Subscription } from './subscription';
import { ClientService } from './client.service';

@Injectable({
  providedIn: 'root'
})
export class SubResolver implements Resolve<Subscription> {

  constructor(private clientService: ClientService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<Subscription> {
    const clientId = route.paramMap.get('clientId');
    const subId = route.paramMap.get('subId');
    console.log(route)
    return this.clientService.getSub(clientId, subId);
  }
}
