<div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
</div>

<div class="row">
  <season-component class="col-md-2" (seasonChange)="onSeasonChange($event)"></season-component>
</div>

<div class="table-responsive">
  <table class="jumpcamp-table">
    <caption>
      <div class="col-md-4">
        <button class="btn btn-primary" [routerLink]="['/manager/accounting/out/add']"
          [queryParams]="{seasonId: selectedSeasonId}">
          Zarejestruj wydatek
        </button>
      </div>
    </caption>

    <ng-container *ngIf="outcomes && outcomes.length">
      <thead>
        <tr>
          <th>Data</th>
          <th>Podmiot</th>
          <th>Kwota</th>
          <th>Sakiewka</th>
          <th>Za co?</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor='let outcome of sortedOutcomes'>
          <td>{{ outcome.date | date:'yyyy-MM-dd'}}</td>
          <td>{{ outcome.entity?.name }}</td>
          <td>{{ outcome.amount?.amount }} {{ outcome.amount?.currency }}</td>
          <td>{{ outcome.wallet?.name }}</td>
          <td>{{ outcome.description }}</td>
          <td>
            <button class="btn btn-primary" [routerLink]="['/manager/accounting/out', outcome.id, 'edit']">
              Edytuj
            </button>
          </td>
          <td>
            <button class="btn btn-danger" (click)="onPaymentDelete(outcome.id)">
              Usuń
            </button>
          </td>
        </tr>
      </tbody>
    </ng-container>
  </table>
</div>