<div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
</div>

<div class="col-md-12">
  <season-component class="col-md-2" (seasonChange)="onSeasonChange($event)"></season-component>
</div>
<div class="table-responsive col-md-12">
  <table class="jumpcamp-table">
    <caption>
      <div class="col-md-4">
        <button class="btn btn-primary" [routerLink]="['add']" [relativeTo]="route"
          [queryParams]="{seasonId: selectedSeasonId}">
          Zarejestruj wpłatę
        </button>
      </div>
    </caption>

    <ng-container *ngIf="incomes && incomes.length">
      <thead>
        <tr>
          <th>Data</th>
          <th>Podmiot</th>
          <th>Kwota</th>
          <th>Sakiewka</th>
          <th>Za co?</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor='let income of sortedIncomes'>
          <td>{{ income.date | date:'yyyy-MM-dd'}}</td>
          <td>{{ income.entity?.name }}</td>
          <td>{{ income.amount?.amount }} {{ income.amount?.currency }}</td>
          <td>{{ income.wallet?.name }}</td>
          <td>{{ income.description }}</td>
          <td>
            <button class="btn btn-primary" [routerLink]="[income.id, 'edit']" [relativeTo]="route">
              Edytuj
            </button>
          </td>
          <td>
            <button class="btn btn-danger" (click)="onPaymentDelete(income.id)">
              Usuń
            </button>
          </td>
        </tr>
      </tbody>
    </ng-container>
  </table>
</div>