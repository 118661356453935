<div class="card" *ngIf="client">

  <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
  </div>

  <div class="table-responsive">
    <table class="jumpcamp-table">
      <caption>
        <div class="col-md-2">{{ client.firstName }} {{ client.lastName }}</div>
        <div class="col-md-4">
          <button class="btn btn-primary" [routerLink]="['..', 'persons', client.id, 'subs', 'add']"
            [relativeTo]="route" [queryParams]="{seasonId: _seasonId}">
            Dodaj subskrypcję
          </button>
        </div>
      </caption>
      <ng-container *ngIf="subs && subs.length">
        <thead>
          <tr>
            <th>Pakiet</th>
            <th>Od</th>
            <th>Do</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let sub of subs'>
            <td>{{ sub.bundleName }}</td>
            <td>{{ sub.from | date: 'dd.MM.yyyy'}}</td>
            <td>{{ sub.to | date: 'dd.MM.yyyy' }}</td>
            <td>
              <button class="btn btn-primary" [routerLink]="['..', 'persons', client.id, 'subs', sub.id, 'edit']"
                [relativeTo]="route">
                Edytuj
              </button>
              <button *ngIf="isInTheFuture(sub.from)" class="btn btn-danger" (click)="onSubDelete(sub.id)">
                Usuń
              </button>
            </td>
          </tr>
        </tbody>
      </ng-container>
    </table>
  </div>
</div>