<div style="height: 100%">
  <mat-toolbar class="menu-top">
    <mat-toolbar-row>
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" color="primary">
        <mat-icon class="white-icon" aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <div class="col-xs-10"></div>
      <div *ngIf="authService.isLoggedOut()" class="nav-item col-md-2">
        <a class="nav-link" routerLink="/login" routerLinkActive="active">
          <img class="lock" src="assets/images/icons/lock.png" alt="Log in">
        </a>
      </div>
      <div *ngIf="authService.isLoggedIn()" class="nav-item">
        <a class="nav-link" (click)="onLogout()" style="cursor: pointer;">wyloguj</a>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-sidenav-container style="height: 90%;" class="example-container">
    <mat-sidenav style="height: 100%" #drawer mode="side" opened role="navigation" class="menu-left">
      <mat-nav-list>
        <mat-list-item [routerLink]="['trainings']"><span
            [routerLinkActive]="['is-active']">Treningi</span></mat-list-item>
        <mat-list-item [routerLink]=" ['instructors']"><span
            [routerLinkActive]="['is-active']">Instruktorzy</span></mat-list-item>
        <mat-list-item [routerLink]="['families']"><span
            [routerLinkActive]="['is-active']">Klienci</span></mat-list-item>
        <mat-list-item [routerLink]="['debts']"><span [routerLinkActive]="['is-active']">Komornik</span></mat-list-item>
        <mat-list-item [routerLink]="['places']"><span [routerLinkActive]="['is-active']">Miejsca</span></mat-list-item>
        <mat-list-item [routerLink]="['seasons']"><span [routerLinkActive]="['is-active']">Sezony</span></mat-list-item>
        <mat-list-item [routerLink]="['accounting']"><span
            [routerLinkActive]="['is-active']">Rozliczenia</span></mat-list-item>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="style-2" style="overflow-x: hidden; overflow-y: auto;">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>