import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientService } from '../../client.service';

import { ClientGroup, Client } from '../../client';

import { CalculateAge } from '../../../utils';

@Component({
  templateUrl: './client-group-detail-subs.component.html'
})

export class ClientGroupDetailSubsComponent implements OnInit {
  pageTitle = 'ClientGroup Subs';
  clientGroup: ClientGroup;
  errorMessage: string;
  selectedSeasonId: string;

  constructor(private route: ActivatedRoute,
    private clientService: ClientService
  ) {
  }

  ngOnInit(): void {
    this.route.parent.data.subscribe(data => {
      const dataName = 'clientGroup';
      this.clientGroup = data[dataName];
      this.getClients();
    });
  }

  getClients(): void {
    this.clientService.getClients(this.clientGroup.id)
      .subscribe(
        (clients: Client[]) => {
          this.clientGroup.clients = clients;
        },
        (error: any) => this.errorMessage = error
      );
  }

  onSeasonChange(seasonId: string): void {
    this.selectedSeasonId = seasonId;
  }
}
