import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ClientService } from '../../client.service';
import { SeasonService } from '../../../seasons/season.service';
import { parseDate } from '../../../utils';
import { Subscription } from '../../subscription';
import { Bundle } from '../../../seasons/bundle';
import { Season } from '../../../seasons/season';

@Component({
  templateUrl: './client-subs-edit.component.html'
})
export class ClientSubEditComponent implements OnInit {
  pageTitle = 'Sub Edit';
  errorMessage = '';

  private currentSub: Subscription;
  private originalSub: Subscription;
  private dataIsValid: boolean = false;

  private clientId: string;
  public clientGroupId: string;
  private seasonId: string;
  bundles: Bundle[];

  parseDate(event: Event): Date | null {
    let value = (event.target as HTMLInputElement).value;
    return parseDate(value)
  }

  get isDirty(): boolean {
    return JSON.stringify(this.originalSub) !== JSON.stringify(this.currentSub);
  }

  get sub(): Subscription {
    return this.currentSub;
  }

  set sub(value: Subscription) {
    this.currentSub = value;
    // Clone the object to retain a copy
    this.originalSub = Object.assign({}, value);
  }

  constructor(public route: ActivatedRoute,
    private seasonService: SeasonService,
    private router: Router,
    private clientService: ClientService) { }

  ngOnInit(): void {
    // Watch for changes to the resolve data

    this.route.paramMap.subscribe(paramMap => {
      this.clientId = paramMap.get('clientId');
      this.clientGroupId = paramMap.get('id');
    });

    this.route.data.subscribe(data => {
      const dataName = 'sub';
      this.onSubRetrieved(data[dataName]);

      if (this.isAddPage()) {
        this.route.queryParams.subscribe(params => {
          this.seasonId = params['seasonId'];
          this.getBundles();
          this.setNewBundleDefaultDates();
        });
      }
    });
  }

  onSubRetrieved(sub: Subscription): void {
    if (sub === undefined || sub === null) {
      this.sub = {
        id: "",
        bundleId: "",
        bundleName: "",
        from: null,
        to: null
      } as Subscription;
      this.pageTitle = 'Nowa subscrypcja';
    }
    else {
      this.sub = sub;
      this.pageTitle = `Edytuj subscrypcję pakietu: ${this.sub.bundleName}`;
    }
  }

  isAddPage(): boolean {
    return this.sub === undefined || this.sub === null || this.sub.id === null || this.sub.id === "";
  }

  getReturnRoute(): string {
    let navigateTo = '../../../../../subs';
    if (this.isAddPage()) {
      navigateTo = '../../../../subs'
    }
    return navigateTo;
  }

  setNewBundleDefaultDates(): void {
    this.seasonService.getSeason(this.seasonId)
      .subscribe(
        (season: Season) => {
          this.sub.from = season.from; //TODO: Or set to date.now() if season.from is in the past
          this.sub.to = season.to;
        },
        (error: any) => this.errorMessage = error
      );
  }

  getBundles(): void {
    this.seasonService.getBundles(this.seasonId)
      .subscribe(
        (bundles: Bundle[]) => {
          this.bundles = bundles;
        },
        (error: any) => this.errorMessage = error
      );
  }
  // deleteClient(): void {
  //   if (!this.client.id) {
  //     // Don't delete, it was never saved.
  //     this.onSaveComplete(`${this.client.firstName} ${this.client.lastName} was deleted`);
  //   } else {
  //     if (confirm(`Czy na pewno usunąć osobę '${this.client.firstName} ${this.client.lastName}'?`)) {
  //       this.clientService.deleteClient(this.clientGroupId, this.client.id).subscribe(
  //         () => this.onSaveComplete(`${this.client.firstName} ${this.client.lastName} was deleted`),
  //         (error) => this.errorMessage = error
  //       );
  //     }
  //   }
  // }

  isValid(): boolean {
    this.validate();
    return this.dataIsValid;
  }

  saveSub(): void {
    if (this.isValid()) {
      this.clientService.saveSub(this.clientId, this.seasonId, this.sub).subscribe(
        () => this.onSaveComplete(`Subscription for bundle ${this.sub.bundleName} was saved`),
        (error) => this.errorMessage = error
      );
    } else {
      this.errorMessage = 'Please correct the validation errors.';
    }
  }

  onSaveComplete(message?: string): void {
    console.log(message);
    var goBackTo = this.getReturnRoute();
    this.reset();
    // Navigate back to the clients list
    this.router.navigate([goBackTo], { relativeTo: this.route });
  }

  // Reset the data
  // Required after a save so the data is no longer seen as dirty.
  reset(): void {
    //TODO: Commented out cause triggered errors in console
    // this.dataIsValid = null;
    // this.currentSub = null;
    // this.originalSub = null;
  }

  validate(): void {
    // Clear the validation object
    this.dataIsValid = false;
    if (this.sub.bundleId &&
      this.sub.from &&
      this.sub.to
      //TODO: Add validation
    ) {
      this.dataIsValid = true;
    } else {
      this.dataIsValid = false;
    }
  }
}
