import { Component, OnInit, Input, ChangeDetectorRef } from "@angular/core";
import { AuthService } from '../shared/auth.service';
import { NewsService } from "./newsService";
import { News } from "./news";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";

@Component({
    selector: "news-list",
    templateUrl: "newsList.component.html",
    styleUrls: []
})

export class NewsList implements OnInit {
    public editMode: boolean;
    public news: Array<News> = new Array<News>();
    private message: string;
    private random: string = ''; //used to refresh images after choosing new ones


    trackArray(index: number, news: { id: any }) {
        return news.id;
    }


    constructor(private router: Router,
        private authService: AuthService,
        private data: NewsService, private http: HttpClient, private cd: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.data.loadNews()
            .subscribe(success => {
                if (success) {
                    this.news = this.data.news.map(x => Object.assign({}, x));

                }
            });
    }

    comparer(otherArray) {
        return function (current) {
            return otherArray.filter(function (other) {
                return (JSON.stringify(current) === JSON.stringify(other));
            }).length == 0;
        }
    }

    adminRole() {
        if (this.authService.isLoggedIn()) {
            //TODO: Add checking user role
            return true;
        }
        return false;
    }

    onEdit() {

        this.editMode = true;
    }

    onCancel() {
        this.news = this.data.news.map(x => Object.assign({}, x));
        this.editMode = false;
    }


    onUpdate() {
        this.editMode = false;
        //Find differences (changes made by user)

        let modifiedNews = this.news.filter(this.comparer(this.data.news));
        modifiedNews.forEach((news) => {
            this.data.updateNews(news).subscribe(value => {
                if (value) {
                    alert("Changes saved :)");
                }
                else {
                    this.onCancel();
                    alert("Something went wrong :(");
                    this.message = "Something went wrong :(";
                }
            });
        });

    }

    onDelete(id) {
        if (confirm("Are you sure to delete permanently?")) {
            this.data.deleteNews(id).subscribe(value => {
                if (value) {
                    this.news = this.news.filter(function (obj) {
                        return obj.id !== id;
                    });
                }
                else {
                    this.message = "Something went wrong :(";
                }
            });
        }
    }
}