import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TrainingService } from './training.service';

import { Training } from './training';
import { TrainingType } from './trainingType';

@Component({
  templateUrl: './trainings-list.component.html'
})
export class TrainingsListComponent implements OnInit {
  pageTitle = 'Trainings';
  errorMessage: string;
  selectedSeasonId: string;
  trainings: Training[];
  filteredTrainings: Training[];
  selectedMonth: Date = new Date();

  private _showAll: boolean;
  public set showAll(value: boolean) {
    this._showAll = value;
    this.filteredTrainings = this.filterTrainings();
  }
  public get showAll(): boolean {
    return this._showAll;
  }

  constructor(private route: ActivatedRoute,
    private router: Router,
    private trainingService: TrainingService
  ) {
  }

  ngOnInit(): void {
  }

  getColor(trainingType: TrainingType) {
    switch (trainingType) {
      case TrainingType.Group: {
        return "rgb(0, 102, 102, 0.3)";
      }
      default: {
        return "--main-bg-color-white-box";
      }
    }
  }

  futureTrainings(): Training[] {
    return this.filteredTrainings.filter((training: Training) =>
      (new Date(training.from) > new Date()));
  }

  pastTrainings(): Training[] {
    return this.filteredTrainings.filter((training: Training) =>
      (new Date(training.from) <= new Date()));
  }

  getTrainings(): void {
    this.trainingService.getTrainings(this.selectedSeasonId)
      .subscribe(
        (trainings: Training[]) => {
          this.trainings = trainings;
          this.filteredTrainings = this.filterTrainings();
        },
        (error: any) => this.errorMessage = error
      );
  }

  filterTrainings(): Training[] {
    if (this._showAll) {
      return this.trainings;
    }
    else {
      return this.trainings.filter((training: Training) =>
        (new Date(training.from)).getMonth() == (new Date(this.selectedMonth)).getMonth() &&
        (new Date(training.from)).getFullYear() == (new Date(this.selectedMonth)).getFullYear())
    }
  }

  onMonthSelected(event: Date, month: Date) {
    this.selectedMonth = event;
    this.filteredTrainings = this.filterTrainings();
  }

  onSeasonChange(seasonId: string): void {
    this.selectedSeasonId = seasonId;
    this.getTrainings();
  }
}
