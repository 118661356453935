import { Component, ChangeDetectorRef } from "@angular/core";
import { NewsService } from "./newsService";
import { News } from "./news";
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http'
import { Observable } from "rxjs";

@Component({
    selector: "news-new-item",
    templateUrl: "newsNewItem.component.html",
    styleUrls: []
})
export class NewsNewItem {

    public newItem: News = {
        id: 0,
        title: "NEW POST",
        content: "<p>Ruszyły zapisy na sezon 2018 / 2019 JUMP CAMP.Zajęcia odbywać się będą w soboty i niedziele rano na stokach w Wisle i Szczyrku. </p><p>Szczegóły w zakładce <a href='https:/ / localhost: 44309/Home/News'>oferta</a></p><p>Serdecznie zapraszamy do kontaktu <a href='mailto: info@jump - camp.pl ? Subject = JumpCamp' target='_top'>mailowego</a> lub telefonicznego.</p>",
        date: new Date()

    };

    newImageUrl = "/assets/images/news/" + this.newItem.id + ".jpg";

    constructor(private data: NewsService, private http: HttpClient, private cd: ChangeDetectorRef) {
    }

    public progress: number;
    public message: string;

    //TODO File verification - only images allowed
    upload(files) {
        if (files.length === 0)
            return;

        const formData = new FormData();
        console.log(files);
        let file = files[0];
        formData.append(file.name, file);
        //for (let file of files)
        //    formData.append(file.name, file);
        formData.append('folderName', 'images/news');
        formData.append('fileName', "" + this.newItem.id + ".jpg");
        const uploadReq = new HttpRequest('POST', `/content/upload`, formData, {
            reportProgress: true,
        });

        this.http.request(uploadReq).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress)
                this.progress = Math.round(100 * event.loaded / event.total);
            else if (event.type === HttpEventType.Response) {
                this.newImageUrl += '?random+\=' + Math.random();
                this.cd.detectChanges();
                this.message = event.body.toString();
            }
        });
    }


    onUpload() {
        this.newItem.date = new Date();
        this.data.addNews(this.newItem);
    }

}