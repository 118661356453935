import { Pipe, PipeTransform } from '@angular/core';
import { ClientTraining } from '../manager/trainings/client';

@Pipe({
    name: 'SortByLevel'
})
export class LevelSortPipe implements PipeTransform {

    transform(values: Array<ClientTraining>, args?: string): any {

        if (args === 'ASC')
            values = values.sort(this.compare);
        else
            values = values.sort(this.compare).reverse();

        return values;
    }

    private compare(a: ClientTraining, b: ClientTraining) {
        if (a.level > b.level)
            return 1;
        if (a.level < b.level)
            return -1;
        return 0;
    }
}