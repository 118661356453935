import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Training } from './training';

@Injectable({
  providedIn: 'root'
})
export class TrainingService {
  // private seasonsUrl = window.location.origin + '/api/seasons';
  // private trainingsUrl = window.location.origin + '/api/trainings';
  private seasonsUrl = '/manager/seasons';
  private trainingsUrl = '/manager/trainings';

  constructor(private http: HttpClient) { }

  getTrainings(seasonId: string): Observable<Training[]> {
    const url = `${this.seasonsUrl}/${seasonId}/trainings`;
    return this.http.get<Training[]>(url)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getTraining(trainingId: string): Observable<Training> {
    if (trainingId === "") {
      return of(this.initializeTraining());
    }
    const url = `${this.trainingsUrl}/${trainingId}`;
    return this.http.get<Training>(url)
      .pipe(
        tap(data => console.log('Data: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  deleteTraining(trainingId: string): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.trainingsUrl}/${trainingId}`;
    return this.http.delete<Training>(url, { headers })
      .pipe(
        tap(data => console.log('deleteTraining: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private initializeTraining(): Training {
    // Return an initialized object
    return {
      id: '',
      type: null,
      from: null,
      to: null,
      cancelled: false,
      instructors: [],
      clients: [],
      place: null,
      instructorTicketPrice: null
    };
  }

  saveTraining(seasonId: string, training: Training): Observable<Training> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    if (training.id === "") {
      return this.createTraining(seasonId, training, headers);
    }
    return this.updateTraining(training, headers);
  }

  private createTraining(seasonId: string, training: Training, headers: HttpHeaders): Observable<Training> {
    training.id = "";
    const url = `${this.seasonsUrl}/${seasonId}/trainings`;
    return this.http.post<Training>(url, training, { headers })
      .pipe(
        tap(data => console.log('createTraining: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private updateTraining(training: Training, headers: HttpHeaders): Observable<Training> {
    const url = `${this.trainingsUrl}/${training.id}`;
    return this.http.put<Training>(url, training, { headers })
      .pipe(
        tap(data => console.log('updateTraining: ' + training.id)),
        catchError(this.handleError)
      );
  }

  private handleError(err: any) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.error}`;
    }
    console.error(err);
    return throwError(errorMessage);
  }
}
