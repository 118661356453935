<div class="col-md-12">

    <div style="width: 100%">

        <div class="col-md-6" style="padding-bottom: 25px; display: block; overflow: auto;">

            <div class="col-sm-6" style="padding:0px; height:300px; overflow:hidden">
                <label for="file-input">
                    <img src="{{newImageUrl}}" style="min-height:300px; max-width:100%; object-fit: cover; " />
                </label>
                <input #file id="file-input" style="display: none" type="file" multiple (change)="upload(file.files)" />

            </div>

            <div class="col-sm-6" style="padding:0px; height:300px">

                <div class="card-white-box">
                    <div class="col-sm-2" style="padding: 0; overflow:hidden;">
                        <img src="/assets/images/star2.png" style="max-width: 100%" />
                    </div>
                    <div class="col-sm-10" style="padding: 0">
                        <div style="padding-left:10px; padding-right:10px">
                            <h4 style="margin-top:5px;">
                                <b>
                                    <input style="background: transparent; border:0" type="text"
                                        [(ngModel)]="newItem.title" required>
                                    <!-- <textarea style="background: transparent; border:0" type="text" [(ngModel)]="newItem.title" required></textarea> -->
                                </b>
                            </h4>
                        </div>

                    </div>

                    <div class="col-xs-12" style="width:100%; padding:20px 0px 20px 0px">
                        <div>
                            <textarea style=" width: 100%;  word-wrap: break-word;
                                    height:150px; background: transparent; border:0" #textbox type="text"
                                [(ngModel)]="newItem.content" required></textarea>

                        </div>
                        <!--<span style="font-weight:bold;color:green;" *ngIf="message"></span>-->
                    </div>
                    <button type="button" style=" position:absolute; bottom:20px;right:20px;" class="btn btn-primary"
                        (click)="onUpload()" *ngIf="message || newItem.title || newItem.content">
                        Add new
                    </button>



                </div>
            </div>

            <h3>
                <input type="date" [ngModel]="newItem.date | date:'yyyy-MM-dd'" (ngModelChange)="newItem.date = $event"
                    required>
            </h3>
        </div>


        <div class="col-md-6" style="padding-bottom: 25px; display: block; overflow: auto;">

            <div class="col-sm-6" style="padding:0px; height:300px; overflow:hidden">
                <img src="{{newImageUrl}}" style="min-height:300px; max-width:100%; object-fit: cover; " />

            </div>

            <div class="col-sm-6" style="padding:0px; height:300px">

                <div class="card-white-box">
                    <div class="col-sm-2" style="padding: 0; overflow:hidden;">
                        <img src="/assets/images/star2.png" style="max-width: 100%" />
                    </div>
                    <div class="col-sm-10" style="padding: 0">
                        <div style="padding-left:10px; padding-right:10px">
                            <h4 style="margin-top:5px;">
                                <b>
                                    {{newItem.title}}
                                </b>
                            </h4>
                        </div>

                    </div>

                    <div class="col-xs-12" style="width:100%; padding:20px 0px 20px 0px">
                        <div [innerHtml]="newItem.content">
                        </div>
                        <!--<span style="font-weight:bold;color:green;" *ngIf="message"></span>-->
                    </div>



                </div>
            </div>

        </div>


    </div>
</div>