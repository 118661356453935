<div class="card" *ngIf="season">
  <div class="card-header">
    {{ season.name }}
  </div>
  <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
  </div>

  <div class="table-responsive">
    <table class="jumpcamp-table">
      <caption>
        <div class="col-md-2">Pakiety</div>
        <div class="col-md-4">
          <button class="btn btn-primary" [routerLink]="['add']" [relativeTo]="route">
            Dodaj pakiet
          </button>
        </div>
      </caption>
      <thead>
        <tr>
          <th>Nazwa</th>
          <th>Stawka treningowa</th>
          <th>Treningi</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor='let bundle of bundles'>
          <td>{{ bundle.name }}</td>
          <td>{{ bundle.trainingPrice.amount }} {{ bundle.trainingPrice.currency }}</td>
          <td>{{ bundle.trainings.length }}</td>
          <td>
            <button class="btn btn-primary" [routerLink]="[bundle.id, 'edit']" [relativeTo]="route">
              Edytuj
            </button>
            <!-- <button class="btn btn-danger" (click)="onBundleDelete(bundle.id)">
                Usuń
              </button> -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>