<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-md-2">Szukaj:</div>
      <div class="col-md-4">
        <input class="jumpcamp-input" type="text" [(ngModel)]="listFilter" />
      </div>
      <button class="btn btn-primary" [routerLink]="['/manager/places/add']">
        Dodaj Miejsce
      </button>
    </div>

    <div class="table-responsive">
      <table class="jumpcamp-table" *ngIf="places && places.length">
        <thead>
          <tr>
            <th>Nazwa</th>
            <th>Cena karnetu</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let place of filteredPlaces'>
            <td><a [routerLink]="['/manager/places', place.id, 'details']">
                {{ place.name }}
              </a></td>
            <td>{{ place.singleTicketPrice.amount }} {{ place.singleTicketPrice.currency }}</td>
            <td>
              <button class="btn btn-primary" [routerLink]="['/manager/places', place.id, 'edit']">
                Edytuj
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

  <div class='alert alert-danger' *ngIf='errorMessage'>
    Error: {{ errorMessage }}
  </div>
</div>