<div class="card" *ngIf="clientGroup">
  <div class="card-header">
    {{pageTitle}}
  </div>
  <br>
  <div class="card-body">
    <div class="row">
      <div class="col-md-2">Nazwa: </div>
      <input class="col-md-2 jumpcamp-input" type="text" placeholder="Nazwa (wymagane)" required minlength="3"
        maxlength="50" [(ngModel)]="clientGroup.name" name="name" #name="ngModel"
        [ngClass]="{'is-invalid': (name.touched || name.dirty) && !name.valid }" />
    </div>

    <div class="row">
      <div class="col-md-4">
        <button class="btn btn-success mr-3" type="button" style="width:80px" [disabled]="!isValid()"
          (click)="saveClientGroup()">
          Zapisz
        </button>
        <button class="btn btn-warning mr-3" type="button" style="width:80px" [routerLink]="[getReturnRoute()]"
          [relativeTo]="route">
          Anuluj
        </button>
        <button class="btn btn-danger" type="button" style="width:80px" (click)="deleteClientGroup()"
          *ngIf="!isAddPage()">
          Usuń
        </button>
      </div>
    </div>

  </div>

  <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
  </div>
</div>