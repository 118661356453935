import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Recommendation, ClientGroup } from './recommendation';
import { ClientService } from '../../client.service';

@Component({
  templateUrl: './client-group-detail-recommendations-edit.component.html'
})
export class ClientGroupDetailRecommendationsEditComponent implements OnInit {
  pageTitle = 'Recommendation Edit';
  errorMessage = '';

  private currentRecommendation: Recommendation;
  private originalRecommendation: Recommendation;
  private dataIsValid: boolean = false;

  public clientGroupId: string;
  private seasonId: string;

  get isDirty(): boolean {
    return JSON.stringify(this.originalRecommendation) !== JSON.stringify(this.currentRecommendation);
  }

  get recommendation(): Recommendation {
    return this.currentRecommendation;
  }

  set recommendation(value: Recommendation) {
    this.currentRecommendation = value;
    // Clone the object to retain a copy
    this.originalRecommendation = Object.assign({}, value);
  }

  constructor(private route: ActivatedRoute,
    private router: Router,
    private clientService: ClientService) { }

  public allClientGroups: ClientGroup[];
  public filteredClientGroups: ClientGroup[];

  ngOnInit(): void {
    // Watch for changes to the resolve data
    this.route.data.subscribe(data => {
      this.clientGroupId = data['clientGroup'].id;
      const dataName = 'recommendation';
      this.onRecommendationRetrieved(data[dataName]);
      if (this.isAddPage()) {
        this.route.queryParams.subscribe(params => {
          this.seasonId = params['seasonId'];
        });
      }
      this.getAllClientGroups();
    });
  }

  onRecommendationRetrieved(recommendation: Recommendation): void {
    if (recommendation === undefined) {
      this.recommendation = {
        id: "",
        whom: new ClientGroup()
      } as Recommendation;
      this.pageTitle = 'Nowe polecenie';
    }
    else {
      throw new Error("Editing recommendation is not implemented.");
      // this.recommendation = recommendation;
      // this.pageTitle = `Edytuj polecenie: ${this.recommendation.whom}`;
    }
  }

  isAddPage(): boolean {
    return this.recommendation.id === null || this.recommendation.id === "";
  }

  getAllClientGroups(): void {
    //TODO: Could be optimised by fetching only names of clientGroups without clients list
    this.clientService.getClientGroups()
      .subscribe(
        (clientGroups: ClientGroup[]) => {
          this.allClientGroups = clientGroups;
          // this.allClientGroups = clientGroups.map(x => ToClientTraining(x));
          this.performClientGroupsFilter();
        },
        (error: any) => this.errorMessage = error
      );
  }

  // Local filter
  performClientGroupsFilter(): void {
    var filtered = this.allClientGroups.filter((clientGroup: ClientGroup) =>
      this.clientGroupId !== clientGroup.id);
    this.filteredClientGroups = filtered;
  }

  deleteRecommendation(): void {
    if (!this.recommendation.id) {
      // Don't delete, it was never saved.
      this.onSaveComplete(`${this.recommendation.whom} was deleted`);
    } else {
      if (confirm(`Czy na pewno usunąć polecenie '${this.recommendation.whom}'?`)) {
        this.clientService.deleteRecommendation(this.clientGroupId, this.recommendation.id).subscribe(
          () => this.onSaveComplete(`${this.recommendation.whom} was deleted`),
          (error) => this.errorMessage = error
        );
      }
    }
  }

  isValid(): boolean {
    this.validate();
    return this.dataIsValid;
  }

  saveRecommendation(): void {
    if (this.isValid()) {
      this.clientService.saveRecommendation(this.clientGroupId, this.seasonId, this.recommendation).subscribe(
        () => this.onSaveComplete(`${this.recommendation.whom} was saved`),
        (error) => this.errorMessage = error
      );
    } else {
      this.errorMessage = 'Please correct the validation errors.';
    }
  }

  onSaveComplete(message?: string): void {
    console.log(message);
    this.reset();
    // Navigate back to the recommendations list
    this.router.navigate(['/manager/families', this.clientGroupId, 'details', 'recommendations']);
  }

  // Reset the data
  // Required after a save so the data is no longer seen as dirty.
  reset(): void {
    this.dataIsValid = null;
    this.currentRecommendation = null;
    this.originalRecommendation = null;
  }

  validate(): void {
    // Clear the validation object
    this.dataIsValid = false;
    if (this.recommendation.whom &&
      this.recommendation.whom.id != ""
      //TODO: Add validation
    ) {
      this.dataIsValid = true;
    } else {
      this.dataIsValid = false;
    }
  }
}
