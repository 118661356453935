import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountingPayment } from '../payment';
import { AccountingService } from '../accounting.service';

@Component({
  templateUrl: './accounting-in.component.html'
})

export class AccountingInComponent implements OnInit {
  pageTitle = 'Wpłaty';
  errorMessage: string;
  incomes: AccountingPayment[];
  selectedSeasonId: string;

  constructor(private route: ActivatedRoute,
    private accountingService: AccountingService
  ) {
  }

  ngOnInit(): void { }

  public get sortedIncomes(): AccountingPayment[] {
    return this.incomes.sort((a, b) => {
      return <any>new Date(b.date) - <any>new Date(a.date);
    });
  }

  onSeasonChange(seasonId: string): void {
    this.selectedSeasonId = seasonId;
    this.getIncomes();
  }

  getIncomes(): void {
    this.accountingService.getIncomes(this.selectedSeasonId)
      .subscribe(
        (incomes: AccountingPayment[]) => {
          this.incomes = incomes;
        },
        (error: any) => this.errorMessage = error
      );
  }

  onPaymentDelete(paymentId) {
    if (confirm("Are you sure to delete permanently?")) {
      this.accountingService.deleteIncome(paymentId).subscribe(value => {
        if (value) {
          this.incomes = this.incomes.filter(function (obj) {
            return obj.id !== paymentId;
          });
        }
        else {
          this.errorMessage = "Something went wrong while deleting income :(";
        }
      });
    }
  }
}
