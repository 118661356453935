<div class="card" *ngIf="instructor">
  <div class="card-header">
    {{ instructor.firstName }} {{ instructor.lastName }}
  </div>
  <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
  </div>
  <season-component (seasonChange)="onSeasonChange($event)"></season-component>
  Miesiąc: <input class="jumpcamp-input" type="month" [ngModel]="selectedMonth | date:'yyyy-MM'"
    (ngModelChange)="onMonthSelected($event, selectedMonth)">
  <br>

  <div class="table-responsive">
    <table class="jumpcamp-table" *ngIf="filteredTrainings && filteredTrainings.length">
      <thead>
        <tr>
          <th>Dzień</th>
          <th>Miejsce</th>
          <th>Godzina</th>
          <th>Typ</th>
          <th>Stawka</th>
          <th>Korekta wypłaty</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor='let training of filteredTrainings | SortInstructorTrainingByDateFromAndType'
          [class.cancelled]="training.cancelled">
          <td>{{ training.from | date:'dd.MM.yyyy E'}}</td>
          <td>
            <ng-container *ngIf="training.cancelled">
              Odwołany
            </ng-container>
            <ng-container *ngIf="!training.cancelled && training.place">
              {{ training.place.name }}
            </ng-container>
          </td>
          <td>{{training.from | date:'HH:mm'}} - {{training.to | date:'HH:mm'}}</td>

          <td>{{ training.type | slice:0:1 }}</td>
          <td>{{ training.salary?.amount }} {{ training.salary?.currency }}</td>
          <td>{{ training.paymentCorrection?.amount }} {{ training.paymentCorrection?.currency }}</td>
          <td>
            <button class="btn btn-primary" [routerLink]="['../../../../trainings', training.id, 'edit']"
              [relativeTo]="route">
              Edytuj
            </button>
          </td>
        </tr>
        <!-- <hr> -->
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td><strong>SUMA</strong></td>
          <td colspan="2">
            <span *ngIf="currencies.length"> <strong>{{ salary[currencies[0]] }} {{ currencies[0] }}</strong></span>
            <span *ngFor="let currency of currencies | slice:1"> <strong> | {{ salary[currency] }} {{ currency
                }}</strong></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <button class="btn btn-primary" (click)="generateBill()">
    Wygeneruj rachunek
  </button>

</div>