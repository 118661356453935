<div class="card-body">

  <div class="wizard">
    <a [routerLink]="['info']" [routerLinkActive]="['is-active']">
      Dane
    </a>
    |
    <a [routerLink]="['trainings']" routerLinkActive="is-active">
      Treningi
    </a>
    |
    <a [routerLink]="['accounting']" routerLinkActive="is-active">
      Rozliczenia
    </a>
    |
    <a [routerLink]="['rates']" routerLinkActive="is-active">
      Stawki
    </a>
  </div>
  <br>
  <router-outlet></router-outlet>