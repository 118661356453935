import { Component, OnInit } from '@angular/core';
import { AccountingSummary, AccountingSeasonalSummary } from '../summary';
import { AccountingService } from '../accounting.service';
import { Currency, PaymentInfo } from '../../models/paymentInfo'

@Component({
  templateUrl: './summary.component.html'
})

export class SummaryComponent implements OnInit {
  pageTitle = 'Podsumowanie';
  errorMessage: string;
  summary: AccountingSummary[];
  seasonalSummary: AccountingSeasonalSummary[];

  constructor(
    private accountingService: AccountingService
  ) {
  }

  ngOnInit(): void {
    this.getSummary()
    this.getSeasonalSummary()
  }

  getSummary(): void {
    this.accountingService.getSummary()
      .subscribe(
        (summary: AccountingSummary[]) => {
          this.summary = summary;
        },
        (error: any) => this.errorMessage = error
      );
  }

  getSeasonalSummary(): void {
    this.accountingService.getSeasonalSummary()
      .subscribe(
        (summary: AccountingSeasonalSummary[]) => {
          this.seasonalSummary = summary;
        },
        (error: any) => this.errorMessage = error
      );
  }

  public currencies = Object.values(Currency);

  getAmount(amounts: PaymentInfo[], currency: string) { //TODO: Move to shared lib
    let result = null
    amounts.forEach(amount => {
      if (amount.currency == currency) {
        result = amount;
      }
    });
    return result
  }
}