import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // <== add the imports!
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from '@angular/router';

import { DatePipe } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthGuardService, AccessGuard, AuthInterceptor, AuthService } from './shared/auth.service';

import { APIInterceptor } from './shared/auth.service';

//Content

import { HomeComponent } from './home/home.component'
import { AboutComponent } from './about/about.component'
import { ContactComponent } from './contact/contact.component'
import { NavigationComponent } from './navigation/navigation.component'
import { LoginComponent } from './login/login.component'
import { OfferComponent } from './offer/offer.component';


import { Angular2ImageGalleryModule } from './angular2-image-gallery/angular2-image-gallery.module';
import { Gallery } from './gallery/gallery.component';
import { GalleryPage } from './gallery/galleryPage.component';
import { AlbumComponent } from './gallery/album.component';
import { DataService } from './gallery/dataService';
import { SortPipe } from "./gallery/dateSortPipe";

import { NewsPage } from './news/newsPage.component';
import { NewsList } from './news/newsList.component';
import { NewsNewItem } from './news/newsNewItem.component';
import { NewsService } from './news/newsService';

import { InfoItem } from './info/info.component';

//Manager
import { LevelSortPipe } from "./shared/levelSortPipe";

import { DateFromSortPipe } from "./shared/dateFromSortPipe";
import { InstructorDateFromSortPipe } from './shared/instructorDateFromSortPipe';
import { ClientDateFromSortPipe } from './shared/clientDateFromSortPipe';

import { ManagerComponent } from './manager/manager.component';
import { ManagerNavigationComponent } from './manager/navigation/navigation.component';
import { SeasonComponent } from './manager/seasons/season.component';
import { SecondComponent } from './manager/second/second.component';
import { SeasonListComponent } from './manager/seasons/list/season-list.component';
import { SeasonEditComponent } from './manager/seasons/edit/season-edit.component';
// import { SeasonResolver } from './manager/seasons/season.resolver';
import { SeasonDetailsComponent } from './manager/seasons/details/season-details.component';
import { SeasonDetailsInfoComponent } from './manager/seasons/details/info/season-details-info.component';
import { SeasonBundlesComponent } from './manager/seasons/details/bundles/season-bundles.component';
// import { BundleResolver } from './manager/seasons/details/bundles/bundle.resolver';
import { SeasonBundleEditComponent } from './manager/seasons/details/bundles/season-bundles-edit.component';

import { DebtsListComponent } from './manager/debts/debts-list.component';

import { PlaceListComponent } from './manager/places/list/place-list.component';
import { PlaceEditComponent } from './manager/places/edit/place-edit.component';
import { PlaceDetailsComponent } from './manager/places/details/place-details.component';
// import { PlaceResolver } from './manager/places/place.resolver';

import { InstructorListComponent } from './manager/instructors/instructor-list.component';
import { InstructorDetailComponent } from './manager/instructors/details/instructor-detail.component';
// import { InstructorResolver } from './manager/instructors/instructor.resolver';
import { InstructorDetailInfoComponent } from './manager/instructors/details/info/instructor-detail-info.component';
import { InstructorDetailInfoEditComponent } from './manager/instructors/details/info/instructor-detail-info-edit.component';
import { InstructorDetailTrainingsComponent } from './manager/instructors/details/trainings/instructor-detail-trainings.component';
import { InstructorDetailRatesComponent } from './manager/instructors/details/rates/instructor-detail-rates.component';
import { InstructorDetailBillsComponent } from './manager/instructors/details/accounting/instructor-detail-accounting.component';
import { InstructorDetailBillsEditComponent } from './manager/instructors/details/accounting/instructor-detail-bills-edit.component';
// import { InstructorBillResolver } from './manager/instructors/details/accounting/instructor-bill.resolver';
import { InstructorDetailPaymentEditComponent } from './manager/instructors/details/accounting/instructor-detail-payment-edit.component';
// import { PaymentResolver } from './manager/instructors/details/accounting/payment.resolver';

import { ClientListComponent } from './manager/clients/client-list.component';
import { ClientGroupDetailComponent } from './manager/clients/details/client-group-detail.component';
import { ClientGroupDetailInfoComponent } from './manager/clients/details/info/client-group-detail-info.component';
import { ClientGroupDetailInfoEditComponent } from './manager/clients/details/info/client-group-detail-info-edit.component';
// import { ClientGroupResolver } from './manager/clients/client-group.resolver';
import { ClientGroupDetailClientsComponent } from './manager/clients/details/persons/client-group-detail-persons.component';
import { ClientGroupDetailClientEditComponent } from './manager/clients/details/persons/client-group-detail-persons-edit.component';
// import { ClientResolver } from './manager/clients/client.resolver';
import { ClientGroupTrainingsComponent } from './manager/clients/details/trainings/client-group-trainings.component';
import { ClientSubsComponent } from './manager/clients/details/subs/client-subs.component';
import { ClientGroupDetailSubsComponent } from './manager/clients/details/subs/client-group-detail-subs.component';
import { ClientSubEditComponent } from './manager/clients/details/subs/client-subs-edit.component';
// import { SubResolver } from './manager/clients/sub.resolver';
import { ClientGroupDetailRecommendationsComponent } from './manager/clients/details/recommendations/client-group-detail-recommendations.component';
import { ClientGroupDetailRecommendationsEditComponent } from './manager/clients/details/recommendations/client-group-detail-recommendations-edit.component';
import { ClientGroupDetailBillsComponent } from './manager/clients/details/accounting/client-group-detail-accounting.component';
import { ClientGroupDetailBillsEditComponent } from './manager/clients/details/accounting/client-group-detail-bills-edit.component';
// import { ClientGroupBillResolver } from './manager/clients/details/accounting/client-group-bill.resolver';
import { ClientGroupDetailPaymentEditComponent } from './manager/clients/details/accounting/client-group-detail-payment-edit.component';
// import { ClientGroupPaymentResolver } from './manager/clients/details/accounting/payment.resolver';

import { TrainingsListComponent } from './manager/trainings/trainings-list.component';
import { TrainingEditComponent } from './manager/trainings/edit/training-edit.component';
// import { TrainingResolver } from './manager/trainings/training.resolver';

import { AccountingComponent } from './manager/accounting/accounting.component';
import { AccountingOutComponent } from './manager/accounting/out/accounting-out.component';
import { AccountingPaymentEditComponent } from './manager/accounting/out/accounting-payment-edit.component';
// import { AccountingPaymentResolver } from './manager/accounting/out/outcomePayment.resolver';

import { AccountingInComponent } from './manager/accounting/in/accounting-in.component';
import { AccountingIncomeEditComponent } from './manager/accounting/in/accounting-in-edit.component';
// import { AccountingIncomeResolver } from './manager/accounting/in/incomePayment.resolver';

import { SummaryComponent } from './manager/accounting/summary/summary.component';
import { SeasonSummaryComponent } from './manager/accounting/season-summary/season-summary.component';

import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';

import { YesNoPipe } from './manager/clients/details/accounting/yes-no.pipe';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    NavigationComponent,
    LoginComponent,
    OfferComponent,
    ManagerComponent,
    ManagerNavigationComponent,
    SecondComponent,
    SeasonComponent,
    SeasonListComponent,
    SeasonEditComponent,
    SeasonDetailsComponent,
    SeasonDetailsInfoComponent,
    SeasonBundlesComponent,
    SeasonBundleEditComponent,
    PlaceListComponent,
    PlaceEditComponent,
    PlaceDetailsComponent,
    InstructorListComponent,
    InstructorDetailComponent,
    InstructorDetailInfoComponent,
    InstructorDetailInfoEditComponent,
    InstructorDetailTrainingsComponent,
    InstructorDetailRatesComponent,
    InstructorDetailBillsComponent,
    InstructorDetailBillsEditComponent,
    InstructorDetailPaymentEditComponent,
    ClientListComponent,
    ClientGroupDetailComponent,
    ClientGroupDetailInfoComponent,
    ClientGroupDetailInfoEditComponent,
    ClientGroupDetailClientsComponent,
    ClientGroupDetailClientEditComponent,
    ClientGroupTrainingsComponent,
    ClientSubsComponent,
    ClientSubEditComponent,
    ClientGroupDetailSubsComponent,
    ClientGroupDetailRecommendationsComponent,
    ClientGroupDetailRecommendationsEditComponent,
    ClientGroupDetailBillsComponent,
    ClientGroupDetailBillsEditComponent,
    ClientGroupDetailPaymentEditComponent,
    DebtsListComponent,
    TrainingsListComponent,
    TrainingEditComponent,
    AccountingComponent,
    AccountingOutComponent,
    AccountingPaymentEditComponent,
    AccountingInComponent,
    AccountingIncomeEditComponent,
    SummaryComponent,
    SeasonSummaryComponent,
    YesNoPipe,
    LevelSortPipe,
    SortPipe,
    DateFromSortPipe,
    InstructorDateFromSortPipe,
    ClientDateFromSortPipe,
    Gallery,
    GalleryPage,
    AlbumComponent,
    NewsPage,
    NewsList,
    NewsNewItem,
    InfoItem
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule, ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    MatNativeDateModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    BrowserAnimationsModule,
    Angular2ImageGalleryModule
  ],
  providers: [
    DatePipe,
    AuthService,
    AccessGuard,
    AuthGuardService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    DataService,
    NewsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
