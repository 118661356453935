<div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
</div>

<div class="table-responsive">
  <table class="jumpcamp-table">
    <ng-container *ngIf="summary && summary.length">
      <thead>
        <tr>
          <th>Sakiewka</th>
          <th *ngFor='let currency of currencies'>Kwota {{currency}}</th>
          <th>Estymacja razem</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let walletSummary of summary">
          <td>{{ walletSummary.wallet?.name }}</td>
          <td *ngFor="let currency of currencies">
            <span *ngIf="getAmount(walletSummary.amounts, currency)">

              {{ getAmount(walletSummary.amounts, currency).amount | number : '1.2-2' }} {{
              getAmount(walletSummary.amounts, currency).currency
              }}
            </span>
          </td>
          <td>{{ walletSummary.estimatedTotal?.amount | number : '1.2-2' }} {{ walletSummary.estimatedTotal?.currency }}
          </td>
        </tr>
      </tbody>
    </ng-container>
  </table>
  <table class="jumpcamp-table">
    <ng-container *ngIf="seasonalSummary && seasonalSummary.length">
      <thead>
        <tr>
          <th>Sezon</th>
          <th *ngFor='let currency of currencies'>Kwota {{currency}}</th>
          <th>Estymacja razem</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let seasonSummary of seasonalSummary">
          <td>{{ seasonSummary.season?.name }}</td>
          <td *ngFor="let currency of currencies">
            <span *ngIf="getAmount(seasonSummary.amounts, currency)">
              {{ getAmount(seasonSummary.amounts, currency).amount | number : '1.2-2' }} {{
              getAmount(seasonSummary.amounts, currency).currency
              }}
            </span>
          </td>
          <td>{{ seasonSummary.estimatedTotal?.amount | number : '1.2-2' }} {{ seasonSummary.estimatedTotal?.currency }}
          </td>
        </tr>
      </tbody>
    </ng-container>
  </table>
</div>