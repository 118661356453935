import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";

import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ClientGroup, Client } from './client';

import { Trainings } from './training';

import { Subscription } from './subscription';
import { Bill, Payment } from '../seasons/season';
import { Recommendation } from './details/recommendations/recommendation';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  // private clientGroupsUrl = window.location.origin + '/api/clientGroups';
  // private clientsUrl = window.location.origin + '/api/clients';
  private clientGroupsUrl = '/manager/clientGroups';
  private clientsUrl = '/manager/clients';

  constructor(private http: HttpClient) { }

  getClientGroups(): Observable<ClientGroup[]> {
    return this.http.get<ClientGroup[]>(this.clientGroupsUrl)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getClientGroup(id: string): Observable<ClientGroup> {
    if (id === "") {
      return of(this.initializeClientGroup());
    }
    const url = `${this.clientGroupsUrl}/${id}`;
    return this.http.get<ClientGroup>(url)
      .pipe(
        tap(data => console.log('Data: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  saveClientGroup(clientGroup: ClientGroup): Observable<ClientGroup> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    if (clientGroup.id === "") {
      return this.createClientGroup(clientGroup, headers);
    }
    return this.updateClientGroup(clientGroup, headers);
  }

  private createClientGroup(clientGroup: ClientGroup, headers: HttpHeaders): Observable<ClientGroup> {
    clientGroup.id = null;
    return this.http.post<ClientGroup>(this.clientGroupsUrl, clientGroup, { headers })
      .pipe(
        tap(data => console.log('createClientGroup: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private updateClientGroup(clientGroup: ClientGroup, headers: HttpHeaders): Observable<ClientGroup> {
    const url = `${this.clientGroupsUrl}/${clientGroup.id}`;
    return this.http.put<ClientGroup>(url, clientGroup, { headers })
      .pipe(
        tap(data => console.log('updateClientGroup: ' + clientGroup.id)),
        catchError(this.handleError)
      );
  }

  deleteClientGroup(id: string): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    const url = `${this.clientGroupsUrl}/${id}`;
    return this.http.delete<ClientGroup>(url, { headers })
      .pipe(
        tap(data => console.log('deleteClientGroup: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getAllClients(): Observable<Client[]> {
    return this.http.get<Client[]>(this.clientsUrl)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getClients(clientGroupId: string): Observable<Client[]> {
    const url = `${this.clientGroupsUrl}/${clientGroupId}/clients`;
    return this.http.get<Client[]>(url)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getTrainings(clientGroupId: string, seasonId: string, month: Date): Observable<Trainings> {
    month = new Date(month);
    const url = `${this.clientGroupsUrl}/${clientGroupId}/trainings?seasonId=${seasonId}&month=${month.toDateString()}`;
    return this.http.get<Trainings>(url)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getSubs(clientId: string, seasonId: string): Observable<Subscription[]> {
    const url = `${this.clientsUrl}/${clientId}/subscriptions?seasonId=${seasonId}`;
    return this.http.get<Subscription[]>(url)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  saveSub(clientId: string, seasonId: string, sub: Subscription): Observable<Subscription> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    if (sub.id === null || sub.id === "") {
      return this.createSub(clientId, seasonId, sub, headers);
    }
    return this.updateSub(clientId, sub, headers);
  }

  private createSub(clientId: string, seasonId: string, sub: Subscription, headers: HttpHeaders): Observable<Subscription> {
    sub.id = null;
    return this.http.post<Subscription>(`${this.clientsUrl}/${clientId}/subscriptions?seasonId=${seasonId}`, sub, { headers })
      .pipe(
        tap(data => console.log('createSub: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private updateSub(clientId: string, sub: Subscription, headers: HttpHeaders): Observable<Subscription> {
    const url = `${this.clientsUrl}/${clientId}/subscriptions/${sub.id}`;
    return this.http.put<Subscription>(url, sub, { headers })
      .pipe(
        tap(data => console.log('updateSub: ' + sub.id)),
        catchError(this.handleError)
      );
  }

  getSub(clientId: string, subId: string): Observable<Subscription> {
    const url = `${this.clientsUrl}/${clientId}/subscriptions/${subId}`;
    return this.http.get<Subscription>(url)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  deleteSub(clientId: string, subId: string): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    const url = `${this.clientsUrl}/${clientId}/subscriptions/${subId}`;
    return this.http.delete(url).pipe(map((res) => {
      console.log(res);
      return true;
    }));
  }

  getClient(id: string): Observable<Client> {
    if (id === "") {
      return of(this.initializeClient());
    }
    const url = `${this.clientsUrl}/${id}`;
    return this.http.get<Client>(url)
      .pipe(
        tap(data => console.log('Data: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  saveClient(clientGroupId: string, client: Client): Observable<Client> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    if (client.id === "") {
      return this.createClient(clientGroupId, client, headers);
    }
    return this.updateClient(client, headers);
  }

  private createClient(clientGroupId: string, client: Client, headers: HttpHeaders): Observable<Client> {
    client.id = null;
    const url = `${this.clientGroupsUrl}/${clientGroupId}/clients`;
    return this.http.post<Client>(url, client, { headers })
      .pipe(
        tap(data => console.log('createClient: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private updateClient(client: Client, headers: HttpHeaders): Observable<Client> {
    const url = `${this.clientsUrl}/${client.id}`;
    return this.http.put<Client>(url, client, { headers })
      .pipe(
        tap(data => console.log('updateClient: ' + client.id)),
        catchError(this.handleError)
      );
  }

  deleteClient(clientGroupId: string, clientId: string): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    const url = `${this.clientGroupsUrl}/${clientGroupId}/clients/${clientId}`;
    return this.http.delete(url).pipe(map((res) => {
      console.log(res);
      return true;
    }));
  }

  private initializeClientGroup(): ClientGroup {
    return {
      id: '',
      name: '',
      clients: new Client[0]
    };
  }

  private initializeClient(): Client {
    return {
      id: '',
      firstName: '',
      lastName: '',
      level: null,
      dateOfBirth: null
    };
  }

  getPayments(seasonId: string, clientGroupId: string): Observable<Payment[]> {
    const url = `${this.clientGroupsUrl}/${clientGroupId}/payments?seasonId=${seasonId}`;
    return this.http.get<Payment[]>(url)
      .pipe(
        tap(data => console.log('Data: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getPayment(clientGroupId: string, paymentId: string): Observable<Payment> {
    const url = `${this.clientGroupsUrl}/${clientGroupId}/payments/${paymentId}`;
    return this.http.get<Payment>(url)
      .pipe(
        tap(data => console.log('Data: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  savePayment(clientGroupId: string, seasonId: string, payment: Payment): Observable<Payment> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    if (payment.id === null || payment.id === "") {
      return this.createPayment(clientGroupId, seasonId, payment, headers);
    }
    return this.updatePayment(clientGroupId, payment, headers);
  }

  private createPayment(clientGroupId: string, seasonId: string, payment: Payment, headers: HttpHeaders): Observable<Payment> {
    payment.id = null;
    return this.http.post<Payment>(`${this.clientGroupsUrl}/${clientGroupId}/payments?seasonId=${seasonId}`, payment, { headers })
      .pipe(
        tap(data => console.log('createPayment: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private updatePayment(clientGroupId: string, payment: Payment, headers: HttpHeaders): Observable<Payment> {
    const url = `${this.clientGroupsUrl}/${clientGroupId}/payments/${payment.id}`;
    return this.http.put<Payment>(url, payment, { headers })
      .pipe(
        tap(data => console.log('updatePayment: ' + payment.id)),
        catchError(this.handleError)
      );
  }

  deletePayment(clientGroupId: string, paymentId: string): Observable<boolean> {
    const url = `${this.clientGroupsUrl}/${clientGroupId}/payments/${paymentId}`;
    return this.http.delete(url).pipe(map((res) => {
      console.log(res);
      return true;
    }));
  }

  getRecommendations(seasonId: string, clientGroupId: string): Observable<Recommendation[]> {
    const url = `${this.clientGroupsUrl}/${clientGroupId}/recommendations?seasonId=${seasonId}`;
    return this.http.get<Recommendation[]>(url)
      .pipe(
        tap(data => console.log('Data: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  saveRecommendation(clientGroupId: string, seasonId: string, recommendation: Recommendation): Observable<Recommendation> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    if (recommendation.id === null || recommendation.id === "") {
      return this.createRecommendation(clientGroupId, seasonId, recommendation, headers);
    }
    throw new Error("Updating recommendation is not implemented");
    // return this.updateRecommendation(clientGroupId, recommendation, headers);
  }

  private createRecommendation(clientGroupId: string, seasonId: string, recommendation: Recommendation, headers: HttpHeaders): Observable<Recommendation> {
    recommendation.id = null;
    return this.http.post<Recommendation>(`${this.clientGroupsUrl}/${clientGroupId}/recommendations?seasonId=${seasonId}`, recommendation, { headers })
      .pipe(
        tap(data => console.log('createRecommendation: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  deleteRecommendation(clientGroupId: string, recommendationId: string): Observable<boolean> {
    const url = `${this.clientGroupsUrl}/${clientGroupId}/recommendations/${recommendationId}`;
    return this.http.delete(url).pipe(map((res) => {
      console.log(res);
      return true;
    }));
  }

  getBills(seasonId: string, clientGroupId: string): Observable<Bill[]> {
    const url = `${this.clientGroupsUrl}/${clientGroupId}/bills?seasonId=${seasonId}`;
    return this.http.get<Bill[]>(url)
      .pipe(
        tap(data => console.log('Data: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getBill(clientGroupId: string, billId: string): Observable<Bill> {
    const url = `${this.clientGroupsUrl}/${clientGroupId}/bills/${billId}`;
    return this.http.get<Bill>(url)
      .pipe(
        tap(data => console.log('Data: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  saveBill(clientGroupId: string, seasonId: string, bill: Bill): Observable<Bill> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    if (bill.id === null || bill.id === "") {
      return this.createBill(clientGroupId, seasonId, bill, headers);
    }
    return this.updateBill(clientGroupId, bill, headers);
  }

  private createBill(clientGroupId: string, seasonId: string, bill: Bill, headers: HttpHeaders): Observable<Bill> {
    bill.id = null;
    return this.http.post<Bill>(`${this.clientGroupsUrl}/${clientGroupId}/bills?seasonId=${seasonId}`, bill, { headers })
      .pipe(
        tap(data => console.log('createBill: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private updateBill(clientGroupId: string, bill: Bill, headers: HttpHeaders): Observable<Bill> {
    const url = `${this.clientGroupsUrl}/${clientGroupId}/bills/${bill.id}`;
    return this.http.put<Bill>(url, bill, { headers })
      .pipe(
        tap(data => console.log('updateBill: ' + bill.id)),
        catchError(this.handleError)
      );
  }

  deleteBill(clientGroupId: string, billId: string): Observable<boolean> {
    const url = `${this.clientGroupsUrl}/${clientGroupId}/bills/${billId}`;
    return this.http.delete(url).pipe(map((res) => {
      console.log(res);
      return true;
    }));
  }

  generateBill(clientGroupId: string, seasonId: string, month: Date): Observable<Bill> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<Bill>(`${this.clientGroupsUrl}/${clientGroupId}/bills/generate?seasonId=${seasonId}&month=${month.toJSON()}`, { headers })
      .pipe(
        tap(data => console.log('createBill: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private handleError(err: any) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
    }
    console.error(err);
    return throwError(errorMessage);
  }
}