<div class="card">
  <div class="card-body">
    <div class="col-md-2">Szukaj:</div>
    <div class="col-md-4">
      <input class="jumpcamp-input" type="text" [(ngModel)]="listFilter" />
    </div>

    <div class="table-responsive col-md-12">
      <table class="jumpcamp-table" *ngIf="filteredDebts && filteredDebts.length">
        <thead>
          <tr>
            <th>Rodzina</th>
            <th *ngFor='let currency of currencies'>Kwota {{currency}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let debt of filteredDebts">
            <td>
              <a [routerLink]="['../families', debt.clientGroup.id, 'details']" [relativeTo]="route">
                {{debt.clientGroup.name}}
              </a>
            </td>
            <td *ngFor="let currency of currencies">
              <span *ngIf="getAmount(debt.debts, currency)">
                {{ getAmount(debt.debts, currency).amount | number : '1.2-2' }} {{
                getAmount(debt.debts, currency).currency
                }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class='alert alert-danger' *ngIf='errorMessage'>
    Error: {{ errorMessage }}
  </div>
</div>