<div class="card">
  <div class="card-header">
    {{pageTitle}}
  </div>
  <br>
  <div class="card-body">
    <div class="row">
      <label class="col-md-2" for="inputBillName">Nazwa</label>
      <input class="jumpcamp-input" id="inputBillName" type="text" placeholder="Nazwa (wymagane)" required minlength="3"
        maxlength="50" [(ngModel)]="bill.name" name="name" #name="ngModel"
        [ngClass]="{'is-invalid': (name.touched || name.dirty) && !name.valid }" />
    </div>
    <div class="row">
      <label class="col-md-2">Kwota</label>
      <input class="jumpcamp-input" type="number" min="0" max="100000" required mdInput placeholder="Kwota (wymagane)"
        [(ngModel)]="bill.amount.amount">

      <select class="jumpcamp-input" matNativeControl [(ngModel)]="bill.amount.currency">
        <option *ngFor="let currency of currencies" [value]="currency">
          {{currency}}
        </option>
      </select>
    </div>
    <br>

    <div class="row">
      <div class="col-md-4">
        <button class="btn btn-success mr-3" type="button" style="width:80px" [disabled]="!isValid()"
          (click)="saveBill()">
          Zapisz
        </button>
        <button class="btn btn-warning mr-3" type="button" style="width:80px"
          [routerLink]="['/manager/instructors', instructorId, 'details', 'accounting']">
          Anuluj
        </button>
        <button class="btn btn-danger" type="button" style="width:80px" (click)="deleteBill()" *ngIf="!isAddPage()">
          Usuń
        </button>
      </div>
    </div>
  </div>
</div>

<div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
</div>