import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientService } from '../../client.service';

import { Client } from '../../client';

import { Subscription } from '../../subscription';

@Component({
  selector: 'client-subs',
  templateUrl: './client-subs.component.html'
})

export class ClientSubsComponent implements OnInit {
  pageTitle = 'Client Subs';
  subs: Subscription[];
  errorMessage: string;
  _seasonId: string;

  clientGroupId: string;
  @Input() client: Client;
  @Input() set seasonId(value: string) {
    this._seasonId = value;
    if (value !== undefined) {
      this.getSubs();
    }
  }
  constructor(private route: ActivatedRoute,
    private clientService: ClientService
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.clientGroupId = paramMap.get('id');
    });
  }

  isInTheFuture(date: Date): boolean {
    const now = new Date();
    const compareTo = new Date(date);
    return compareTo > now;
  }

  getSubs(): void {
    this.clientService.getSubs(this.client.id, this._seasonId)
      .subscribe(
        (subs: Subscription[]) => {
          this.subs = subs;
        },
        (error: any) => this.errorMessage = error
      );
  }

  onSubDelete(subId) {
    if (confirm("Are you sure to delete permanently?")) {
      this.clientService.deleteSub(this.client.id, subId).subscribe(value => {
        if (value) {
          this.subs = this.subs.filter(function (obj) {
            return obj.id !== subId;
          });
        }
        else {
          this.errorMessage = "Something went wrong while deleting subscription :(";
        }
      });
    }
  }
}
