<div class="card" *ngIf="clientGroup">
  <div class="card-header">
    {{ clientGroup.name }}
  </div>
  <br>
  <div class="card-body">
    <div class="row">
      <div class="col-md-2">Nazwa: </div>
      <div class="col-md-10">{{ clientGroup.name }}</div>
    </div>
  </div>

  <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
  </div>
</div>