import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Currency, PaymentInfo } from '../../../models/paymentInfo'
import { Bundle } from '../../bundle';
import { SeasonService } from '../../season.service';
import { Training } from '../../training';

@Component({
  templateUrl: './season-bundle-edit.component.html'
})
export class SeasonBundleEditComponent implements OnInit {
  pageTitle = 'Bundle Edit';
  errorMessage = '';

  private currentBundle: Bundle;
  private originalBundle: Bundle;
  private dataIsValid: boolean = false;

  private allTrainings: Training[];
  public filteredTrainings: Training[];
  newTraining: Training = new Training();

  public seasonId: string;

  get isDirty(): boolean {
    return JSON.stringify(this.originalBundle) !== JSON.stringify(this.currentBundle);
  }

  get bundle(): Bundle {
    return this.currentBundle;
  }

  set bundle(value: Bundle) {
    this.currentBundle = value;
    // Clone the object to retain a copy
    this.originalBundle = Object.assign({}, value);
  }

  constructor(public route: ActivatedRoute,
    private router: Router,
    private seasonService: SeasonService) { }

  public currencies = Object.values(Currency);

  ngOnInit(): void {
    // Watch for changes to the resolve data
    this.route.parent.data.subscribe(data => {
      this.seasonId = data['season'].id;
    })
    this.route.data.subscribe(data => {
      const dataName = 'bundle';
      this.onBundleRetrieved(data[dataName]);
    });
    this.getAllTrainings();
  }

  removeTraining(training: Training): void {
    const index = this.bundle.trainings.indexOf(training, 0);
    if (index > -1) {
      this.bundle.trainings.splice(index, 1);
      this.performTrainingsFilter();
    }
  }

  addTraining(): void {
    if (!this.newTraining.id) {
      this.errorMessage = "Did not provide all instructor information.";
      return;
    }
    if (this.bundle.trainings.find(r => r.id === this.newTraining.id)) {
      this.errorMessage = `Training ${this.newTraining.id} is already added to this bundle.`;
      return;
    }
    this.bundle.trainings.push(this.newTraining);
    this.newTraining = new Training();
    this.performTrainingsFilter();
  }

  getAllTrainings(): void {
    this.seasonService.getTrainings(this.seasonId)
      .subscribe(
        (trainings: Training[]) => {
          this.allTrainings = trainings;
          this.performTrainingsFilter();
        },
        (error: any) => this.errorMessage = error
      );
  }

  performTrainingsFilter(): void {
    var filtered = this.allTrainings.filter((training: Training) =>
      !this.bundle.trainings.some((bundleTraining) => bundleTraining.id === training.id)
    );
    this.filteredTrainings = filtered;
  }

  onBundleRetrieved(bundle: Bundle): void {
    if (bundle === undefined) {
      this.bundle = {
        id: "",
        name: "",
        trainingPrice: new PaymentInfo(),
        trainings: []
      } as Bundle;
      this.pageTitle = 'Nowy pakiet';
    }
    else {
      this.bundle = bundle;
      this.pageTitle = `Edytuj pakiet: ${this.bundle.name}`;
    }
  }

  isAddPage(): boolean {
    return this.bundle.id === null || this.bundle.id === "";
  }

  getReturnRoute(): string {
    let navigateTo = '../..';
    if (this.isAddPage()) {
      navigateTo = '..'
    }
    return navigateTo;
  }

  // deleteBill(): void {
  //   if (!this.bill.id) {
  //     // Don't delete, it was never saved.
  //     this.onSaveComplete(`${this.bill.name} was deleted`);
  //   } else {
  //     if (confirm(`Czy na pewno usunąć rachunek '${this.bill.name}'?`)) {
  //       this.instructorService.deleteBill(this.instructorId, this.bill.id).subscribe(
  //         () => this.onSaveComplete(`${this.bill.name} was deleted`),
  //         (error) => this.errorMessage = error
  //       );
  //     }
  //   }
  // }

  isValid(): boolean {
    this.validate();
    return this.dataIsValid;
  }

  saveBundle(): void {
    if (this.isValid()) {
      this.seasonService.saveBundle(this.seasonId, this.bundle).subscribe(
        () => this.onSaveComplete(`${this.bundle.name} was saved`),
        (error) => this.errorMessage = error
      );
    } else {
      this.errorMessage = 'Please correct the validation errors.';
    }
  }

  onSaveComplete(message?: string): void {
    console.log(message);
    var goBackTo = this.getReturnRoute();
    this.reset();
    // Navigate back to the bills list
    this.router.navigate([goBackTo], { relativeTo: this.route });
  }

  // Reset the data
  // Required after a save so the data is no longer seen as dirty.
  reset(): void {
    this.dataIsValid = null;
    this.currentBundle = null;
    this.originalBundle = null;
  }

  validate(): void {
    // Clear the validation object
    this.dataIsValid = false;
    if (this.bundle.name
      //TODO: Add validation
    ) {
      this.dataIsValid = true;
    } else {
      this.dataIsValid = false;
    }
  }
}
