<div class="card" *ngIf="clientGroup">
  <div class="card-header">
    {{ clientGroup.name }}
  </div>
  <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
  </div>
  <season-component (seasonChange)="onSeasonChange($event)"></season-component>
  Miesiąc: <input class="jumpcamp-input" type="month" [ngModel]="selectedMonth | date:'yyyy-MM'"
    (ngModelChange)="onMonthSelected($event, selectedMonth)">
  <br>
  <div *ngIf="clientGroupTrainings && clientGroupTrainings.clients">
    <div *ngFor='let client of clientGroupTrainings.clients'>
      <div class="table-responsive">
        <table class="jumpcamp-table" *ngIf="client.trainings && client.trainings.length">
          <caption>{{client.firstName}} {{client.lastName}}
            <ng-container *ngIf="client.discount > 0"> | zniżka: {{client.discount}}%</ng-container>
          </caption>
          <thead>
            <tr>
              <th>Dzień</th>
              <th>Miejsce</th>
              <th>Godzina</th>
              <th>Typ</th>
              <th>Obecność</th>
              <th>Notatka</th>
              <!-- <th>Korekta płatności</th> -->
              <th>Koszt treningu</th>
              <th>Karnety instruktora</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor='let training of client.trainings | SortClientTrainingByDateFromAndType'
              [class.cancelled]="training.cancelled">
              <td>{{ training.from | date:'dd.MM.yyyy E'}}</td>
              <td>
                <ng-container *ngIf="training.cancelled">
                  Odwołany
                </ng-container>
                <ng-container *ngIf="!training.cancelled && training.place">
                  {{ training.place.name }}
                </ng-container>
              </td>
              <td>{{training.from | date:'HH:mm'}} - {{training.to | date:'HH:mm'}}</td>
              <td>{{ training.type | slice:0:1 }}</td>
              <td>{{ getClientStatus(training) }}</td>
              <td>{{ training.note }}
              </td>
              <!-- <td>{{ training.paymentCorrection?.amount }} {{ training.paymentCorrection?.currency }}</td> -->
              <td>{{ training.charge?.amount }} {{ training.charge?.currency }}
                <ng-container *ngIf="training.discount > 0 && !training.cancelled &&
                  training.charge?.amount > 0">(-{{training.discount}}%)
                </ng-container>
              </td>
              <td>{{ training.instructorSkipassCharge?.amount | number : '1.2-2' }} {{
                training.instructorSkipassCharge?.currency }}
              </td>
              <td>
                <button class="btn btn-primary" [routerLink]="[training.id, 'edit']" [relativeTo]="route">
                  Edytuj
                </button>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td><strong>SUMA</strong></td>
              <td colspan="2">
                <!-- <span *ngIf="client.total.length"> <strong>{{ client.total[currencies[0]] }} {{ currencies[0] }}</strong></span> -->
                <!-- <span *ngFor="let currency of currencies | slice:1"> <strong> | {{ salary[currency] }} {{ currency
                    }}</strong></span> -->
                <span *ngIf="client.total.length"> <strong> {{ client.total[0].amount | number : '1.2-2' }} {{
                    client.total[0].currency
                    }}</strong></span>
                <span *ngFor="let item of client.total | slice:1"> <strong> | {{ item.amount | number : '1.2-2' }} {{
                    item.currency
                    }}</strong></span>
              </td>
            </tr>
            <!-- <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td><strong>SUMA</strong></td>
        <td> <span *ngFor="let currency of currencies"> <strong>{{ salary[currency] }} {{ currency }} | </strong></span></td>
        <td></td>
      </tr> -->
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <button class="btn btn-primary" (click)="generateBill()">
    Wygeneruj rachunek
  </button>

</div>