import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientService } from '../../client.service';

import { ClientGroup, Client } from '../../client';

import { CalculateAge } from '../../../utils';

@Component({
  templateUrl: './client-group-detail-persons.component.html'
})

export class ClientGroupDetailClientsComponent implements OnInit {
  pageTitle = 'ClientGroup Clients';
  clientGroup: ClientGroup;
  errorMessage: string;

  constructor(public route: ActivatedRoute,
    private clientService: ClientService
  ) {
  }

  ngOnInit(): void {
    this.route.parent.data.subscribe(data => {
      const dataName = 'clientGroup';
      this.clientGroup = data[dataName];
      this.getClients();
    });
  }

  getClients(): void {
    this.clientService.getClients(this.clientGroup.id)
      .subscribe(
        (clients: Client[]) => {
          this.clientGroup.clients = clients;
        },
        (error: any) => this.errorMessage = error
      );
  }

  onClientDelete(clientId) {
    if (confirm("Are you sure to delete permanently?")) {
      this.clientService.deleteClient(this.clientGroup.id, clientId).subscribe(value => {
        if (value) {
          this.clientGroup.clients = this.clientGroup.clients.filter(function (obj) {
            return obj.id !== clientId;
          });
        }
        else {
          this.errorMessage = "Something went wrong while deleting client :(";
        }
      });
    }
  }

  calculateAge = CalculateAge;
}
