import { ClientTraining } from './client';

export class ClientTrainingState {
  presence: boolean;
  catchUp: boolean;
  excused: boolean;
  note: string;
}

export enum ClientTrainingStatus {
  Unspecified = "",
  Present = "+",
  Absent = "-",
  CatchUp = "O",
  Excused = "U"
}

export function absent(client: ClientTraining): boolean {
  var status = getStatus(client);
  return status === ClientTrainingStatus.Absent || status === ClientTrainingStatus.Excused;
}

export function getStatus(clientState: ClientTrainingState): ClientTrainingStatus {
  if (clientState === null) {
    return ClientTrainingStatus.Unspecified;
  }
  if (clientState.catchUp) {
    return ClientTrainingStatus.CatchUp;
  }
  else if (clientState.excused) {
    return ClientTrainingStatus.Excused;
  }
  else if (clientState.presence) {
    return ClientTrainingStatus.Present;
  }
  else if (clientState.presence === false) {
    return ClientTrainingStatus.Absent;
  }
  else if (clientState.presence === null || clientState.presence === undefined) {
    return ClientTrainingStatus.Unspecified;
  }
  throw new Error("Invalid state");
}