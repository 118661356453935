<app-navigation></app-navigation>
<div class="col-md-1 hidden-xs"></div>
<div class="col-md-10 col-xs-12 no-padding flex">
  <div class="col-xs-12 no-padding">
    <router-outlet></router-outlet>
  </div>
</div>
<div class="col-md-1 hidden-xs"></div>
<footer id="footer" class="py-5">
  <div class="container">
    <div class="row box">
      <div class="col-md-1"></div>
      <div class="col-md-5 col-xs-3 no-padding">

        <div id="icons" style="float: left">
          <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/jumpcamp/">
            <img class="social-icon" src="/assets/images/icons/fb.png" alt="facebook">
          </a>
          <a href="https://www.facebook.com/jumpcamp/">
            <img class="social-icon" src="/assets/images/icons/instagram.png" alt="instagram">
          </a>
        </div>
      </div>
      <div class="col-md-5 col-xs-9 no-padding">
        <div id="footer-content">
          <div class="col-xs-4 no-padding">
            Biuro - adres korespondencyny:<br />
            Jump Camp Sp.z o.o. <br />
            ul. Szczęśliwa 24<br />
            43-400 Bielsko-Biała<br />
          </div>
          <div class="col-xs-4 no-padding">e-mail:<br />Telefon komórkowy:</div>
          <div class="col-xs-4 no-padding">
            <b>
              <a href="mailto:info@jump-camp.pl">info@jump-camp.pl</a><br />
              <a href="tel:+48513158285">+48 513 158 285</a><br />
              <a href="tel:+48505675235">+48 505 675 235</a>
            </b>
          </div>
        </div>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
</footer>