import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Season } from './season';
import { Rate } from '../seasons/season';

import { Bundle } from './bundle';
import { Training } from './training';

@Injectable({
  providedIn: 'root'
})
export class SeasonService {
  // private seasonsUrl = window.location.origin + '/api/seasons';
  private seasonsUrl = '/manager/seasons';

  constructor(private http: HttpClient) { }

  getSeasons(): Observable<Season[]> {
    return this.http.get<Season[]>(this.seasonsUrl)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getTrainings(seasonId: string): Observable<Training[]> {
    const url = `${this.seasonsUrl}/${seasonId}/trainings`;
    return this.http.get<Training[]>(url)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getSeason(id: string): Observable<Season> {
    if (id === "") {
      return of(this.initializeSeason());
    }
    const url = `${this.seasonsUrl}/${id}`;
    return this.http.get<Season>(url)
      .pipe(
        tap(data => console.log('Data: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  saveSeason(season: Season): Observable<Season> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    if (season.id === "") {
      return this.createSeason(season, headers);
    }
    return this.updateSeason(season, headers);
  }

  deleteSeason(id: string): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    const url = `${this.seasonsUrl}/${id}`;
    return this.http.delete<Season>(url, { headers })
      .pipe(
        tap(data => console.log('deleteSeason: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private createSeason(season: Season, headers: HttpHeaders): Observable<Season> {
    season.id = null;
    return this.http.post<Season>(this.seasonsUrl, season, { headers })
      .pipe(
        tap(data => console.log('createSeason: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private updateSeason(season: Season, headers: HttpHeaders): Observable<Season> {
    const url = `${this.seasonsUrl}/${season.id}`;
    return this.http.put<Season>(url, season, { headers })
      .pipe(
        tap(data => console.log('updateSeason: ' + season.id)),
        catchError(this.handleError)
      );
  }

  saveBundle(seasonId: string, bundle: Bundle): Observable<Bundle> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    if (bundle.id === "") {
      return this.createBundle(seasonId, bundle, headers);
    }
    return this.updateBundle(seasonId, bundle, headers);
  }

  private createBundle(seasonId: string, bundle: Bundle, headers: HttpHeaders): Observable<Bundle> {
    bundle.id = null;
    const url = `${this.seasonsUrl}/${seasonId}/bundles`;
    return this.http.post<Bundle>(url, bundle, { headers })
      .pipe(
        tap(data => console.log('createBundle: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private updateBundle(seasonId: string, bundle: Bundle, headers: HttpHeaders): Observable<Bundle> {
    const url = `${this.seasonsUrl}/${seasonId}/bundles/${bundle.id}`;
    return this.http.put<Bundle>(url, bundle, { headers })
      .pipe(
        tap(data => console.log('updateBundle: ' + bundle.id)),
        catchError(this.handleError)
      );
  }

  private initializeSeason(): Season {
    // Return an initialized object
    return {
      id: '',
      name: '',
      from: null,
      to: null,
      instructorRates: new Rate[0],
      trainingPrices: new Rate[0]
    };
  }

  getBundles(seasonId: string): Observable<Bundle[]> {
    const url = `${this.seasonsUrl}/${seasonId}/bundles`;
    return this.http.get<Bundle[]>(url)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getBundle(seasonId: string, bundleId: string): Observable<Bundle> {
    const url = `${this.seasonsUrl}/${seasonId}/bundles/${bundleId}`;
    return this.http.get<Bundle>(url)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private handleError(err: any) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.error}`;
    }
    console.error(err);
    return throwError(errorMessage);
  }
}
