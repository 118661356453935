import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Place } from '../place';
import { PlaceService } from '../place.service';
import { PlaceParameterService } from './place-parameter.service';

@Component({
  templateUrl: './place-list.component.html'
})
export class PlaceListComponent implements OnInit {
  pageTitle = 'Place List';
  filteredPlaces: Place[];
  places: Place[];
  errorMessage: string;

  get listFilter(): string {
    return this.placeParameterService.filterBy;
  }
  set listFilter(value: string) {
    this.placeParameterService.filterBy = value;
    this.filteredPlaces = this.performFilter(this.listFilter);
  }

  constructor(private placeService: PlaceService,
    private placeParameterService: PlaceParameterService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.pageTitle = 'Place List';
      // If parameters are passed in,
      // clear any existing filter
      if (Object.keys(params).length) {
        this.listFilter = null;
      }
      this.getPlaces();
    });
  }

  getPlaces(): void {
    this.placeService.getPlaces()
      .subscribe(
        (places: Place[]) => {
          this.places = places;
          this.filteredPlaces = this.performFilter(this.listFilter);
        },
        (error: any) => this.errorMessage = error
      );
  }

  // Local filter
  performFilter(filterBy: string): Place[] {
    if (filterBy) {
      filterBy = filterBy.toLocaleLowerCase();
      return this.places.filter((place: Place) =>
        place.name.toLocaleLowerCase().indexOf(filterBy) !== -1)
    } else {
      return this.places;
    }
  }
}
