<div class="card">
  <div class="card-header">
    {{pageTitle}}
  </div>
  <br>
  <div class="card-body">
    <div class="row">
      <label class="col-md-2" for="inputSeasonName">Nazwa</label>
      <input class="jumpcamp-input" id="inputSeasonName" type="text" placeholder="Nazwa (wymagane)" required
        minlength="3" maxlength="50" [(ngModel)]="season.name" name="name" #name="ngModel"
        [ngClass]="{'is-invalid': (name.touched || name.dirty) && !name.valid }" />
    </div>
    <br>
    <div class="row">
      <div class="col-md-2">Od: </div>
      <input class="jumpcamp-input" type="date" [value]="season.from | date:'yyyy-MM-dd'"
        (input)="season.from = parseDate($event)" required>
    </div>
    <div class="row">
      <div class="col-md-2">Do: </div>
      <input class="jumpcamp-input" type="date" [value]="season.to | date:'yyyy-MM-dd'"
        (input)="season.to = parseDate($event)" required>
    </div>
    <div class="table-responsive">
      <table class="jumpcamp-table">
        <caption>Stawki instruktorskie</caption>
        <thead>
          <tr>
            <th>Typ</th>
            <th>Stawka</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let rate of season.instructorRates'>
            <td>{{ rate.trainingType }}</td>
            <td>{{ rate.amount.amount }} {{ rate.amount.currency }}</td>
            <td>
              <button class="btn btn-danger" (click)="removeRate(rate)">
                Usuń
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <select class="jumpcamp-input" class="jumpcamp-input" matNativeControl [(ngModel)]="newRate.trainingType"
                name="newRateTrainingType">
                <option *ngFor="let trainingType of trainingTypes" [value]="trainingType">
                  {{trainingType}}
                </option>
              </select>
            </td>
            <td>
              <input class="jumpcamp-input" type="number" min="0" max="100" required mdInput placeholder="Kwota"
                [(ngModel)]="newRate.amount.amount" name="newRateSalary">

              <select class="jumpcamp-input" matNativeControl [(ngModel)]="newRate.amount.currency"
                name="newRateSalaryCurrency">
                <option *ngFor="let currency of currencies" [value]="currency">
                  {{currency}}
                </option>
              </select>
            </td>
            <td>
              <button class="btn btn-primary" (click)="addRate()">
                Dodaj
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--TODO Change it to rates-list-component and reuse for both salaries and training prices-->
    <div class="table-responsive">
      <table class="jumpcamp-table">
        <caption>Cennik treningów</caption>
        <thead>
          <tr>
            <th>Typ</th>
            <th>Cena</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let rate of season.trainingPrices'>
            <td>{{ rate.trainingType }}</td>
            <td>{{ rate.amount.amount }} {{ rate.amount.currency }}</td>
            <td>
              <button class="btn btn-danger" (click)="removeTrainingPrice(rate)">
                Usuń
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <select class="jumpcamp-input" class="jumpcamp-input" matNativeControl
                [(ngModel)]="newTrainingPrice.trainingType">
                <option *ngFor="let trainingType of trainingTypes" [value]="trainingType">
                  {{trainingType}}
                </option>
              </select>
            </td>
            <td>
              <input class="jumpcamp-input" type="number" min="0" max="100" required mdInput placeholder="Kwota"
                [(ngModel)]="newTrainingPrice.amount.amount">

              <select class="jumpcamp-input" matNativeControl [(ngModel)]="newTrainingPrice.amount.currency">
                <option *ngFor="let currency of currencies" [value]="currency">
                  {{currency}}
                </option>
              </select>
            </td>
            <td>
              <button class="btn btn-primary" (click)="addTrainingPrice()">
                Dodaj
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <div class="col-md-4">
        <button class="btn btn-success mr-3" type="button" style="width:80px" [disabled]="!isValid()"
          (click)="saveSeason()">
          Zapisz
        </button>
        <button class="btn btn-warning mr-3" type="button" style="width:80px" [routerLink]="['/manager/seasons']">
          Anuluj
        </button>
        <button class="btn btn-danger" type="button" style="width:80px" (click)="deleteSeason()" *ngIf="!isAddPage()">
          Usuń
        </button>
      </div>
    </div>
  </div>
</div>

<div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
</div>