import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { Place } from './place';
import { PlaceService } from './place.service';

@Injectable({
  providedIn: 'root'
})
export class PlaceResolver implements Resolve<Place> {

  constructor(private placeService: PlaceService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<Place> {
    const id = route.paramMap.get('placeId');
    console.log(route)
    return this.placeService.getPlace(id);
  }
}
