import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ClientGroup } from './client';
import { ClientService } from './client.service';
import { ClientParameterService } from './client-parameter.service';

import { CalculateAge } from '../utils';

@Component({
  templateUrl: './client-list.component.html'
})

export class ClientListComponent implements OnInit {
  pageTitle = 'Client List';
  filteredClientGroups: ClientGroup[];
  clientGroups: ClientGroup[];
  errorMessage: string;

  get listFilter(): string {
    return this.clientParameterService.filterBy;
  }

  set listFilter(value: string) {
    this.clientParameterService.filterBy = value;
    this.filteredClientGroups = this.performFilter(this.listFilter);
  }

  constructor(private clientService: ClientService,
    private clientParameterService: ClientParameterService,
    public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.pageTitle = 'Client List';
      // If parameters are passed in,
      // clear any existing filter
      if (Object.keys(params).length) {
        this.listFilter = null;
      }
      this.getClientGroups();
    });
  }

  getClientGroups(): void {
    this.clientService.getClientGroups()
      .subscribe(
        (clientGroups: ClientGroup[]) => {
          // this.instructors = this.performSearch(instructors);
          this.clientGroups = clientGroups;
          this.filteredClientGroups = this.performFilter(this.listFilter);
        },
        (error: any) => this.errorMessage = error
      );
  }

  // Local filter
  performFilter(filterBy: string): ClientGroup[] {
    if (filterBy) {
      filterBy = filterBy.toLocaleLowerCase();
      return this.clientGroups.filter((clientGroup: ClientGroup) =>
        (clientGroup.name && clientGroup.name.toLocaleLowerCase().indexOf(filterBy)) !== -1 ||
        clientGroup.clients.some((element) => {
          return (element.firstName && element.firstName.toLocaleLowerCase().indexOf(filterBy)) !== -1 ||
            (element.lastName && element.lastName.toLocaleLowerCase().indexOf(filterBy) !== -1)
        }))
    } else {
      return this.clientGroups;
    }
  }

  calculateAge = CalculateAge;

  displayAge(age: number): string {
    if (age) {
      return age.toString();
    }
    else {
      return "-";
    }
  }
}
