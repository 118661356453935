<div class="card" *ngIf="instructor">
  <div class="card-header">
    {{ instructor.firstName }} {{ instructor.lastName }}
  </div>
  <br>
  <div class="card-body">
    <div class="row">
      <div class="col-md-2">Imię: </div>
      <div class="col-md-10">{{ instructor.firstName }}</div>
    </div>
    <div class="row">
      <div class="col-md-2">Nazwisko: </div>
      <div class="col-md-10">{{ instructor.lastName }}</div>
    </div>
    <div class="row">
      <div class="col-md-2">Data urodzenia: </div>
      <div class="col-md-10">{{ instructor.dateOfBirth | date:'dd.MM.yyyy' }}</div>
    </div>
    <!-- <div class="row">
      <div class="col-md-2">MPAA Film Rating: </div>
      <div class="col-md-10">{{ instructor.mpaa | uppercase}}</div>
    </div>
    <div class="row">
      <div class="col-md-2">Tags:</div>
      <div class="col-md-10">{{instructor.tags}}</div>
    </div>
    <div class="row">
      <div class="col-md-2">Star Rating: </div>
      <div class="col-md-10">
        <mh-star [rating]="instructor.starRating"></mh-star>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">Audience Approval: </div>
      <div class="col-md-10">{{ instructor.approvalRating | percent:'1.0-0' }}</div>
    </div>
    <div class="row">
      <div class="col-md-2">Average DVD Price: </div>
      <div class="col-md-10">{{ instructor.price | currency:'USD':'symbol' }}</div>
    </div> -->
    <!-- 

    <div class="row mt-4">
      <div class="col-md-4">
        <button class="btn btn-outline-secondary mr-3"
                style="width:80px"
                (click)="onBack()">
          <i class="fa fa-chevron-left"></i> Back
        </button>
        <button class="btn btn-outline-primary mr-3"
                style="width:80px"
                [routerLink]="['/instructors', instructor.id, 'edit']">
          Edit
        </button>
        <button class="btn btn-outline-primary"
                [routerLink]="['/instructors', instructor.id, 'editReactive']">
          Edit Reactive
        </button>
      </div>
    </div> -->

  </div>

  <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
  </div>
</div>