import { Injectable } from '@angular/core';
import { Resolve, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { Bill } from '../../../seasons/season';
import { InstructorService } from '../../instructor.service';

@Injectable({
  providedIn: 'root'
})

//TODO: Change to generic bill resolver 
export class InstructorBillResolver implements Resolve<Bill> {

  constructor(
    private instructorService: InstructorService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<Bill> {
    const billId = route.paramMap.get('billId');
    const instructorId = route.paramMap.get('id');
    // const instructorId = route.parent.data["instructor"].id;
    return this.instructorService.getBill(instructorId, billId);
  }
}
