<div class="card" *ngIf="instructor">
  <div class="card-header">
    {{ instructor.firstName }} {{ instructor.lastName }}
  </div>
  <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
  </div>
  <season-component (seasonChange)="onSeasonChange($event)"></season-component>
  <br>

  <div class="table-responsive">
    <table class="jumpcamp-table" *ngIf="rates && rates.length">
      <thead>
        <tr>
          <th>Typ</th>
          <th>Stawka</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor='let rate of rates'>
          <td>{{ rate.trainingType }}</td>
          <td>{{ rate.amount.amount }} {{ rate.amount.currency }}</td>
        </tr>
      </tbody>
    </table>
  </div>

</div>