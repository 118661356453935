export interface ClientGroup {
  id: string;
  name: string;
  clients: Client[] | null;
}

export class Client {
  id: string;
  firstName: string;
  lastName: string;
  level: number;
  dateOfBirth: Date | null;
}