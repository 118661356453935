import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ClientGroup } from '../../client';
import { ClientService } from '../../client.service';

@Component({
  templateUrl: './client-group-detail-info-edit.component.html'
})
export class ClientGroupDetailInfoEditComponent implements OnInit {
  pageTitle = 'ClientGroup Edit';
  errorMessage: string;

  private currentClientGroup: ClientGroup;
  private originalClientGroup: ClientGroup;
  private dataIsValid: boolean = false;

  get isDirty(): boolean {
    return JSON.stringify(this.originalClientGroup) !== JSON.stringify(this.currentClientGroup);
  }

  get clientGroup(): ClientGroup {
    return this.currentClientGroup;
  }

  set clientGroup(value: ClientGroup) {
    this.currentClientGroup = value;
    // Clone the object to retain a copy
    this.originalClientGroup = Object.assign({}, value);
  }

  constructor(private route: ActivatedRoute,
    private router: Router,

    private clientService: ClientService) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      const dataName = 'clientGroup';
      this.onClientGroupRetrieved(data[dataName])
    });
  }

  onClientGroupRetrieved(clientGroup: ClientGroup): void {
    if (clientGroup === undefined) {
      this.clientGroup = {
        name: "",
        id: ""
      } as ClientGroup;
      this.pageTitle = 'Dodaj rodzinę';
    }
    else {
      this.clientGroup = clientGroup;
      this.pageTitle = `Edytuj rodzinę: ${this.clientGroup.name}`;
    }
  }

  isAddPage(): boolean {
    return this.clientGroup.id === "";
  }

  isValid(): boolean {
    this.validate();
    return this.dataIsValid;
  }

  validate(): void {
    // Clear the validation object
    this.dataIsValid = false;
    if (this.clientGroup.name &&
      this.clientGroup.name.length >= 3 &&
      this.clientGroup.name.length <= 50
      //TODO: Add more validation
    ) {
      this.dataIsValid = true;
    } else {
      this.dataIsValid = false;
    }
  }

  deleteClientGroup(): void {
    if (!this.clientGroup.id) {
      // Don't delete, it was never saved.
      this.onSaveComplete(`${this.clientGroup.name} was deleted`);
    } else {
      if (confirm(`Czy na pewno usunąć rodzinę '${this.clientGroup.name}'?`)) {
        this.clientService.deleteClientGroup(this.clientGroup.id).subscribe(
          () => this.onSaveComplete(`${this.clientGroup.name} was deleted`),
          (error) => this.errorMessage = error
        );
      }
    }
  }

  saveClientGroup(): void {
    if (this.isValid()) {
      this.clientService.saveClientGroup(this.clientGroup).subscribe(
        () => this.onSaveComplete(`${this.clientGroup.name} was saved`),
        (error) => this.errorMessage = error
      );
    } else {
      this.errorMessage = 'Please correct the validation errors.';
    }
  }

  onSaveComplete(message?: string): void {
    console.log(message);
    this.reset();
    // Navigate back to the season list
    this.router.navigate(['/manager/families']);
  }

  reset(): void {
    this.dataIsValid = null;
    this.currentClientGroup = null;
    this.originalClientGroup = null;
  }
}
