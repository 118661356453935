import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientService } from '../../client.service';

import { ClientGroup } from '../../client';
import { Training, Trainings } from '../../training';
import { getStatus, ClientTrainingStatus } from '../../../trainings/client-training-status';

@Component({
  templateUrl: './client-group-trainings.component.html'
})
export class ClientGroupTrainingsComponent implements OnInit {
  pageTitle = 'Client Group Trainings';
  clientGroup: ClientGroup;
  errorMessage: string;
  selectedSeasonId: string;
  clientGroupTrainings: Trainings;
  selectedMonth: Date = new Date();


  constructor(private route: ActivatedRoute,
    private router: Router,
    private clientService: ClientService
  ) {
  }

  public getClientStatus(training: Training): ClientTrainingStatus {
    return getStatus(training);
  }

  ngOnInit(): void {
    this.route.parent.data.subscribe(data => {
      const dataName = 'clientGroup';
      this.clientGroup = data[dataName];
    });
  }

  getTrainings(): void {
    this.clientGroupTrainings = null;
    this.clientService.getTrainings(this.clientGroup.id, this.selectedSeasonId, this.selectedMonth)
      .subscribe(
        (trainings: Trainings) => {
          // this.instructors = this.performSearch(instructors);
          this.clientGroupTrainings = trainings;
          // this.filteredTrainings = this.filterTrainings();
          // this.salary = this.sum();
        },
        (error: any) => this.errorMessage = error
      );
  }

  onMonthSelected(event, month: Date) {
    this.selectedMonth = event;
    // this.filteredTrainings = this.filterTrainings();
    this.getTrainings()
    // this.salary = this.sum();
  }

  onSeasonChange(seasonId: string): void {
    this.selectedSeasonId = seasonId;
    this.getTrainings();
  }

  generateBill(): void {
    this.clientService.generateBill(this.clientGroup.id, this.selectedSeasonId, new Date(this.selectedMonth)).subscribe(
      () => this.onSaveComplete(`$Bill for month '${this.selectedMonth}' was saved`),
      (error) => this.errorMessage = error
    );
  }

  onSaveComplete(message?: string): void {
    console.log(message);
    // Navigate to the bills list
    this.router.navigate(['/manager/families', this.clientGroup.id, 'details', 'accounting']);
  }
}
