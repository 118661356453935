import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Season } from './season';
import { SeasonService } from './season.service';

@Component({
  selector: 'season-component',
  templateUrl: './season.component.html'
})
export class SeasonComponent implements OnInit {
  seasons: Season[];
  private _selectedSeasonId: string;
  errorMessage: string;

  @Output() seasonChange = new EventEmitter<string>();

  public get selectedSeasonId(): string {
    return this._selectedSeasonId;
  }

  //https://www.dotnettricks.com/learn/angular/sharing-data-between-angular-components-methods
  //https://etutorialz.com/how-to-pass-dynamic-data-to-a-component-in-angular
  public set selectedSeasonId(value: string) {
    this._selectedSeasonId = value;
    this.seasonChange.emit(value);
  }

  constructor(private seasonService: SeasonService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.getSeasons();
    });
  }

  getSeasons(): void {
    this.seasonService.getSeasons()
      .subscribe(
        (seasons: Season[]) => {
          this.seasons = seasons;
          this.selectedSeasonId = seasons[0].id
        },
        (error: any) => this.errorMessage = error
      );
  }
}
