import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Instructor } from '../../instructor';
import { InstructorService } from '../../instructor.service';
import { parseDate } from '../../../utils';

@Component({
  templateUrl: './instructor-detail-info-edit.component.html'
})
export class InstructorDetailInfoEditComponent implements OnInit {
  pageTitle = 'Instructor Edit';
  errorMessage: string;

  private currentInstructor: Instructor;
  private originalInstructor: Instructor;
  private dataIsValid: boolean = false;

  parseDate(event: Event): Date | null {
    let value = (event.target as HTMLInputElement).value;
    return parseDate(value)
  }

  get isDirty(): boolean {
    return JSON.stringify(this.originalInstructor) !== JSON.stringify(this.currentInstructor);
  }

  get instructor(): Instructor {
    return this.currentInstructor;
  }

  set instructor(value: Instructor) {
    this.currentInstructor = value;
    // Clone the object to retain a copy
    this.originalInstructor = Object.assign({}, value);
  }

  constructor(private route: ActivatedRoute,
    private router: Router,

    private instructorService: InstructorService) {
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      const dataName = 'instructor';
      this.onInstructorRetrieved(data[dataName])
    });
  }

  onInstructorRetrieved(instructor: Instructor): void {
    if (instructor === undefined) {
      this.instructor = {
        firstName: "",
        lastName: "",
        id: ""
      } as Instructor;
      this.pageTitle = 'Dodaj instruktora';
    }
    else {
      this.instructor = instructor;
      this.pageTitle = `Edytuj instruktora: ${this.instructor.firstName} ${this.instructor.lastName}`;
    }
  }

  isAddPage(): boolean {
    return this.instructor.id === "";
  }

  isValid(): boolean {
    this.validate();
    return this.dataIsValid;
  }

  validate(): void {
    // Clear the validation object
    this.dataIsValid = false;
    if (this.instructor.firstName &&
      this.instructor.firstName.length >= 3 &&
      this.instructor.firstName.length <= 50 &&
      this.instructor.lastName &&
      this.instructor.lastName.length >= 3 &&
      this.instructor.lastName.length <= 50
      //TODO: Add more validation
    ) {
      this.dataIsValid = true;
    } else {
      this.dataIsValid = false;
    }
  }

  deleteInstructor(): void {
    if (!this.instructor.id) {
      // Don't delete, it was never saved.
      this.onSaveComplete(`${this.instructor.firstName} ${this.instructor.lastName} was deleted`);
    } else {
      if (confirm(`Czy na pewno usunąć instruktora '${this.instructor.firstName} ${this.instructor.lastName}'?`)) {
        this.instructorService.deleteInstructor(this.instructor.id).subscribe(
          () => this.onSaveComplete(`${this.instructor.firstName} ${this.instructor.lastName} was deleted`),
          (error) => this.errorMessage = error
        );
      }
    }
  }

  saveInstructor(): void {
    if (this.isValid()) {
      this.instructorService.saveInstructor(this.instructor).subscribe(
        () => this.onSaveComplete(`${this.instructor.firstName} ${this.instructor.lastName} was saved`),
        (error) => this.errorMessage = error
      );
    } else {
      this.errorMessage = 'Please correct the validation errors.';
    }
  }

  onSaveComplete(message?: string): void {
    console.log(message);
    this.reset();
    // Navigate back to the season list
    this.router.navigate(['/manager/instructors']);
  }

  reset(): void {
    this.dataIsValid = null;
    this.currentInstructor = null;
    this.originalInstructor = null;
  }
}
