<div class="card">
  <div class="card-header">
    {{pageTitle}}
  </div>
  <br>
  <div class="card-body">
    <div>Od: {{season.from | date: 'dd.MM.yyyy'}}</div>
    <div>Do: {{season.to | date: 'dd.MM.yyyy'}}</div>
    <div class="table-responsive">
      <table class="jumpcamp-table" *ngIf="season.instructorRates && season.instructorRates.length">
        <caption>Stawki instruktorskie</caption>
        <thead>
          <tr>
            <th>Typ</th>
            <th>Stawka</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let rate of season.instructorRates'>
            <td>{{ rate.trainingType }}</td>
            <td>{{ rate.amount.amount }} {{ rate.amount.currency }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-responsive">
      <table class="jumpcamp-table" *ngIf="season.trainingPrices && season.trainingPrices.length">
        <caption>Cennik treningów</caption>
        <thead>
          <tr>
            <th>Typ</th>
            <th>Cena</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let rate of season.trainingPrices'>
            <td>{{ rate.trainingType }}</td>
            <td>{{ rate.amount.amount }} {{ rate.amount.currency }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
</div>