import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: "the-album",
    templateUrl:"album.component.html"
})

export class AlbumComponent implements OnInit, OnDestroy{

    //@Input() id: string;
    public folderName: string;
    private sub: any;

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.sub = this.route.params.subscribe(params => {
            this.folderName = params['id']; // (+) converts string 'id' to a number

            // In a real app: dispatch action to load the details here.
        });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}