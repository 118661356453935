import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Place } from './place';
import { PaymentInfo } from '../models/paymentInfo';

@Injectable({
  providedIn: 'root'
})
export class PlaceService {
  // private placesUrl = window.location.origin + '/api/places';
  private placesUrl = '/manager/places';

  constructor(private http: HttpClient) { }

  getPlaces(): Observable<Place[]> {
    return this.http.get<Place[]>(this.placesUrl)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getPlace(id: string): Observable<Place> {
    if (id === "") {
      return of(this.initializePlace());
    }
    const url = `${this.placesUrl}/${id}`;
    return this.http.get<Place>(url)
      .pipe(
        tap(data => console.log('Data: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  savePlace(place: Place): Observable<Place> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    if (place.id === "") {
      return this.createPlace(place, headers);
    }
    return this.updatePlace(place, headers);
  }

  deletePlace(id: string): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    const url = `${this.placesUrl}/${id}`;
    return this.http.delete<Place>(url, { headers })
      .pipe(
        tap(data => console.log('deletePlace: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private createPlace(place: Place, headers: HttpHeaders): Observable<Place> {
    place.id = null;
    return this.http.post<Place>(this.placesUrl, place, { headers })
      .pipe(
        tap(data => console.log('createPlace: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private updatePlace(place: Place, headers: HttpHeaders): Observable<Place> {
    const url = `${this.placesUrl}/${place.id}`;
    return this.http.put<Place>(url, place, { headers })
      .pipe(
        tap(data => console.log('updatePlace: ' + place.id)),
        catchError(this.handleError)
      );
  }

  private initializePlace(): Place {
    // Return an initialized object
    return {
      id: '',
      name: '',
      singleTicketPrice: new PaymentInfo()
    };
  }

  private handleError(err: any) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.error}`;
    }
    console.error(err);
    return throwError(errorMessage);
  }
}
