<div class="col-xs-12 no-padding">
    <div class="col-xs-12 col-sm-6 col-md-6 offer-content holiday-content" style="height: 80vh; padding-top: 0px;">
        <div class="row style-2" style="overflow: auto; height: 100%; direction: rtl; padding-bottom: 20px;">
            <div style="direction: ltr; padding-left: 10px;">
                <br />
                <h1 class="holiday-title" style="color: #e52c8a;">29.04-3.05.2023</h1>
                <h2 class="holiday-title" style="color: #0071b9">zakończenie sezonu<span
                        class="holiday-title text-color-turquoise">!</span></h2>
                <br />
                <p>
                    <b>zakwaterowanie w apartamentach + skipass 4 dni + ubezpieczenie:</b>
                </p>
                <p>
                    osoba dorosła:&ensp;&ensp;&ensp; &emsp; &emsp;<span
                        class="hidden-xs">&emsp;&emsp;&emsp;&emsp;</span> 442 eur<br />
                    junior (2005-2007):&emsp;&emsp;<span class="hidden-xs">&emsp;&emsp;&emsp;&emsp;</span> 377 eur<br />
                    dziecko (2008-2017): &emsp;<span class="hidden-xs">&emsp;&emsp;&emsp;&emsp;</span> 310 eur<br />
                    dziecko (≥2018):&ensp;&emsp;&emsp;&emsp;<span class="hidden-xs">&emsp;&emsp;&emsp;&emsp;</span>196
                    eur<br />
                </p>
                <br>
                <p>
                    <b>szkolenie narciarskie: &emsp;260 eur</b><br />
                    4 dni szkolenia w małych grupach (max 6 osób)<br />
                    z treningiem na tyczkach, wideo analiza<br />
                </p>
                <br>
                <p>
                    <b>zakwaterowanie:</b><br />
                    w apartamentach 4-6-osobowych <a href="https://www.appartement-muehle-kaprun.at">Appartement Muhle
                        Kaprun</a><br />
                    od 1 maja ważna karta <a
                        href="https://www.zellamsee-kaprun.com/de/erleben/sommerkarte/partner-attraktionen">Sommerkarte</a>
                    dająca darmowe wejścia do wielu obiektów<br />
                </p>
                <br>
                <p>
                    <b>do wyboru:</b><br />
                    TYP A, 55m<sup>2</sup>, 5-6 osób<br />
                    (1 łazienka + 1 WC, 2 sypialnie + salon, 2 łóżka podwójne + sofa)<br />
                    <br />
                    TYP B, 35m<sup>2</sup>, 3-4 osoby<br />
                    (1 łazienka + 1 WC, 1 sypialnia + salon, 1 łóżko podwójne + sofa)<br />
                </p>
                <br>
                <p>
                    <b>ośrodek narciarski:</b><br />
                    <a href="https://www.kitzsteinhorn.at/pl">Kitzteinhorn Kaprun</a>
                </p>
                <br>
                <p>
                    <b>program:</b><br />

                    sobota, 29.04.2023<br />
                    - przyjazd uczestników, zakwaterowanie<br />
                    - 21.00 spotkanie organizacyjne<br />
                    <br />

                    niedziela, 30.04.2023<br />
                    - 09.30 - zbiórka dzieci - przekazanie pod opiekę instruktorów<br />
                    - 09:30 - 12.30 trening - slalom gigant, jazda zadaniowa<br />
                    - przerwa<br />
                    - 13.30 – 15.30 trening - slalom gigant, jazda zadaniowa<br />
                    - 20:00 - 22:00 - wideo analiza w grupach<br />
                    <br />

                    poniedziałek, 01.05.2023<br />
                    - 09.30 - zbiórka dzieci - przekazanie pod opiekę instruktorów<br />
                    - 09.30 - 12.30 trening - slalom gigant, jazda zadaniowa<br />
                    - przerwa<br />
                    - 13.30 – 15.30 trening - slalom gigant, jazda zadaniowa<br />
                    - 20:00 - 22:00 - wideo analiza w grupach<br />
                    <br />

                    wtorek, 02.05.2023<br />
                    - 09.30 - zbiórka dzieci - przekazanie pod opiekę instruktorów<br />
                    - 09.30 - 12.30 trening - slalom gigant, jazda zadaniowa<br />
                    - przerwa<br />
                    - 13.30 – 15.30 trening - slalom gigant, jazda zadaniowa<br />
                    - 20:00 - 22:00 - wideo analiza w grupach<br />
                    <br />

                    środa, 03.05.2023<br />
                    - 09.30 - zbiórka dzieci - przekazanie pod opiekę instruktorów<br />
                    - 09.30 - 13.00 trening - slalom gigant, jazda zadaniowa<br />
                </p>
                <br>
                <button style="display: block; margin-left: auto; margin-right: auto;" id="join-us-button"
                    class="button-round color-blue">jadę!</button>
            </div>
        </div>
    </div>
    <div class="col-sm-6 col-md-6 hidden-xs" style="height: 80vh; padding-left: 0px;">
        <img style="display: block; width:100%; height: 100%; object-fit: cover; margin: auto; margin-top: 0px; mari !important;"
            class="hidden-xs" src="assets/images/icons/kaprun.jpg" alt="children">
        <br />
    </div>
</div>