import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountingPayment } from '../payment';
import { AccountingService } from '../accounting.service';

@Component({
  templateUrl: './accounting-out.component.html'
})

export class AccountingOutComponent implements OnInit {
  pageTitle = 'Wydatki';
  errorMessage: string;
  outcomes: AccountingPayment[];
  selectedSeasonId: string;

  constructor(private route: ActivatedRoute,
    private accountingService: AccountingService
  ) {
  }

  ngOnInit(): void {
    // this.route.parent.data.subscribe(data => {
    // });
  }

  public get sortedOutcomes(): AccountingPayment[] {
    return this.outcomes.sort((a, b) => {
      return <any>new Date(b.date) - <any>new Date(a.date);
    });
  }

  onSeasonChange(seasonId: string): void {
    this.selectedSeasonId = seasonId;
    this.getOutcomes();
  }

  getOutcomes(): void {
    this.accountingService.getOutcomes(this.selectedSeasonId)
      .subscribe(
        (outcomes: AccountingPayment[]) => {
          this.outcomes = outcomes;
        },
        (error: any) => this.errorMessage = error
      );
  }

  onPaymentDelete(paymentId) {
    if (confirm("Are you sure to delete permanently?")) {
      this.accountingService.deleteOutcome(paymentId).subscribe(value => {
        if (value) {
          this.outcomes = this.outcomes.filter(function (obj) {
            return obj.id !== paymentId;
          });
        }
        else {
          this.errorMessage = "Something went wrong while deleting outcome :(";
        }
      });
    }
  }
}
