import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";

import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AccountingPayment, Entity } from './payment';
import { Debt } from './debt';
import { AccountingSummary, AccountingSeasonalSummary } from './summary';
import { AccountingSeasonSummary } from './season-summary';

@Injectable({
  providedIn: 'root'
})
export class AccountingService {
  // private accountingUrl = window.location.origin + '/api/accounting';
  private accountingUrl = '/manager/accounting';

  constructor(private http: HttpClient) { }

  getSummary(): Observable<AccountingSummary[]> {
    return this.http.get<AccountingSummary[]>(`${this.accountingUrl}/summary`)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getSeasonalSummary(): Observable<AccountingSeasonalSummary[]> {
    return this.http.get<AccountingSeasonalSummary[]>(`${this.accountingUrl}/seasonalSummary`)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getSeasonCategorySummary(seasonId: string): Observable<AccountingSeasonSummary[]> {
    return this.http.get<AccountingSeasonSummary[]>(`${this.accountingUrl}/seasonCategorySummary?seasonId=${seasonId}`)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getDebts(): Observable<Debt[]> {
    return this.http.get<Debt[]>(`${this.accountingUrl}/debts`)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getOutcomes(seasonId: string): Observable<AccountingPayment[]> {
    return this.http.get<AccountingPayment[]>(`${this.accountingUrl}/out?seasonId=${seasonId}`)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getOutcome(paymentId: string): Observable<AccountingPayment> {
    const url = `${this.accountingUrl}/out/${paymentId}`;
    return this.http.get<AccountingPayment>(url)
      .pipe(
        tap(data => console.log('Data: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  saveOutcome(seasonId: string, payment: AccountingPayment): Observable<AccountingPayment> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    if (payment.id === null || payment.id === "") {
      return this.createOutcome(seasonId, payment, headers);
    }
    return this.updateOutcome(payment, headers);
  }

  private createOutcome(seasonId: string, payment: AccountingPayment, headers: HttpHeaders): Observable<AccountingPayment> {
    payment.id = null;
    return this.http.post<AccountingPayment>(`${this.accountingUrl}/out?seasonId=${seasonId}`, payment, { headers })
      .pipe(
        tap(data => console.log('createOutcome: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private updateOutcome(payment: AccountingPayment, headers: HttpHeaders): Observable<AccountingPayment> {
    const url = `${this.accountingUrl}/out/${payment.id}`;
    return this.http.put<AccountingPayment>(url, payment, { headers })
      .pipe(
        tap(data => console.log('updateOutcome: ' + payment.id)),
        catchError(this.handleError)
      );
  }

  deleteOutcome(paymentId: string): Observable<boolean> {
    const url = `${this.accountingUrl}/out/${paymentId}`;
    return this.http.delete(url).pipe(map((res) => {
      console.log(res);
      return true;
    }));
  }

  getIncomes(seasonId: string): Observable<AccountingPayment[]> {
    return this.http.get<AccountingPayment[]>(`${this.accountingUrl}/in?seasonId=${seasonId}`)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getIncome(paymentId: string): Observable<AccountingPayment> {
    const url = `${this.accountingUrl}/in/${paymentId}`;
    return this.http.get<AccountingPayment>(url)
      .pipe(
        tap(data => console.log('Data: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  saveIncome(seasonId: string, payment: AccountingPayment): Observable<AccountingPayment> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    if (payment.id === null || payment.id === "") {
      return this.createIncome(seasonId, payment, headers);
    }
    return this.updateIncome(payment, headers);
  }

  private createIncome(seasonId: string, payment: AccountingPayment, headers: HttpHeaders): Observable<AccountingPayment> {
    payment.id = null;
    return this.http.post<AccountingPayment>(`${this.accountingUrl}/in?seasonId=${seasonId}`, payment, { headers })
      .pipe(
        tap(data => console.log('createIncome: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private updateIncome(payment: AccountingPayment, headers: HttpHeaders): Observable<AccountingPayment> {
    const url = `${this.accountingUrl}/in/${payment.id}`;
    return this.http.put<AccountingPayment>(url, payment, { headers })
      .pipe(
        tap(data => console.log('updateIncome: ' + payment.id)),
        catchError(this.handleError)
      );
  }

  deleteIncome(paymentId: string): Observable<boolean> {
    const url = `${this.accountingUrl}/in/${paymentId}`;
    return this.http.delete(url).pipe(map((res) => {
      console.log(res);
      return true;
    }));
  }

  getEntities(): Observable<Entity[]> {
    const url = `${this.accountingUrl}/entities`;
    return this.http.get<Entity[]>(url)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private handleError(err: any) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err.status}: ${err.body.error}`;
    }
    console.error(err);
    return throwError(errorMessage);
  }
}
