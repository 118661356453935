<div style="height: 100%">
  <mat-toolbar color="warn">
    <mat-toolbar-row>
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" color="primary">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-sidenav-container style="height: 90%;" class="example-container">
    <mat-sidenav style="height: 100%" #drawer mode="side" opened role="navigation"
      style="overflow-x: hidden; overflow-y: auto;">
      <mat-nav-list>
        <a mat-list-item [routerLink]="['trainings']" [routerLinkActive]="['is-active']">Treningi</a>
        <a mat-list-item [routerLink]="['instructors']" [routerLinkActive]="['is-active']">Instruktorzy</a>
        <a mat-list-item [routerLink]="['families']" [routerLinkActive]="['is-active']">Klienci</a>
        <a mat-list-item [routerLink]="['debts']" [routerLinkActive]="['is-active']">Komornik</a>
        <a mat-list-item [routerLink]="['places']" [routerLinkActive]="['is-active']">Miejsca</a>
        <a mat-list-item [routerLink]="['seasons']" [routerLinkActive]="['is-active']">Sezony</a>
        <a mat-list-item [routerLink]="['accounting']" [routerLinkActive]="['is-active']">Rozliczenia</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="style-2" style="overflow-x: hidden; overflow-y: auto; max-height: 700px;">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>