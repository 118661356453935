<div class="card">
  <!-- Filter the Title   -->
  <div class="card-body">
    <div class="col-md-2">Szukaj:</div>
    <div class="col-md-4">
      <input class="jumpcamp-input" type="text" [(ngModel)]="listFilter" />
    </div>
    <div class="col-md-2">
      <button class="btn btn-primary" [routerLink]="['add']" [relativeTo]="route">
        Dodaj Rodzinę
      </button>
    </div>

    <div class="table-responsive col-md-12">
      <table class="jumpcamp-table" *ngIf="filteredClientGroups && filteredClientGroups.length">
        <thead>
          <tr>
            <th>Rodzina</th>
            <th>Imię Nazwisko</th>
            <th>Wiek</th>
            <th>Level</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let clientGroup of filteredClientGroups">
            <td>
              <a [routerLink]="[clientGroup.id, 'details']" [relativeTo]="route">
                {{clientGroup.name}}
              </a>
            </td>

            <td>
              <div *ngFor="let client of clientGroup.clients">
                {{client.firstName}} {{client.lastName}}
              </div>
            </td>
            <td>
              <div *ngFor="let client of clientGroup.clients">
                {{ displayAge(calculateAge(client.dateOfBirth)) }}
              </div>
            </td>
            <td>
              <div *ngFor="let client of clientGroup.clients">
                {{ client.level }}
              </div>
            </td>
            <td>
              <button class="btn btn-primary" [routerLink]="[clientGroup.id, 'edit']" [relativeTo]="route">
                Edytuj
              </button>
            </td>
          </tr>
          <!-- <tr *ngIf="clientGroup.clients && clientGroup.clients.length" ng-repeat-end ng-repeat="client in clientGroup.clients">
            <td>{{client.firstName}}</td>
          </tr> -->
        </tbody>
      </table>
    </div>
  </div>

  <div class='alert alert-danger' *ngIf='errorMessage'>
    Error: {{ errorMessage }}
  </div>
</div>