<div class="card-body">

  <div class="wizard">
    <a [routerLink]="['info']" [routerLinkActive]="['is-active']">
      Dane
    </a>
    |
    <a [routerLink]="['persons']" routerLinkActive="is-active">
      Osoby
    </a>
    |
    <a [routerLink]="['trainings']" routerLinkActive="is-active">
      Treningi
    </a>
    |
    <a [routerLink]="['accounting']" routerLinkActive="is-active">
      Rozliczenia
    </a>
    |
    <a [routerLink]="['subs']" routerLinkActive="is-active">
      Subskrypcje
    </a>
    |
    <a [routerLink]="['recommendations']" routerLinkActive="is-active">
      Polecenia
    </a>
  </div>
  <br>
  <router-outlet></router-outlet>