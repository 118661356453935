<div class="card">
  <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
  </div>
  <div class="card-body">

    <div class="row">

      <season-component class="col-md-2" (seasonChange)="onSeasonChange($event)"></season-component>
      <div class="col-md-2">
        Pokaż wszystkie: <input class="jumpcamp-input" type="checkbox" [(ngModel)]="showAll">
      </div>
      <span class="col-md-4" *ngIf="!showAll">
        Miesiąc: <input class="jumpcamp-input" type="month" [ngModel]="selectedMonth | date:'yyyy-MM'"
          (ngModelChange)="onMonthSelected($event, selectedMonth)">
      </span>
      <button class="col-md-2" class="btn btn-primary" [routerLink]="['/manager/trainings', 'add']"
        [queryParams]="{seasonId: selectedSeasonId}">
        Dodaj Trening
      </button>
    </div>

    <div class="table-responsive">
      <table class="jumpcamp-table" *ngIf="filteredTrainings && filteredTrainings.length">
        <thead>
          <tr>
            <th>Dzień</th>
            <th>Miejsce</th>
            <th>Godzina</th>
            <th>Typ</th>
            <th>Klienci</th>
            <th>Instruktorzy</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let training of pastTrainings() | SortTrainingByDateFromAndType"
            [class.cancelled]="training.cancelled" [ngStyle]="{'background-color': getColor(training.type)}">
            <td>{{ training.from | date:'dd.MM.yyyy E'}}</td>
            <td>
              <ng-container *ngIf="training.cancelled">
                Odwołany
              </ng-container>
              <ng-container *ngIf="!training.cancelled && training.place">
                {{ training.place.name }}
              </ng-container>
            </td>
            <td>{{training.from | date:'HH:mm'}} - {{training.to | date:'HH:mm'}}</td>
            <td>{{ training.type | slice:0:1 }}</td>
            <td>{{ training.clients.length }}</td>
            <td>{{ training.instructors.length }}</td>
            <td>
              <button class="btn btn-primary" [routerLink]="['/manager/trainings', training.id, 'edit']">
                Edytuj
              </button>
            </td>
          </tr>
          <tr *ngIf="pastTrainings().length && futureTrainings().length"></tr>
          <tr *ngFor="let training of futureTrainings() | SortTrainingByDateFromAndType"
            [class.cancelled]="training.cancelled" [ngStyle]="{'background-color': getColor(training.type)}">
            <td>{{ training.from | date:'dd.MM.yyyy E'}}</td>
            <td>
              <ng-container *ngIf="training.cancelled">
                Odwołany
              </ng-container>
              <ng-container *ngIf="!training.cancelled && training.place">
                {{ training.place.name }}
              </ng-container>
            </td>
            <td>{{training.from | date:'HH:mm'}} - {{training.to | date:'HH:mm'}}</td>
            <td>{{ training.type | slice:0:1 }}</td>
            <td>{{ training.clients.length }}</td>
            <td>{{ training.instructors.length }}</td>
            <td>
              <button class="btn btn-primary" [routerLink]="['/manager/trainings', training.id, 'edit']">
                Edytuj
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>