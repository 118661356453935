<div class="card">
  <div class="card-header">
    {{pageTitle}}
  </div>
  <br>
  <div class="card-body">
    <div class="col-xs-12 col-md-6">
      <div class="col-xs-12">
        <label>Od</label>
        <input class="jumpcamp-input" type="datetime-local" [(ngModel)]="training.from"
          (ngModelChange)="training.from = $event; training.to = calculateEnd($event)" required>
      </div>
      <div class="col-xs-12">
        <label>Do</label>
        <input class="jumpcamp-input" type="datetime-local" [(ngModel)]="training.to"
          (ngModelChange)="training.to = $event" required>
      </div>
      <div class="col-xs-12">
        <label>Miejsce</label>
        <select class="jumpcamp-input" matNativeControl [compareWith]="compareFn" [ngModel]="training.place"
          (ngModelChange)="onPlaceChange($event)">
          <option [ngValue]="null"></option>
          <option *ngFor="let place of allPlaces" [ngValue]="place">
            {{place.name}}
          </option>
        </select>
      </div>
      <div class="col-xs-12">
        <label>Cena karnetu instruktorskiego</label>
        <input class="jumpcamp-input" type="number" min="0" max="100" required mdInput placeholder="Kwota"
          [(ngModel)]="training.instructorTicketPrice.amount">
        <select class="jumpcamp-input" matNativeControl [(ngModel)]="training.instructorTicketPrice.currency">
          <option *ngFor="let currency of currencies" [value]="currency">
            {{currency}}
          </option>
        </select>
      </div>
      <div class="col-xs-12">
        <label>Typ</label>
        <select class="jumpcamp-input" matNativeControl [(ngModel)]="training.type">
          <option *ngFor="let trainingType of trainingTypes" [value]="trainingType">
            {{trainingType}}
          </option>
        </select>
      </div>
      <div class="col-xs-12" *ngIf="!isAddPage()">
        <label>Anulowany</label>
        <input class="jumpcamp-input" type="checkbox" [(ngModel)]="training.cancelled" required>
      </div>
      <div class="col-xs-12" *ngIf="training.type == 'Group'">
        <label>Wskaźnik</label>
        <span> {{getPresentClientsNumber()}}/{{training.instructors.length}} =
          {{getPresentClientsNumber()/training.instructors.length | number : '1.2-2'}}</span>
      </div>

    </div>
    <div class="col-xs-12 col-md-6" *ngIf="training.type == 'Group'">
      <table class="jumpcamp-table" style="margin-top: 0;">
        <thead>
          <tr>
            <th>Level</th>
            <th>Osób</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let level of levels' [ngStyle]="{'background-color': getColor(level)}">
            <td>
              <span [ngStyle]="{'background-color': getColor(level, 1)}" class="level-dot"></span>
              {{ level }}
            </td>
            <td>{{ getNumberOfClients(level) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-responsive col-xs-12">
      <table class="jumpcamp-table">
        <caption>
          <div>Instruktorzy</div>
        </caption>
        <thead>
          <tr>
            <th>Imię Nazwisko</th>
            <th>Korekta wypłaty</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let instructor of training.instructors'>
            <td>{{ instructor.firstName }} {{ instructor.lastName }}</td>
            <td>
              <input class="jumpcamp-input" type="number" min="0" max="100" required mdInput placeholder="Kwota"
                [(ngModel)]="instructor.paymentCorrection.amount">
              <select class="jumpcamp-input" matNativeControl [(ngModel)]="instructor.paymentCorrection.currency">
                <option *ngFor="let currency of currencies" [value]="currency">
                  {{currency}}
                </option>
              </select>
            <td>
              <button class="btn btn-danger" (click)="removeInstructor(instructor)">
                Usuń
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <select class="jumpcamp-input" matNativeControl [(ngModel)]="newInstructor">
                <option *ngFor="let person of filteredInstructors" [ngValue]="person">
                  {{person.firstName}} {{person.lastName}}
                </option>
              </select>
            </td>
            <td>
              <input class="jumpcamp-input" type="number" min="0" max="100" required mdInput placeholder="Kwota"
                [(ngModel)]="newInstructor.paymentCorrection.amount">
              <select class="jumpcamp-input" matNativeControl [(ngModel)]="newInstructor.paymentCorrection.currency">
                <option *ngFor="let currency of currencies" [value]="currency">
                  {{currency}}
                </option>
              </select>
            </td>
            <td>
              <button class="btn btn-primary" (click)="addInstructor()">
                Dodaj
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-responsive col-xs-12">
      <table class="jumpcamp-table">
        <caption>
          <div>Klienci</div>
        </caption>
        <thead>
          <tr>
            <th>Imię Nazwisko</th>
            <th>Level</th>
            <th>Status</th>
            <th>Notatka</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let client of training.clients | SortByLevel:'DESC'" [class.cancelled]="absent(client)"
            [ngStyle]="{'color': getColor(client.level, 1)}">
            <td>{{
              client.firstName }} {{ client.lastName }} </td>
            <td [routerLink]="['/manager/families', client.clientGroupId, 'details', 'persons', client.id, 'edit']">{{
              client.level }} <span *ngIf="!absent(client)" [ngStyle]="{'background-color': getColor(client.level, 1)}"
                class="level-dot"></span></td>
            <td>
              <select class="jumpcamp-input" matNativeControl [ngModel]="getClientStatus(client)"
                (change)="updateClientStatusFront(client, $event)">
                <option *ngFor="let status of clientStatuses" [value]="status">
                  {{status}}
                </option>
              </select>
            </td>
            <td>
              <input class="jumpcamp-input" type="text" placeholder="Notatka" maxlength="100"
                [(ngModel)]="client.note" />
            </td>
            <td>
              <button class="btn btn-danger" (click)="removeClient(client)">
                Usuń
              </button>
            </td>
          </tr>
          <tr>
            <td>
              <select class="jumpcamp-input" matNativeControl [(ngModel)]="newClient">
                <option *ngFor="let person of filteredClients" [ngValue]="person">
                  {{person.firstName}} {{person.lastName}}
                </option>
              </select>
            </td>
            <td></td>
            <td>
              <!-- <select matNativeControl (change)="var updatedClient = updateClientStatus(newClient, $event); newClient = updatedClient;"> -->
              <select class="jumpcamp-input" matNativeControl [ngModel]="getClientStatus(newClient)"
                (change)="updateClientStatusFront(newClient, $event)">
                <option *ngFor="let status of clientStatuses" [value]="status">
                  {{status}}
                </option>
              </select>
            </td>
            <td></td>
            <td>
              <button class="btn btn-primary" (click)="addClient()">
                Dodaj
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <div class="col-xs-4">
        <button class="btn btn-success mr-3" type="button" style="width:80px" [disabled]="!isValid()"
          (click)="saveTraining()">
          Zapisz
        </button>
        <button class="btn btn-warning mr-3" type="button" style="width:80px" [routerLink]="['/manager/trainings']">
          Anuluj
        </button>
        <button class="btn btn-danger" type="button" style="width:80px" (click)="deleteTraining()" *ngIf="!isAddPage()">
          Usuń
        </button>
      </div>
    </div>
  </div>
</div>

<div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
</div>