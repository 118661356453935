import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { ClientGroup } from './client';
import { ClientService } from './client.service';

@Injectable({
  providedIn: 'root'
})
export class ClientGroupResolver implements Resolve<ClientGroup> {

  constructor(private clientService: ClientService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<ClientGroup> {
    const id = route.paramMap.get('id');
    console.log(route)
    return this.clientService.getClientGroup(id);
  }
}
