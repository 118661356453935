import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { Season } from './season';
import { SeasonService } from './season.service';

@Injectable({
  providedIn: 'root'
})
export class SeasonResolver implements Resolve<Season> {

  constructor(private seasonService: SeasonService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<Season> {
    const id = route.paramMap.get('seasonId');
    console.log(route)
    return this.seasonService.getSeason(id);
  }
}
