import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Instructor } from '../../instructor';

@Component({
  templateUrl: './instructor-detail-info.component.html'
})
export class InstructorDetailInfoComponent implements OnInit {
  pageTitle = 'Instructor Detail';
  instructor: Instructor;
  errorMessage: string;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.parent.data.subscribe(data => {
      const dataName = 'instructor';
      this.instructor = data[dataName];

    });
  }

  // onBack(): void {
  //   this.router.navigate(['/instructors']);
  // }
}
