<div class="col-xs-12 flex no-padding">
    <div class="col-xs-12 flex no-padding" style="display: flex;">
        <div id="advance-offer-content" class="col-xs-12 col-sm-6 offer-content" style="display: none">
            <div class="row style-2" style="overflow: auto; height: 72vh;direction: rtl;padding-bottom: 20px;">
                <div style="direction: ltr; padding-left: 10px;">
                    <br />
                    <h3> SZCZEGÓŁY OFERTY ZIMOWEJ 2022/23</h3>
                    <br />
                    <p>
                        Zajęcia odbywają się przez cały sezon zimowy (grudzień - marzec).
                    </p>
                    <br />
                    <div>
                        <br>
                        <h4>Grupy:</h4>
                        <div>
                            4 - 6 osób<br />
                            Grupy dzieci rozpoczynających naukę liczą maksymalnie 2 osoby na istruktora, dzieci jeżdżące
                            to grupy 4-6-osobowe.
                            <br>

                            <br>
                            <h4>Zajęcia:</h4>
                            4 godziny - sobota i niedziela od
                            <br class="visible-xs"> 8:00 do 10:00<br /> oraz dodatkowo w tygodniu w godzinach późno
                            popołudniowych.
                            <br>

                            <br>
                            <h4>Przykładowy plan zajęć:</h4>
                            7:50 - zbiórka dzieci na wyznaczonym stoku<br />
                            8:00 - 10:00 - zajęcia narciarskie, prowadzone w grupach dla początkujących oraz jeżdżących
                            dzieci<br />
                            10:00 - 10:10 - odbiór dzieci z zajęć<br />
                            <br>

                            <h4>Cena uczestnictwa:</h4>
                            631 zł - opłata miesięczna
                            <br class="visible-xs">(zajęcia 2 razy w tygodniu)*<br />
                            Cena zawiera 16 godzin zajęć miesięcznie.<br />
                            <br>

                            <h4>Dodatkowe opłaty to:</h4>
                            karnet - 30-60 zł dziennie **<br /><br />
                            * opcja: 1 dzien zajęć w tygodniu
                            <br class="visible-xs"> 377 zł/miesiąc<br />
                            ** do opłat doliczamy karnet trenera<br />
                            <br>
                            Przy szkoleniu grupowym przyznajemy zniżkę na kolejne dziecko w wysokości 10%
                            <br>
                            <br>

                            <h4>Promocja:</h4>
                            Za każdą skutecznie poleconą osobę Wasze dziecko otrzyma 10 % zniżki na zajęcia grupowe.
                            Zniżki łączą się ze "zniżkami rodzinnymi" :)<br>
                            Sposób naliczania zniżek w przypadkach polecania większej liczby osób pokazaliśmy w <a
                                href="/Home/Promo">tabeli</a>.
                            Zniżka naliczana jest za każdy pełny miesiąc uczestnictwa poleconej osoby w zajęciach.
                            <br>
                            <br>
                            Wszyscy uczestnicy JUMP CAMP otrzymują <br class="visible-xs">
                            10% zniżki na cały asortyment <a href="https://ski-boot-service.pl/">sklepu</a> oraz
                            na usługi <a href="https://www.grupaszafranski.pl/index.php?kategoria=177">serwisu
                                narciarskiego</a>.
                            <br>
                            <br>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-xs-6 offer-start-container" id="basic-main">
            <div class="col-md-4 col-xs-12 offer-image-container row-sm-no-padding">
                <img id="basic-offer-image" class="offer-image center" src="assets/images/children/back1.png"
                    alt="children">
            </div>
            <div class="col-md-8 col-xs-12 offer-buttons row-sm-no-padding">
                <button id="start-journey-button" class="offer-button-round color-turquoise center">dzieci
                    rozpoczynajace przygodę<br /> z nartami
                </button>
            </div>
        </div>
        <div class="col-xs-6 offer-start-container" id="advance-main">
            <div class="col-md-4 col-xs-12 offer-buttons row-sm-no-padding">
                <button id="advance-journey-button" class="offer-button-round color-blue center">młodzi zawodnicy
                </button>
            </div>
            <div class="col-md-8 col-xs-12 offer-image-container row-sm-no-padding">
                <img id="advance-offer-image" class="offer-image center" src="assets/images/children/back2.png"
                    alt="children">
            </div>
        </div>

        <div id="basic-offer-content" class="col-xs-12 col-sm-6 offer-content" style="display: none">
            <div class="col-xs-12 no-padding">
                <div id="test" class="row">
                    <nav id="mynavBar2" class="mynavBar">
                        <div class="">
                            <ul class="nav navbar-nav">
                                <li class="active offer-nested-nav">
                                    <a class="offer-nested-button-round color-turquoise" href="#section1">Krok 1</a>
                                </li>
                                <li class="offer-nested-nav">
                                    <a class="offer-nested-button-round color-blue" href="#section2">Krok 2</a>
                                </li>
                                <li class="offer-nested-nav">
                                    <a class="offer-nested-button-round color-blue" href="#section3">Krok 3</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <div class="row scrollbar style-2" style="overflow: auto; height: 50vh" data-spy="scroll"
                    data-target="#mynavBar2" data-offset="200">
                    <div id="section1" class="section">
                        <div>
                            <h4>Krok 1</h4>
                            Dzieci jeżdżą indywidualnie<br class="visible-xs">
                            z instruktorem.<br />
                            Zajęcia odbywają się 1 lub 2 razy <br class="visible-xs">
                            w tygodniu i trwają 2 godziny.<br>
                            Jak tylko dziecko potrafi samodzielnie hamować oraz skręcać i jeździć wyciągiem,
                            przystępujemy do kroku 2-go.<br />
                            Cena każdego spotkania (2h):<br class="visible-xs">
                            165 zł (+ karnet instruktora).<br />

                        </div>
                        <br />
                    </div>
                    <div id="section2" class="section">
                        <div>
                            <h4>Krok 2</h4>
                            - to przystąpienie do mini grupy - 2-osobowej.<br />
                            Zakładamy 4 spotkania w bloku 2 godzinnym (równolegle z zajęciami Jump Camp).<br>
                            Szybkie postępy dzieci umożliwiają oczywiście skrócenie fazy zajęć<br class="visible-xs">
                            w grupach 2-osobowych i szybsze przejście do kroku 3-go.<br />
                            Jeden trener przypada na dwójkę dzieci.<br />
                            Cena każdego spotkania (2h):<br class="visible-xs">
                            120 zł (+ karnet instruktora).<br />

                        </div>
                        <br />
                    </div>
                    <div id="section3" class="section">
                        <div ng-bind-html="item">
                            <h4>Krok 3</h4>
                            - dzieci łączą się w min. 4-osobowe grupy.<br />
                            Trening prowadzony na ogólnych zasadach Jump Camp.<br />
                            Jeden trener przypada na 4-6 dzieci.<br />
                            Cena:<br>
                            377 zł/mc** (+ karnet trenera)&#160;&#160; - &ensp; &ensp; &ensp; 1 dzień zajęć<br
                                class="visible-xs">
                            w tygodniu (sobota lub niedziela)<br />
                            631 zł/mc*** (+ karnet trenera) - 2 dni zajęć<br class="visible-xs">
                            w tygodniu (sobota i niedziela)<br />
                            <br />
                            ** Przy szkoleniu grupowym przyznajemy zniżkę na kolejne dziecko w wysokości 10% - 339
                            zł.<br />
                            *** Przy szkoleniu grupowym przyznajemy zniżkę na kolejne dziecko<br class="visible-xs">
                            w wysokości 10% - 568 zł.<br />
                            <br>
                            Wszyscy uczestnicy JUMP CAMP otrzymują <br class="visible-xs">
                            10% zniżki na cały asortyment <a href="https://ski-boot-service.pl/">sklepu</a> oraz
                            na usługi <a href="https://www.grupaszafranski.pl/index.php?kategoria=177">serwisu
                                narciarskiego</a>.
                            <br>
                        </div>
                        <br />
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>