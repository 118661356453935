<div class="col-xs-12 no-padding">
    <div class="col-xs-7 col-md-6 offer-content holiday-content" style="height: 100%; padding-top: 0;">
        <div class="row style-2" style="overflow: auto; height: 72vh; direction: rtl; padding-bottom: 20px;">
            <div style="direction: ltr; padding-left: 10px;">
                <br />
                <h1 class="holiday-title" style="color: #e52c8a;">9-13.11.2022</h1>
                <h2 class="holiday-title" style="color: #0071b9">rozpocznij dobrze sezon<span
                        class="holiday-title text-color-turquoise">!</span></h2>
                <br />
                <p>
                    <b>zakwaterowanie w apartamentach + skipass 4 dni + szkolenie + ubezpieczenie:</b>
                </p>
                <p>
                    osoba dorosła:&ensp;&ensp;&ensp; &emsp; &emsp;<span
                        class="hidden-xs">&emsp;&emsp;&emsp;&emsp;</span> 715 eur*<br />
                    junior (2003-2007):&emsp;&emsp;<span class="hidden-xs">&emsp;&emsp;&emsp;&emsp;</span> 660
                    eur*<br />
                    dziecko (2008-2014): &emsp;<span class="hidden-xs">&emsp;&emsp;&emsp;&emsp;</span> 610 eur*<br />
                    dziecko (≥2015):&ensp;&emsp;&emsp;&emsp;<span class="hidden-xs">&emsp;&emsp;&emsp;&emsp;</span>520
                    eur*<br />
                    <br />
                    *bez szkolenia: &emsp;&emsp;&emsp;<span class="hidden-xs">&emsp;&emsp;&emsp;&emsp;</span> -230
                    eur<br />
                    <br />
                    ubezpieczenie od<span class="visible-xs"></span> rezygncji<span class="hidden-xs"><br /></span> z
                    imprezy: &ensp;&emsp;&ensp;<span
                        class="hidden-xs">&emsp;&ensp;&emsp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&emsp;&ensp;&emsp;</span>50
                    eur<br />
                </p>
                <br>
                <p>
                    <b>szkolenie narciarskie:</b><br />
                    4 dni szkolenia w małych grupach (max 6 osób)<br />
                    z treningiem na tyczkach, wideo analiza<br />
                </p>
                <br>
                <p>
                    <b>zakwaterowanie:</b><br />
                    w apartamentach 2-4-osobowych <a href="https://www.top-tirol.com">Top Tirol</a><br />
                    do dyspozycji SPA, basen, whirlpool, wybór saun<br />
                    <br />
                    za dodatkową dopłatą możliwość zakwaterowania w pokoju jednoosobowym (84 eur)<br>
                </p>
                <br>
                <p>
                    <b>ośrodek narciarski:</b><br />
                    <a href="https://www.soelden.com/winter.html">Sölden - Lodowiec Tiefenbach</a>
                </p>
                <br>
                <p>
                    <b>program:</b><br />

                    środa, 09.11.2022<br />
                    - przyjazd uczestników, zakwaterowanie<br />
                    - 21.00 spotkanie organizacyjne, prezentacja multimedialna<br />
                    <br />

                    czwartek, 10.11.2022<br />
                    - 09.30 - zbiórka dzieci - przekazanie pod opiekę instruktorów<br />
                    - 10:00 - 13.00 trening - slalom gigant, jazda zadaniowa<br />
                    - przerwa<br />
                    - 13.30 – 15.30 trening - slalom gigant, jazda zadaniowa<br />
                    - 20:00 - 22:00 - wideo analiza w grupach<br />
                    <br />

                    piątek, 11.11.2022<br />
                    - 09.30 - zbiórka dzieci - przekazanie pod opiekę instruktorów<br />
                    - 10:00 - 13.00 trening - slalom gigant, jazda zadaniowa<br />
                    - przerwa<br />
                    - 13.30 – 15.30 trening - slalom gigant, jazda zadaniowa<br />
                    - 20:00 - 22:00 - wideo analiza w grupach<br />
                    <br />

                    sobota, 12.11.2022<br />
                    - 09.30 - zbiórka dzieci - przekazanie pod opiekę instruktorów<br />
                    - 10:00 - 13.00 trening - slalom gigant, jazda zadaniowa<br />
                    - przerwa<br />
                    - 13.30 – 15.30 trening - slalom gigant, jazda zadaniowa<br />
                    - 20:00 - 22:00 - wideo analiza w grupach<br />
                    <br />

                    niedziela, 13.11.2022<br />
                    - 09.30 - zbiórka dzieci - przekazanie pod opiekę instruktorów<br />
                    - 10:00 - 13.00 trening - slalom gigant, jazda zadaniowa<br />
                </p>
                <br>
            </div>
        </div>
    </div>
    <div class="col-xs-5 col-md-6" style="height: 100%;">
        <img style="display: block; max-width:80%; height: auto; margin: auto; margin-top: 50px !important;"
            class="holiday-logo" src="/assets/images/icons/z_grupa_szafranski_mob.png" alt="children">
        <br />
        <button style="display: block; margin: auto;" id="join-us-button" class="button-round color-blue">jadę!</button>
    </div>
</div>