<div class="card" *ngIf="instructor">
  <div class="card-header">
    {{pageTitle}}
  </div>
  <br>
  <div class="card-body">
    <div class="row">
      <div class="col-md-2">Imię: </div>
      <input class="jumpcamp-input" type="text" placeholder="Imię (wymagane)" required minlength="3" maxlength="50"
        [(ngModel)]="instructor.firstName" name="name" #name="ngModel"
        [ngClass]="{'is-invalid': (name.touched || name.dirty) && !name.valid }" />
    </div>
    <div class="row">
      <div class="col-md-2">Nazwisko: </div>
      <input class="jumpcamp-input" type="text" placeholder="Nazwisko (wymagane)" required minlength="3" maxlength="50"
        [(ngModel)]="instructor.lastName" name="name" #name="ngModel"
        [ngClass]="{'is-invalid': (name.touched || name.dirty) && !name.valid }" />
    </div>
    <div class="row">
      <div class="col-md-2">Data urodzenia: </div>
      <input class="jumpcamp-input" type="date" [value]="instructor.dateOfBirth | date:'yyyy-MM-dd'"
        (input)="instructor.dateOfBirth = parseDate($event)" required>
    </div>

    <div class="row">
      <div class="col-md-4">
        <button class="btn btn-success mr-3" type="button" style="width:80px" [disabled]="!isValid()"
          (click)="saveInstructor()">
          Zapisz
        </button>
        <button class="btn btn-warning mr-3" type="button" style="width:80px" [routerLink]="['/manager/instructors']">
          Anuluj
        </button>
        <button class="btn btn-danger" type="button" style="width:80px" (click)="deleteInstructor()"
          *ngIf="!isAddPage()">
          Usuń
        </button>
      </div>
    </div>

  </div>

  <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
  </div>
</div>