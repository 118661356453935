import { Pipe, PipeTransform } from '@angular/core';
import { TrainingType } from '../manager/trainings/trainingType';
import { Training } from '../manager/instructors/training';

@Pipe({
    name: 'SortInstructorTrainingByDateFromAndType'
})
export class InstructorDateFromSortPipe implements PipeTransform {

    transform(values: Array<Training>, args?: string): any {

        if (args === 'ASC')
            values = values.sort(this.compare);
        else
            values = values.sort(this.compare).reverse();

        return values;
    }

    private compare(a: Training, b: Training) {
        if (a.from && b.from && a.from < b.from)
            return 1;
        if (a.from && b.from && a.from > b.from)
            return -1;
        if (a.type === TrainingType.Group && b.type !== TrainingType.Group)
            return 1;
        if (a.type !== TrainingType.Group && b.type === TrainingType.Group)
            return -1;
        return 0;
    }
}