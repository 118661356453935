export function CalculateAge(dateOfBirth: Date): number | null {
    if (new Date(dateOfBirth).getFullYear() != 1) {
        var timeDiff = Math.abs(Date.now() - new Date(dateOfBirth).getTime());
        return Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
    }
    return null;
}

export function parseDate(dateString: string): Date | null {
    if (dateString) {
        return new Date(dateString);
    }
    return null;
}