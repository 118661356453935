import { Component, OnInit } from '@angular/core';
import { AccountingSeasonSummary } from '../season-summary';
import { AccountingService } from '../accounting.service';
import { Currency, PaymentInfo } from '../../models/paymentInfo'

@Component({
  templateUrl: './season-summary.component.html'
})

export class SeasonSummaryComponent implements OnInit {
  pageTitle = 'Podsumowanie Sezonu';
  errorMessage: string;
  summary: AccountingSeasonSummary[];
  selectedSeasonId: string;

  constructor(
    private accountingService: AccountingService
  ) {
  }

  ngOnInit(): void {
  }

  onSeasonChange(seasonId: string): void {
    this.selectedSeasonId = seasonId;
    this.getSeasonCategorySummary();
  }

  getSeasonCategorySummary(): void {
    this.accountingService.getSeasonCategorySummary(this.selectedSeasonId)
      .subscribe(
        (summary: AccountingSeasonSummary[]) => {
          this.summary = summary;
        },
        (error: any) => this.errorMessage = error
      );
  }

  public currencies = Object.values(Currency);

  getAmount(amounts: PaymentInfo[], currency: string) {
    let result = null
    amounts.forEach(amount => {
      if (amount.currency == currency) {
        result = amount;
      }
    });
    return result
  }
}