import { Injectable } from '@angular/core';
import { Resolve, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { Payment } from '../../../seasons/season';
import { InstructorService } from '../../instructor.service';

@Injectable({
  providedIn: 'root'
})

//TODO: Change to generic payments resolver
export class PaymentResolver implements Resolve<Payment> {

  constructor(
    private instructorService: InstructorService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<Payment> {
    const paymentId = route.paramMap.get('paymentId');
    const ownerId = route.parent?.paramMap.get('id');
    // const instructorId = route.parent.data["instructor"].id;
    return this.instructorService.getPayment(ownerId, paymentId);
  }
}
