import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { AccountingPayment } from '../payment';
import { AccountingService } from '../accounting.service';

@Injectable({
  providedIn: 'root'
})

//TODO: Change to generic payments resolver
export class AccountingIncomeResolver implements Resolve<AccountingPayment> {

  constructor(
    private accountingService: AccountingService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<AccountingPayment> {
    const paymentId = route.paramMap.get('paymentId');
    return this.accountingService.getIncome(paymentId);
  }
}
