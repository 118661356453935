import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccessGuard, AuthGuardService } from './shared/auth.service';

// Content
import { HomeComponent } from './home/home.component'
import { AboutComponent } from './about/about.component'
import { ContactComponent } from './contact/contact.component'
import { LoginComponent } from './login/login.component'
import { OfferComponent } from './offer/offer.component';
import { GalleryPage } from './gallery/galleryPage.component';
import { AlbumComponent } from './gallery/album.component';
import { NewsPage } from './news/newsPage.component';

import { Kreischberg012023 } from './travel/kreischberg-01-2023/kreischberg-01-2023.component'
import { Soelden112022 } from './travel/soelden-11-2022/soelden-11-2022.component'
import { Zwardon032023 } from './travel/zwardon-03-2023/zwardon-03-2023.component';
import { Lyngen032023 } from './travel/lyngen-03-2023/lyngen-03-2023.component';
import { Kaprun042023 } from './travel/kaprun-04-2023/kaprun-04-2023.component';

// Manager
import { ManagerComponent } from './manager/manager.component';

// import { NavigationComponent } from './manager/navigation/navigation.component';
// import { SeasonComponent } from './manager/seasons/season.component';
// import { SecondComponent } from './manager/second/second.component';
import { SeasonListComponent } from './manager/seasons/list/season-list.component';
import { SeasonEditComponent } from './manager/seasons/edit/season-edit.component';
import { SeasonResolver } from './manager/seasons/season.resolver';
import { SeasonDetailsComponent } from './manager/seasons/details/season-details.component';
import { SeasonDetailsInfoComponent } from './manager/seasons/details/info/season-details-info.component';
import { SeasonBundlesComponent } from './manager/seasons/details/bundles/season-bundles.component';
import { BundleResolver } from './manager/seasons/details/bundles/bundle.resolver';
import { SeasonBundleEditComponent } from './manager/seasons/details/bundles/season-bundles-edit.component';

import { DebtsListComponent } from './manager/debts/debts-list.component';

import { PlaceListComponent } from './manager/places/list/place-list.component';
import { PlaceEditComponent } from './manager/places/edit/place-edit.component';
import { PlaceDetailsComponent } from './manager/places/details/place-details.component';
import { PlaceResolver } from './manager/places/place.resolver';

import { InstructorListComponent } from './manager/instructors/instructor-list.component';
import { InstructorDetailComponent } from './manager/instructors/details/instructor-detail.component';
import { InstructorResolver } from './manager/instructors/instructor.resolver';
import { InstructorDetailInfoComponent } from './manager/instructors/details/info/instructor-detail-info.component';
import { InstructorDetailInfoEditComponent } from './manager/instructors/details/info/instructor-detail-info-edit.component';
import { InstructorDetailTrainingsComponent } from './manager/instructors/details/trainings/instructor-detail-trainings.component';
import { InstructorDetailRatesComponent } from './manager/instructors/details/rates/instructor-detail-rates.component';
import { InstructorDetailBillsComponent } from './manager/instructors/details/accounting/instructor-detail-accounting.component';
import { InstructorDetailBillsEditComponent } from './manager/instructors/details/accounting/instructor-detail-bills-edit.component';
import { InstructorBillResolver } from './manager/instructors/details/accounting/instructor-bill.resolver';
import { InstructorDetailPaymentEditComponent } from './manager/instructors/details/accounting/instructor-detail-payment-edit.component';
import { PaymentResolver } from './manager/instructors/details/accounting/payment.resolver';

import { ClientListComponent } from './manager/clients/client-list.component';
import { ClientGroupDetailComponent } from './manager/clients/details/client-group-detail.component';
import { ClientGroupDetailInfoComponent } from './manager/clients/details/info/client-group-detail-info.component';
import { ClientGroupDetailInfoEditComponent } from './manager/clients/details/info/client-group-detail-info-edit.component';
import { ClientGroupResolver } from './manager/clients/client-group.resolver';
import { ClientGroupDetailClientsComponent } from './manager/clients/details/persons/client-group-detail-persons.component';
import { ClientGroupDetailClientEditComponent } from './manager/clients/details/persons/client-group-detail-persons-edit.component';
import { ClientResolver } from './manager/clients/client.resolver';
import { ClientGroupTrainingsComponent } from './manager/clients/details/trainings/client-group-trainings.component';
import { ClientGroupDetailSubsComponent } from './manager/clients/details/subs/client-group-detail-subs.component';
import { ClientSubEditComponent } from './manager/clients/details/subs/client-subs-edit.component';
import { SubResolver } from './manager/clients/sub.resolver';
import { ClientGroupDetailRecommendationsComponent } from './manager/clients/details/recommendations/client-group-detail-recommendations.component';
import { ClientGroupDetailRecommendationsEditComponent } from './manager/clients/details/recommendations/client-group-detail-recommendations-edit.component';
import { ClientGroupDetailBillsComponent } from './manager/clients/details/accounting/client-group-detail-accounting.component';
import { ClientGroupDetailBillsEditComponent } from './manager/clients/details/accounting/client-group-detail-bills-edit.component';
import { ClientGroupBillResolver } from './manager/clients/details/accounting/client-group-bill.resolver';
import { ClientGroupDetailPaymentEditComponent } from './manager/clients/details/accounting/client-group-detail-payment-edit.component';
import { ClientGroupPaymentResolver } from './manager/clients/details/accounting/payment.resolver';

import { TrainingsListComponent } from './manager/trainings/trainings-list.component';
import { TrainingEditComponent } from './manager/trainings/edit/training-edit.component';
import { TrainingResolver } from './manager/trainings/training.resolver';

import { AccountingComponent } from './manager/accounting/accounting.component';
import { AccountingOutComponent } from './manager/accounting/out/accounting-out.component';
import { AccountingPaymentEditComponent } from './manager/accounting/out/accounting-payment-edit.component';
import { AccountingPaymentResolver } from './manager/accounting/out/outcomePayment.resolver';

import { AccountingInComponent } from './manager/accounting/in/accounting-in.component';
import { AccountingIncomeEditComponent } from './manager/accounting/in/accounting-in-edit.component';
import { AccountingIncomeResolver } from './manager/accounting/in/incomePayment.resolver';

import { SummaryComponent } from './manager/accounting/summary/summary.component';
import { SeasonSummaryComponent } from './manager/accounting/season-summary/season-summary.component';

// import { MatNativeDateModule, MatIconModule, MatSidenavModule, MatListModule, MatToolbarModule } from '@angular/material';

// import { YesNoPipe } from './manager/clients/details/accounting/yes-no.pipe';


const baseTitle: string = " - Jump Camp"

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '/manager',
  //   pathMatch: 'full'
  // },
  {
    path: 'home',
    component: HomeComponent,
    title: 'Cześć' + baseTitle
  },
  {
    path: 'about',
    component: AboutComponent,
    title: 'O nas' + baseTitle
  },
  {
    path: 'contact',
    component: ContactComponent,
    title: 'Kontakt' + baseTitle
  },
  {
    path: 'gallery',
    component: GalleryPage,
    title: 'Galeria' + baseTitle
  },
  {
    path: 'news',
    component: NewsPage,
    title: 'Aktualności' + baseTitle
  },
  {
    path: 'gallery/album/:id',
    component: AlbumComponent,
    title: 'Galeria' + baseTitle
  },
  {
    path: 'login',
    component: LoginComponent,
    title: 'Login' + baseTitle
  },
  {
    path: 'offer',
    component: OfferComponent,
    title: 'Oferta' + baseTitle
  },
  // {
  //   path: "travel",
  //   component: Kreischberg012023,
  //   title: 'Kreischberg 01.2023' + baseTitle
  // },
  {
    path: "travel/kreischberg-01-2023",
    component: Kreischberg012023,
    title: 'Kreischberg 01.2023' + baseTitle
  },
  {
    path: "travel/soelden-11-2022",
    component: Soelden112022,
    title: 'Soelden 11.2022' + baseTitle
  },
  {
    path: "travel/zwardon-03-2023",
    component: Zwardon032023,
    title: 'Zwardoń 03.2023' + baseTitle
  },
  {
    path: "travel/lyngen-03-2023",
    component: Lyngen032023,
    title: 'Lyngen 03.2023' + baseTitle
  },
  {
    path: "travel/kaprun-04-2023",
    component: Kaprun042023,
    title: 'Kaprun 04.2023' + baseTitle
  },
  {
    path: "", component: ManagerComponent,
    children: [
      { path: '', redirectTo: 'trainings', pathMatch: 'full' },
      {
        path: 'trainings', component: TrainingsListComponent,
        canActivate: [AccessGuard],
        canLoad: [AccessGuard]
      },
      {
        path: 'trainings/:trainingId/edit',
        component: TrainingEditComponent,
        resolve: {
          season: SeasonResolver,
          training: TrainingResolver
        }
      },
      {
        path: 'trainings/add',
        component: TrainingEditComponent,
        resolve: {
          season: SeasonResolver,
        }
      },
      { path: 'debts', component: DebtsListComponent },
      { path: 'instructors', component: InstructorListComponent },
      // { path: 'instructors/search', component: InstructorSearchComponent},
      {
        path: 'instructors/:id/details',
        component: InstructorDetailComponent,
        resolve: { instructor: InstructorResolver },
        children: [
          { path: '', redirectTo: 'info', pathMatch: 'full' },
          { path: 'info', component: InstructorDetailInfoComponent },
          { path: 'trainings', component: InstructorDetailTrainingsComponent },
          { path: 'rates', component: InstructorDetailRatesComponent },
          {
            path: 'accounting',
            component: InstructorDetailBillsComponent,
            canActivate: [AccessGuard],
            canLoad: [AccessGuard]
            // children:[
            //   { path: 'bills/:billId/edit', component: InstructorDetailBillsEditComponent }
            // ]
          },
          {
            path: 'accounting/bills/:billId/edit',
            component: InstructorDetailBillsEditComponent,
            resolve: {
              bill: InstructorBillResolver
            }
          },
          {
            path: 'accounting/payments/add',
            component: InstructorDetailPaymentEditComponent,
          },
          {
            path: 'accounting/payments/:paymentId/edit',
            component: InstructorDetailPaymentEditComponent,
            resolve: {
              payment: PaymentResolver
            }
          },
          {
            path: 'accounting/bills/add',
            component: InstructorDetailBillsEditComponent,
          }
        ]
      },
      {
        path: 'instructors/:id/edit',
        component: InstructorDetailInfoEditComponent,
        resolve: { instructor: InstructorResolver }
      },
      {
        path: 'instructors/add',
        component: InstructorDetailInfoEditComponent
      },
      {
        path: 'families',
        component: ClientListComponent
      },
      {
        path: 'families/:id/details',
        component: ClientGroupDetailComponent,
        resolve: { clientGroup: ClientGroupResolver },
        children: [
          { path: '', redirectTo: 'info', pathMatch: 'full' },
          { path: 'info', component: ClientGroupDetailInfoComponent },
          { path: 'trainings', component: ClientGroupTrainingsComponent },
          { path: 'subs', component: ClientGroupDetailSubsComponent },
          { path: 'persons', component: ClientGroupDetailClientsComponent },
          {
            path: 'persons/:clientId/edit',
            component: ClientGroupDetailClientEditComponent,
            resolve: {
              client: ClientResolver
            }
          },
          {
            path: 'persons/add',
            component: ClientGroupDetailClientEditComponent,
          },
          {
            path: 'persons/:clientId/subs/add',
            component: ClientSubEditComponent
          },
          {
            path: 'persons/:clientId/subs/:subId/edit',
            component: ClientSubEditComponent,
            resolve: {
              sub: SubResolver
            }
          },
          {
            path: 'recommendations',
            component: ClientGroupDetailRecommendationsComponent,
          },
          {
            path: 'recommendations/add',
            component: ClientGroupDetailRecommendationsEditComponent,
          },
          {
            path: 'accounting',
            component: ClientGroupDetailBillsComponent
          },
          {
            path: 'accounting/bills/:billId/edit',
            component: ClientGroupDetailBillsEditComponent,
            resolve: {
              bill: ClientGroupBillResolver
            }
          },
          {
            path: 'accounting/payments/add',
            component: ClientGroupDetailPaymentEditComponent,
          },
          {
            path: 'accounting/payments/:paymentId/edit',
            component: ClientGroupDetailPaymentEditComponent,
            resolve: {
              payment: ClientGroupPaymentResolver
            }
          },
          {
            path: 'accounting/bills/add',
            component: ClientGroupDetailBillsEditComponent,
          }
        ]
      },
      {
        path: 'families/:id/edit',
        component: ClientGroupDetailInfoEditComponent,
        resolve: { clientGroup: ClientGroupResolver }
      },
      {
        path: 'families/add',
        component: ClientGroupDetailInfoEditComponent
      },
      {
        path: 'places',
        component: PlaceListComponent
      },
      {
        path: 'places/:placeId/edit',
        component: PlaceEditComponent,
        resolve: { place: PlaceResolver }
      },
      {
        path: 'places/:placeId/details',
        component: PlaceDetailsComponent,
        resolve: { place: PlaceResolver }
      },
      {
        path: 'places/add',
        component: PlaceEditComponent
      },
      {
        path: 'seasons',
        component: SeasonListComponent
      },
      {
        path: 'seasons/:seasonId/edit',
        component: SeasonEditComponent,
        resolve: { season: SeasonResolver }
      },
      {
        path: 'seasons/:seasonId/details',
        component: SeasonDetailsComponent,
        resolve: { season: SeasonResolver },
        children: [
          { path: '', redirectTo: 'info', pathMatch: 'full' },
          { path: 'info', component: SeasonDetailsInfoComponent },
          { path: 'bundles', component: SeasonBundlesComponent },
          {
            path: 'bundles/:bundleId/edit',
            component: SeasonBundleEditComponent,
            resolve: {
              bundle: BundleResolver
            }
          },
          {
            path: 'bundles/add',
            component: SeasonBundleEditComponent,
          }
        ]
      },
      {
        path: 'seasons/add',
        component: SeasonEditComponent
      },
      {
        path: 'accounting',
        component: AccountingComponent,
        children: [
          { path: '', redirectTo: 'out', pathMatch: 'full' },
          { path: 'out', component: AccountingOutComponent },
          {
            path: 'out/add',
            component: AccountingPaymentEditComponent,
          },
          {
            path: 'out/:paymentId/edit',
            component: AccountingPaymentEditComponent,
            resolve: {
              payment: AccountingPaymentResolver
            }
          },
          { path: 'in', component: AccountingInComponent },
          {
            path: 'in/add',
            component: AccountingIncomeEditComponent,
          },
          {
            path: 'in/:paymentId/edit',
            component: AccountingIncomeEditComponent,
            resolve: {
              payment: AccountingIncomeResolver
            }
          },
          { path: 'summary', component: SummaryComponent },
          { path: 'categories', component: SeasonSummaryComponent }
        ]
      },
    ],
    title: 'Manager' + baseTitle,
    canActivate: [AccessGuard],
    canActivateChild: [AuthGuardService],
    canLoad: [AccessGuard]
    // data: { requiresLogin: true },
    // canActivate: [AccessGuard]
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
