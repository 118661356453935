import { Client } from '../clients/client';
import { ClientTrainingState } from './client-training-status';

export class ClientTraining extends ClientTrainingState {
  id: string;
  firstName: string;
  lastName: string;
  level: number;
}

export function ToClientTraining(client: Client): ClientTraining {
  return {
    id: client.id,
    firstName: client.firstName,
    lastName: client.lastName,
    level: client.level
  } as ClientTraining;
}