<div class="card-body">

  <div class="wizard">
    <a [routerLink]="['out']" routerLinkActive="is-active">
      Wydatki
    </a>
    |
    <a [routerLink]="['in']" [routerLinkActive]="['is-active']">
      Wpływy
    </a>
    |
    <a [routerLink]="['transfers']" [routerLinkActive]="['is-active']">
      Transfery
    </a>
    |
    <a [routerLink]="['summary']" routerLinkActive="is-active">
      Podsumowanie
    </a>
    |
    <a [routerLink]="['categories']" routerLinkActive="is-active">
      Kategorie
    </a>
    |
    <a [routerLink]="['wallets']" routerLinkActive="is-active">
      Sakiewki
    </a>
    <!-- |
    <a [routerLink]="['entities']" routerLinkActive="is-active">
      Podmioty
    </a> -->
  </div>
  <br>
  <router-outlet></router-outlet>