import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Season, Rate } from '../season';
import { TrainingType } from '../../trainings/trainingType';
import { SeasonService } from '../season.service';
import { Currency } from '../../models/paymentInfo'
import { parseDate } from '../../utils';

@Component({
  templateUrl: './season-edit.component.html'
})
export class SeasonEditComponent implements OnInit {
  pageTitle = 'Season Edit';
  errorMessage = '';

  private currentSeason: Season;
  private originalSeason: Season;
  private dataIsValid: boolean = false;

  parseDate(event: Event): Date | null {
    let value = (event.target as HTMLInputElement).value;
    return parseDate(value)
  }

  get isDirty(): boolean {
    return JSON.stringify(this.originalSeason) !== JSON.stringify(this.currentSeason);
  }

  get season(): Season {
    return this.currentSeason;
  }

  set season(value: Season) {
    this.currentSeason = value;
    // Clone the object to retain a copy
    this.originalSeason = Object.assign({}, value);
  }

  public trainingTypes = Object.values(TrainingType);
  public currencies = Object.values(Currency);

  newRate: Rate = new Rate();
  newTrainingPrice: Rate = new Rate();

  constructor(public route: ActivatedRoute,
    private router: Router,
    private seasonService: SeasonService) { }

  removeRate(rate: Rate): void {
    const index = this.season.instructorRates.indexOf(rate, 0);
    if (index > -1) {
      this.season.instructorRates.splice(index, 1);
    }
  }

  removeTrainingPrice(rate: Rate): void {
    const index = this.season.trainingPrices.indexOf(rate, 0);
    if (index > -1) {
      this.season.trainingPrices.splice(index, 1);
    }
  }

  addRate(): void {
    if (!this.newRate.trainingType || !this.newRate.amount || !this.newRate.amount.currency) {
      this.errorMessage = "Did not provide all rate information.";
      return;
    }
    if (this.newRate.amount.amount < 0) {
      this.errorMessage = "Amount needs to be greater than 0.";
      return;
    }
    if (this.season.instructorRates.find(r => r.trainingType === this.newRate.trainingType)) {
      this.errorMessage = "Cannot add new rate for the same training type";
      return;
    }
    this.season.instructorRates.push(this.newRate);
    this.newRate = new Rate();
  }

  addTrainingPrice(): void {
    if (!this.newTrainingPrice.trainingType || !this.newTrainingPrice.amount || !this.newTrainingPrice.amount.currency) {
      this.errorMessage = "Did not provide all price information.";
      return;
    }
    if (this.newTrainingPrice.amount.amount < 0) {
      this.errorMessage = "Amount needs to be greater than 0.";
      return;
    }
    if (this.season.trainingPrices.find(r => r.trainingType === this.newTrainingPrice.trainingType)) {
      this.errorMessage = "Cannot add new price for the same training type";
      return;
    }
    this.season.trainingPrices.push(this.newTrainingPrice);
    this.newTrainingPrice = new Rate();
  }

  ngOnInit(): void {
    // Watch for changes to the resolve data
    this.route.data.subscribe(data => {
      const dataName = 'season';
      this.onSeasonRetrieved(data[dataName]);
    });
  }

  onSeasonRetrieved(season: Season): void {
    if (season === undefined) {
      this.season = {
        name: "",
        id: ""
      } as Season;
      this.pageTitle = 'Dodaj sezon';
    }
    else {
      this.season = season;
      this.pageTitle = `Edytuj sezon: ${this.season.name}`;
    }
  }

  isAddPage(): boolean {
    return !this.season.id || this.season.id === "";
  }

  getReturnRoute(): string {
    let navigateTo = '../..';
    if (this.isAddPage()) {
      navigateTo = '..'
    }
    return navigateTo;
  }

  deleteSeason(): void {
    if (!this.season.id) {
      // Don't delete, it was never saved.
      this.onSaveComplete(`${this.season.name} was deleted`);
    } else {
      if (confirm(`Czy na pewno usunąć sezon '${this.season.name}'?`)) {
        this.seasonService.deleteSeason(this.season.id).subscribe(
          () => this.onSaveComplete(`${this.season.name} was deleted`),
          (error) => this.errorMessage = error
        );
      }
    }
  }

  isValid(): boolean {
    this.validate();
    return this.dataIsValid;
  }

  saveSeason(): void {
    if (this.isValid()) {
      this.seasonService.saveSeason(this.season).subscribe(
        () => this.onSaveComplete(`${this.season.name} was saved`),
        (error) => this.errorMessage = error
      );
    } else {
      this.errorMessage = 'Please correct the validation errors.';
    }
  }

  onSaveComplete(message?: string): void {
    console.log(message);
    var goBackTo = this.getReturnRoute();
    this.reset();
    // Navigate back to the season list
    this.router.navigate([goBackTo], { relativeTo: this.route });
  }

  // Reset the data
  // Required after a save so the data is no longer seen as dirty.
  reset(): void {
    this.dataIsValid = null;
    this.currentSeason = null;
    this.originalSeason = null;
  }

  validate(): void {
    // Clear the validation object
    this.dataIsValid = false;
    if (this.season.name &&
      this.season.name.length >= 3 &&
      this.season.name.length <= 50
    ) {
      this.dataIsValid = true;
    } else {
      this.dataIsValid = false;
    }
  }
}
