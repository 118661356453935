<div class="card" *ngIf="clientGroup">
  <div class="card-header">
    {{ clientGroup.name }}
  </div>
  <div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
  </div>

  <div class="table-responsive">
    <table class="jumpcamp-table">
      <caption>
        <div class="col-md-4">
          <button class="btn btn-primary" [routerLink]="['add']" [relativeTo]="route">
            Dodaj osobę
          </button>
        </div>
      </caption>
      <ng-container *ngIf="clientGroup.clients && clientGroup.clients.length">

        <thead>
          <tr>
            <th>Imię</th>
            <th>Nazwisko</th>
            <th>Wiek</th>
            <th>Level</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor='let client of clientGroup.clients'>
            <td>{{ client.firstName }}</td>
            <td>{{ client.lastName }}</td>
            <td>{{ calculateAge(client.dateOfBirth) }}</td>
            <td>{{ client.level }}</td>
            <td>
              <button class="btn btn-primary" [routerLink]="[client.id, 'edit']" [relativeTo]="route">
                Edytuj
              </button>
            </td>
            <td>
              <button class="btn btn-danger" (click)="onClientDelete(client.id)">
                Usuń
              </button>
            </td>
          </tr>
        </tbody>
      </ng-container>
    </table>
  </div>
</div>