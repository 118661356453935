import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { Training } from './training';
import { TrainingService } from './training.service';

@Injectable({
  providedIn: 'root'
})
export class TrainingResolver implements Resolve<Training> {

  constructor(private trainingService: TrainingService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<Training> {
    const trainingId = route.paramMap.get('trainingId');
    console.log(route)
    return this.trainingService.getTraining(trainingId);
  }
}
