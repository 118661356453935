import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Instructor } from './instructor';
import { InstructorService } from './instructor.service';
import { InstructorParameterService } from './instructor-parameter.service';

@Component({
  templateUrl: './instructor-list.component.html',
  styleUrls: ['./instructor-list.component.css']
})
export class InstructorListComponent implements OnInit {
  pageTitle = 'Instructor List';
  filteredInstructors: Instructor[];
  instructors: Instructor[];
  errorMessage: string;

  get listFilter(): string {
    return this.instructorParameterService.filterBy;
  }
  set listFilter(value: string) {
    this.instructorParameterService.filterBy = value;
    this.filteredInstructors = this.performFilter(this.listFilter);
  }

  // get showImage(): boolean {
  //   return this.instructorParameterService.displayPosters;
  // }
  // set showImage(value: boolean) {
  //   this.instructorParameterService.displayPosters = value;
  // }

  constructor(private instructorService: InstructorService,
    private instructorParameterService: InstructorParameterService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.pageTitle = 'Instructor List';
      // If parameters are passed in,
      // clear any existing filter
      if (Object.keys(params).length) {
        this.listFilter = null;
      }
      this.getInstructors();
    });
  }

  getInstructors(): void {
    this.instructorService.getInstructors()
      .subscribe(
        (instructors: Instructor[]) => {
          // this.instructors = this.performSearch(instructors);
          this.instructors = instructors;
          this.filteredInstructors = this.performFilter(this.listFilter);
        },
        (error: any) => this.errorMessage = error
      );
  }

  // Local filter
  performFilter(filterBy: string): Instructor[] {
    if (filterBy) {
      filterBy = filterBy.toLocaleLowerCase();
      return this.instructors.filter((instructor: Instructor) =>
        instructor.firstName.toLocaleLowerCase().indexOf(filterBy) !== -1 ||
        instructor.lastName.toLocaleLowerCase().indexOf(filterBy) !== -1)
    } else {
      return this.instructors;
    }
  }

  // // Advanced search
  // performSearch(instructors: Instructor[]): Instructor[] {
  //   const params = this.route.snapshot.queryParamMap;
  //   if (params.keys.length) {
  //     this.pageTitle = 'Instructor List From Advanced Search';
  //     return instructors.filter((instructor: Instructor) =>
  //       (params.get('firstName') ?
  //         instructor.firstName.toLocaleLowerCase().indexOf(params.get('title').toLocaleLowerCase()) !== -1 : true) &&
  //       (params.get('lastName') ?
  //         instructor.lastName.toLocaleLowerCase().indexOf(params.get('director').toLocaleLowerCase()) !== -1 : true)// &&
  //       // (params.get('description') ?
  //       //   instructor.description.toLocaleLowerCase().indexOf(params.get('description').toLocaleLowerCase()) !== -1 : true) &&
  //       // (params.get('minStarRating') ? instructor.starRating >= +params.get('minStarRating') : true) &&
  //       // (params.get('maxStarRating') ? instructor.starRating <= +params.get('maxStarRating') : true)
  //     );
  //   }
  //   return instructors;
  // }

  // toggleImage(): void {
  //   this.showImage = !this.showImage;
  // }
}
