import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { InstructorService } from '../../instructor.service';

import { Instructor } from '../../instructor';
import { Training } from '../../training';

@Component({
  templateUrl: './instructor-detail-trainings.component.html'
})
export class InstructorDetailTrainingsComponent implements OnInit {
  pageTitle = 'Instructor Trainings';
  instructor: Instructor;
  errorMessage: string;
  selectedSeasonId: string;
  trainings: Training[];
  public filteredTrainings: Training[];
  selectedMonth: Date = new Date();

  salary: { [id: string]: number; };

  sum(): { [id: string]: number; } {
    let result: { [id: string]: number; } = {}
    this.filteredTrainings.forEach(training => {
      if (!training.cancelled) {
        if (training.salary) {
          if (!result[training.salary.currency]) {
            result[training.salary.currency] = 0;
          }
          result[training.salary.currency] += training.salary.amount;
        }
        if (training.paymentCorrection) {
          if (!result[training.paymentCorrection.currency]) {
            result[training.paymentCorrection.currency] = 0;
          }
          result[training.paymentCorrection.currency] += training.paymentCorrection.amount;
        }
      }
    })
    return result;
  }

  get currencies() {
    return Object.keys(this.salary);
  }

  constructor(private route: ActivatedRoute,
    private router: Router,
    private instructorService: InstructorService
  ) {
  }

  ngOnInit(): void {
    this.route.parent.data.subscribe(data => {
      const dataName = 'instructor';
      this.instructor = data[dataName];
    });
  }

  getTrainings(): void {
    this.instructorService.getTrainings(this.selectedSeasonId, this.instructor.id)
      .subscribe(
        (trainings: Training[]) => {
          // this.instructors = this.performSearch(instructors);
          this.trainings = trainings;
          this.filteredTrainings = this.filterTrainings();
          this.salary = this.sum();
        },
        (error: any) => this.errorMessage = error
      );
  }

  filterTrainings(): Training[] {
    return this.trainings.filter((training: Training) =>
      (new Date(training.from)).getMonth() == (new Date(this.selectedMonth)).getMonth() &&
      (new Date(training.from)).getFullYear() == (new Date(this.selectedMonth)).getFullYear())
  }

  onMonthSelected(event, month: Date) {
    this.selectedMonth = event;
    this.filteredTrainings = this.filterTrainings();
    this.salary = this.sum();
  }

  onSeasonChange(seasonId: string): void {
    this.selectedSeasonId = seasonId;
    this.getTrainings();
  }

  generateBill(): void {
    this.instructorService.generateBill(this.instructor.id, this.selectedSeasonId, new Date(this.selectedMonth)).subscribe(
      () => this.onSaveComplete(`$Bill for month '${this.selectedMonth}' was saved`),
      (error) => this.errorMessage = error
    );
  }

  onSaveComplete(message?: string): void {
    console.log(message);
    // Navigate to the bills list
    this.router.navigate(['/manager/instructors', this.instructor.id, 'details', 'accounting']);
  }
}
