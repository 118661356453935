import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SeasonService } from '../../season.service';

import { Bundle } from '../../bundle';
import { Season } from '../../season';

@Component({
  templateUrl: './season-bundles.component.html'
})

export class SeasonBundlesComponent implements OnInit {
  pageTitle = 'Season Bundles';
  season: Season;
  errorMessage: string;
  bundles: Bundle[];

  // public get sortedBills(): Bill[] {
  //   return this.bills.sort((b, a) => {
  //     return <any>new Date(b.creationDate) - <any>new Date(a.creationDate);
  //   });
  // }

  constructor(private route: ActivatedRoute,
    private seasonService: SeasonService
  ) {
  }

  ngOnInit(): void {
    this.route.parent.data.subscribe(data => {
      const dataName = 'season';
      this.season = data[dataName];
      this.getBundles();
    });
  }

  getBundles(): void {
    this.seasonService.getBundles(this.season.id)
      .subscribe(
        (bundles: Bundle[]) => {
          this.bundles = bundles;
        },
        (error: any) => this.errorMessage = error
      );
  }

  // onBillDelete(bundleId) {
  //   if (confirm("Are you sure to delete permanently?")) {
  //     this.seasonService.deleteBundle(this.season.id, bundleId).subscribe(value => {
  //       if (value) {
  //         this.bundles = this.bundles.filter(function (obj) {
  //           return obj.id !== bundleId;
  //         });
  //       }
  //       else {
  //         this.errorMessage = "Something went wrong while deleting bundle :(";
  //       }
  //     });
  //   }
  // }
}
