<div class="col-xs-12" style="margin-top: 1vh">
    <h1 class="text-color-turquoise main-text">... bo <span class="text-color-blue main-text">dzieciaki</span> są
        najważniejsze</h1>
</div>
<div class="col-xs-12 no-padding" style="margin-top: 5vh">
    <div class="col-xs-4 col-sm-2">
        <button id="offer-button" class="button-round color-turquoise">nasza oferta</button>
    </div>
    <div class="col-xs-4 col-sm-2">
        <button id="join-us-button" class="button-round color-blue">dołącz<br /> do nas</button>
    </div>
    <div class="col-xs-4 col-sm-2">
        <button id="near-trening-button" class="button-round color-pink">najbliższy trening</button>
    </div>
</div>
<div class="col-xs-12">
    <img class="main-image" src="assets/images/children/main.png" alt="children">
</div>
<div class="col-xs-12">
    <div id="main-message" class="main-message" style="display: none">
        <p id="main-message-content" style="display: none">
        </p>
    </div>
</div>