import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Currency, PaymentInfo } from '../../../models/paymentInfo'
import { Bill } from '../../../seasons/season';
import { InstructorService } from '../../instructor.service';

@Component({
  templateUrl: './instructor-detail-bills-edit.component.html'
})
export class InstructorDetailBillsEditComponent implements OnInit {
  pageTitle = 'Bill Edit';
  errorMessage = '';

  private currentBill: Bill;
  private originalBill: Bill;
  private dataIsValid: boolean = false;

  public instructorId: string;
  private seasonId: string;

  get isDirty(): boolean {
    return JSON.stringify(this.originalBill) !== JSON.stringify(this.currentBill);
  }

  get bill(): Bill {
    return this.currentBill;
  }

  set bill(value: Bill) {
    this.currentBill = value;
    // Clone the object to retain a copy
    this.originalBill = Object.assign({}, value);
  }

  constructor(public route: ActivatedRoute,
    private router: Router,
    private instructorService: InstructorService) { }

  public currencies = Object.values(Currency);

  ngOnInit(): void {
    // Watch for changes to the resolve data
    this.route.parent.data.subscribe(data => {
      this.instructorId = data['instructor'].id;
    })
    this.route.data.subscribe(data => {
      const dataName = 'bill';
      this.onBillRetrieved(data[dataName]);
      if (this.isAddPage()) {
        this.route.queryParams.subscribe(params => {
          this.seasonId = params['seasonId'];
        });
      }
    });
  }

  onBillRetrieved(bill: Bill): void {
    if (bill === undefined) {
      this.bill = {
        id: "",
        creationDate: new Date(),
        name: "",
        amount: new PaymentInfo()
      } as Bill;
      this.pageTitle = 'Nowy rachunek';
    }
    else {
      this.bill = bill;
      this.pageTitle = `Edytuj rachunek: ${this.bill.name}`;
    }
  }

  isAddPage(): boolean {
    return this.bill.id === null || this.bill.id === "";
  }

  getReturnRoute(): string {
    let navigateTo = '../../..';
    if (this.isAddPage()) {
      navigateTo = '../..'
    }
    return navigateTo;
  }

  deleteBill(): void {
    if (!this.bill.id) {
      // Don't delete, it was never saved.
      this.onSaveComplete(`${this.bill.name} was deleted`);
    } else {
      if (confirm(`Czy na pewno usunąć rachunek '${this.bill.name}'?`)) {
        this.instructorService.deleteBill(this.instructorId, this.bill.id).subscribe(
          () => this.onSaveComplete(`${this.bill.name} was deleted`),
          (error) => this.errorMessage = error
        );
      }
    }
  }

  isValid(): boolean {
    this.validate();
    return this.dataIsValid;
  }

  saveBill(): void {
    if (this.isValid()) {
      this.instructorService.saveBill(this.instructorId, this.seasonId, this.bill).subscribe(
        () => this.onSaveComplete(`${this.bill.name} was saved`),
        (error) => this.errorMessage = error
      );
    } else {
      this.errorMessage = 'Please correct the validation errors.';
    }
  }

  onSaveComplete(message?: string): void {
    console.log(message);
    var goBackTo = this.getReturnRoute();
    this.reset();
    // Navigate back to the bills list
    this.router.navigate([goBackTo], { relativeTo: this.route });
  }

  // Reset the data
  // Required after a save so the data is no longer seen as dirty.
  reset(): void {
    this.dataIsValid = null;
    this.currentBill = null;
    this.originalBill = null;
  }

  validate(): void {
    // Clear the validation object
    this.dataIsValid = false;
    if (this.bill.name &&
      this.bill.name.length >= 3 &&
      this.bill.name.length <= 50
      //TODO: Add validation
    ) {
      this.dataIsValid = true;
    } else {
      this.dataIsValid = false;
    }
  }
}
