import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Place } from '../place';
import { PlaceService } from '../place.service';

@Component({
  templateUrl: './place-details.component.html'
})
export class PlaceDetailsComponent implements OnInit {
  pageTitle = 'Place details';
  errorMessage = '';

  private currentPlace: Place;

  get place(): Place {
    return this.currentPlace;
  }

  set place(value: Place) {
    this.currentPlace = value;
  }

  constructor(private route: ActivatedRoute,
    private router: Router,
    private placeService: PlaceService) { }

  ngOnInit(): void {
    // Watch for changes to the resolve data
    this.route.data.subscribe(data => {
      const dataName = 'place';
      this.onPlaceRetrieved(data[dataName]);
    });
  }

  onPlaceRetrieved(place: Place): void {
    this.place = place;
    this.pageTitle = `Miejsce: ${this.place.name}`;
  }
}
