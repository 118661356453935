import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientService } from '../../client.service';

import { ClientGroup } from '../../client';
import { Bill, Payment } from '../../../seasons/season';

@Component({
  templateUrl: './client-group-detail-accounting.component.html'
})

export class ClientGroupDetailBillsComponent implements OnInit {
  pageTitle = 'Client Group Bills';
  clientGroup: ClientGroup;
  errorMessage: string;
  selectedSeasonId: string;
  bills: Bill[];
  payments: Payment[];

  balance: { [id: string]: number; };

  get currencies() {
    return Object.keys(this.balance);
  }

  getBalanceColor(balance: number) {
    if (balance === 0) {
      return "green";
    }
    if (balance < 0) {
      return "red";
    }
    if (balance > 0) {
      return "#CC6600"; //orange
    }
    return "black";
  }

  getBalance(): { [id: string]: number; } {
    let result: { [id: string]: number; } = {}
    if (this.bills) {
      this.bills.forEach(bill => {
        if (bill.amount) {
          if (!result[bill.amount.currency]) {
            result[bill.amount.currency] = 0;
          }
          result[bill.amount.currency] -= bill.amount.amount;
        }
      })
    }
    if (this.payments) {
      this.payments.forEach(payment => {
        if (payment.amount) {
          if (!result[payment.amount.currency]) {
            result[payment.amount.currency] = 0;
          }
          result[payment.amount.currency] += payment.amount.amount;
        }
      })
    }
    return result;
  }

  public get sortedBills(): Bill[] {
    return this.bills.sort((b, a) => {
      return <any>new Date(b.creationDate) - <any>new Date(a.creationDate);
    });
  }

  public get sortedPayments(): Payment[] {
    return this.payments.sort((b, a) => {
      return <any>new Date(b.date) - <any>new Date(a.date);
    });
  }

  constructor(private route: ActivatedRoute,
    private clientService: ClientService
  ) {
  }

  ngOnInit(): void {
    this.route.parent.data.subscribe(data => {
      const dataName = 'clientGroup';
      this.clientGroup = data[dataName];
    });
  }

  getBills(): void {
    this.clientService.getBills(this.selectedSeasonId, this.clientGroup.id)
      .subscribe(
        (bills: Bill[]) => {
          this.bills = bills;
          this.balance = this.getBalance();
        },
        (error: any) => this.errorMessage = error
      );
  }

  getPayments(): void {
    this.clientService.getPayments(this.selectedSeasonId, this.clientGroup.id)
      .subscribe(
        (payments: Payment[]) => {
          this.payments = payments;
          this.balance = this.getBalance();
        },
        (error: any) => this.errorMessage = error
      );
  }

  onBillDelete(billId) {
    if (confirm("Are you sure to delete permanently?")) {
      this.clientService.deleteBill(this.clientGroup.id, billId).subscribe(value => {
        if (value) {
          this.bills = this.bills.filter(function (obj) {
            return obj.id !== billId;
          });
          this.balance = this.getBalance();
        }
        else {
          this.errorMessage = "Something went wrong while deleting bill :(";
        }
      });
    }
  }

  onPaymentDelete(paymentId) {
    if (confirm("Are you sure to delete permanently?")) {
      this.clientService.deletePayment(this.clientGroup.id, paymentId).subscribe(value => {
        if (value) {
          this.payments = this.payments.filter(function (obj) {
            return obj.id !== paymentId;
          });
          this.balance = this.getBalance();
        }
        else {
          this.errorMessage = "Something went wrong while deleting payment :(";
        }
      });
    }
  }

  onSeasonChange(seasonId: string): void {
    this.selectedSeasonId = seasonId;
    this.getBills();
    this.getPayments();
  }
}
