<div class="col-xs-12 no-padding">
    <div class="col-xs-12 col-sm-6 col-md-6 offer-content holiday-content" style="height: 80vh; padding-top: 0px;">
        <div class="row style-2" style="overflow: auto; height: 100%; direction: rtl; padding-bottom: 20px;">
            <div style="direction: ltr; padding-left: 10px;">
                <br />
                <h1 class="holiday-title" style="color: #0071b9;">03-05.03.2023</h1>
                <h2 class="holiday-title" style="color: #0071b9">porządny trening</h2>
                <br />
                <p>
                    <b>zakwaterowanie z wyżywieniem, karnet:</b>
                </p>
                <p>
                    osoba dorosła:&ensp;&ensp; &ensp; &emsp;<span class="hidden-xs">&emsp;&emsp;&emsp;&emsp;</span> 500
                    pln<br />
                    junior (do 16 lat):&emsp;&emsp;<span class="hidden-xs">&emsp;&emsp;&emsp;&emsp;</span> 470 pln<br />
                    dziecko (do 120cm):&emsp;<span class="hidden-xs">&emsp;&emsp;&emsp;&emsp;</span>231 pln<br />
                </p>
                <br>
                <p>
                    <b>szkolenie narciarskie: &emsp;500 pln</b><br />
                    małe grupy (max 6 osób)<br />
                    z treningiem na tyczkach<br />
                </p>
                <br>
                <p>
                    <b>Plan wyjazdu:</b><br />
                    piątek (03.03):<br />
                    18:00-20:00 trening, tyczki, GS<br />
                    20:30 kolacja<br />
                    <br />

                    sobota (04.03):<br />
                    06:30 śniadanie<br />
                    07:30-09:00 trening, tyczki, GS<br />
                    09:00-11:00 zadaniówka<br />
                    11:00-12:00 przerwa<br />
                    12:00-14:00 zadaniówka<br />
                    15:00 obiad<br />
                    16:00 wideo analiza<br />
                    18:00 - 20:00 trening, tyczki, GS<br />
                    20:30 kolacja<br />
                    <br />

                    niedziela (05.03)<br />
                    06:30 śniadanie<br />
                    07:30-09:00 trening, tyczki, GS<br />
                    09:00-11:00 zadaniówka<br />
                    11:00-12:00 przerwa<br />
                    12:00-14:00 zadaniówka<br />
                </p>
                <br>
                <p>
                    <b>zakwaterowanie:</b><br />
                    w pokojach 2, 3, 4, 5 osobowych w <a href="https://orawcowa.pl/">Kompleksie Orawcowa</a><br />
                </p>
                <p>
                    jeździmy na <a href="https://www.zwardonski.pl/">Zwardoń Ski</a><br />
                </p>
                <br>
                <button style="display: block; margin-left: auto; margin-right: auto;" id="join-us-button"
                    class="button-round color-blue">jadę!</button>
            </div>
        </div>
    </div>
    <div class="col-sm-6 col-md-6 hidden-xs" style="height: 80vh; padding-left: 0px;">
        <img style="display: block; width:100%; height: 100%; object-fit: cover; margin: auto; margin-top: 0px; mari !important;"
            class="hidden-xs" src="assets/images/icons/zwardon_1.jpeg" alt="children">
        <br />
    </div>
</div>