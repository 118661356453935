<div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
</div>

<div class="row">
  <season-component class="col-md-2" (seasonChange)="onSeasonChange($event)"></season-component>
</div>

<div class="table-responsive">
  <table class="jumpcamp-table">
    <ng-container *ngIf="summary && summary.length">
      <thead>
        <tr>
          <th>Kategoria</th>
          <th *ngFor='let currency of currencies'>Kwota {{currency}}</th>
          <th>Estymacja razem</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let categorySummary of summary">
          <td>{{ categorySummary.category?.name }}</td>
          <td *ngFor="let currency of currencies">
            <span *ngIf="getAmount(categorySummary.amounts, currency)">

              {{ getAmount(categorySummary.amounts, currency).amount | number : '1.2-2' }} {{
              getAmount(categorySummary.amounts, currency).currency
              }}
            </span>
          </td>
          <td>{{ categorySummary.estimatedTotal?.amount | number : '1.2-2' }} {{
            categorySummary.estimatedTotal?.currency }}
        </tr>
      </tbody>
    </ng-container>
  </table>
</div>