import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { Instructor } from './instructor';
import { InstructorService } from './instructor.service';

@Injectable({
  providedIn: 'root'
})
export class InstructorResolver implements Resolve<Instructor> {

  constructor(private instructorService: InstructorService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<Instructor> {
    const id = route.paramMap.get('id');
    console.log(route)
    return this.instructorService.getInstructor(id);
  }
}
