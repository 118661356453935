import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.css']
})
export class AccountingComponent implements OnInit {
  pageTitle = 'Accounting';
  errorMessage: string;

  constructor(private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    // this.route.data.subscribe(data => {
    // });
  }
}
