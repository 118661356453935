import { Component, OnInit } from "@angular/core";
import { HttpClient } from '@angular/common/http'
import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

export class Info {
    content: string;
}

@Component({
    selector: "info-item",
    templateUrl: "info.component.html",
    styleUrls: []
})
export class InfoItem implements OnInit {
    public info: Info = new Info();
    errorMessage: string;

    constructor(private http: HttpClient) {
    }

    ngOnInit(): void {
        this.getInfo().subscribe(
            (info: Info) => {
                if (info) {
                    this.info = info;
                }
            },
            (error: any) => this.errorMessage = error
        );
    }

    onSave() {
        this.saveInfo().subscribe(
            (info: Info) => {
                document.location.href = location.origin;
            },
            (error: any) => this.errorMessage = error
        );
    }

    public saveInfo(): Observable<Info> {
        return this.http.put<Info>("/content/info/nextTrainings", this.info)
            .pipe(
                tap(data => console.log('update info')),
                catchError(this.handleError)
            );
    }

    public getInfo(): Observable<Info> {
        return this.http.get<Info>("/content/info/nextTrainings")
            .pipe(
                tap(data => console.log('get info')),
                catchError(this.handleError)
            );
    }

    private handleError(err: any) {
        // in a real world app, we may send the server to some remote logging infrastructure
        // instead of just logging it to the console
        let errorMessage: string;
        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            errorMessage = `Backend returned code ${err.status}: ${err.error}`;
        }
        console.error(err);
        return throwError(errorMessage);
    }
}