import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ClientService } from '../../client.service';

import { ClientGroup } from '../../client';
import { Recommendation } from './recommendation';

@Component({
  templateUrl: './client-group-detail-recommendations.component.html'
})

export class ClientGroupDetailRecommendationsComponent implements OnInit {
  pageTitle = 'Client Group Recommendations';
  clientGroup: ClientGroup;
  errorMessage: string;
  selectedSeasonId: string;
  recommendations: Recommendation[];

  public get sortedRecommendations(): Recommendation[] {
    return this.recommendations;
    // return this.recommendations.sort((b, a) => {
    //   return <any>new Date(b.creationDate) - <any>new Date(a.creationDate);
    // });
  }

  constructor(public route: ActivatedRoute,
    private clientService: ClientService
  ) {
  }

  ngOnInit(): void {
    this.route.parent.data.subscribe(data => {
      const dataName = 'clientGroup';
      this.clientGroup = data[dataName];
    });
  }

  getRecommendations(): void {
    this.clientService.getRecommendations(this.selectedSeasonId, this.clientGroup.id)
      .subscribe(
        (recommendations: Recommendation[]) => {
          this.recommendations = recommendations;
        },
        (error: any) => this.errorMessage = error
      );
  }

  onRecommendationDelete(recommendationId) {
    if (confirm("Are you sure to delete permanently?")) {
      this.clientService.deleteRecommendation(this.clientGroup.id, recommendationId).subscribe(value => {
        if (value) {
          this.recommendations = this.recommendations.filter(function (obj) {
            return obj.id !== recommendationId;
          });
        }
        else {
          this.errorMessage = "Something went wrong while deleting recommendation :(";
        }
      });
    }
  }

  onSeasonChange(seasonId: string): void {
    this.selectedSeasonId = seasonId;
    this.getRecommendations();
  }
}
