import { TrainingType } from '../trainings/trainingType'
import { PaymentInfo } from '../models/paymentInfo'

export class Season {
  id: string;
  name: string;
  from: Date;
  to: Date;
  instructorRates: Rate[];
  trainingPrices: Rate[];
}

export class Rate {
  trainingType: TrainingType;
  amount: PaymentInfo = new PaymentInfo();
}

export class Bill {
  id: string;
  creationDate: Date;
  name: string;
  contents: string | null;
  amount: PaymentInfo = new PaymentInfo();
  sent: boolean;
}

export class Payment {
  id: string;
  date: Date;
  amount: PaymentInfo = new PaymentInfo();
  wallet: Wallet = new Wallet();
  category: AccountingCategory = new AccountingCategory();
  description: string;
  season: Season;
}

export class Wallet {
  id: string;
  name: string;
}

export class AccountingCategory {
  id: string;
  name: string;
}