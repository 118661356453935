import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Season } from '../../season';
import { SeasonService } from '../../season.service';

@Component({
  templateUrl: './season-details-info.component.html'
})
export class SeasonDetailsInfoComponent implements OnInit {
  pageTitle = 'Season details';
  errorMessage = '';

  private currentSeason: Season;

  get season(): Season {
    return this.currentSeason;
  }

  set season(value: Season) {
    this.currentSeason = value;
  }

  constructor(private route: ActivatedRoute,
    private router: Router,
    private seasonService: SeasonService) { }

  ngOnInit(): void {
    // Watch for changes to the resolve data
    this.route.data.subscribe(data => {
      const dataName = 'season';
      this.onSeasonRetrieved(data[dataName]);
    });
  }

  onSeasonRetrieved(season: Season): void {
    this.season = season;
    this.pageTitle = `Sezon: ${this.season.name}`;
  }
}
