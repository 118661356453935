import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";

import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Instructor } from './instructor';
import { Training } from './training';
import { Rate, Bill, Payment } from '../seasons/season';

@Injectable({
  providedIn: 'root'
})
export class InstructorService {
  // private instructorsUrl = window.location.origin + '/api/instructors';
  private instructorsUrl = '/manager/instructors';

  constructor(private http: HttpClient) { }

  getInstructors(): Observable<Instructor[]> {
    return this.http.get<Instructor[]>(this.instructorsUrl)
      .pipe(
        tap(data => console.log(JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getInstructor(id: string): Observable<Instructor> {
    if (id === "") {
      return of(this.initializeInstructor());
    }
    const url = `${this.instructorsUrl}/${id}`;
    return this.http.get<Instructor>(url)
      .pipe(
        tap(data => console.log('Data: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getTrainings(seasonId: string, instructorId: string): Observable<Training[]> {
    const url = `${this.instructorsUrl}/${instructorId}/trainings?seasonId=${seasonId}`;
    return this.http.get<Training[]>(url)
      .pipe(
        tap(data => console.log('Data: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getRates(seasonId: string, instructorId: string): Observable<Rate[]> {
    const url = `${this.instructorsUrl}/${instructorId}/rates?seasonId=${seasonId}`;
    return this.http.get<Rate[]>(url)
      .pipe(
        tap(data => console.log('Data: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getBills(seasonId: string, instructorId: string): Observable<Bill[]> {
    const url = `${this.instructorsUrl}/${instructorId}/bills?seasonId=${seasonId}`;
    return this.http.get<Bill[]>(url)
      .pipe(
        tap(data => console.log('Data: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getPayments(seasonId: string, instructorId: string): Observable<Payment[]> {
    const url = `${this.instructorsUrl}/${instructorId}/payments?seasonId=${seasonId}`;
    return this.http.get<Payment[]>(url)
      .pipe(
        tap(data => console.log('Data: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  generateBill(instructorId: string, seasonId: string, month: Date): Observable<Bill> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post<Bill>(`${this.instructorsUrl}/${instructorId}/bills/generate?seasonId=${seasonId}&month=${month.toJSON()}`, { headers })
      .pipe(
        tap(data => console.log('createBill: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getBill(instructorId: string, billId: string): Observable<Bill> {
    const url = `${this.instructorsUrl}/${instructorId}/bills/${billId}`;
    return this.http.get<Bill>(url)
      .pipe(
        tap(data => console.log('Data: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  getPayment(instructorId: string, paymentId: string): Observable<Payment> {
    const url = `${this.instructorsUrl}/${instructorId}/payments/${paymentId}`;
    return this.http.get<Payment>(url)
      .pipe(
        tap(data => console.log('Data: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  saveBill(instructorId: string, seasonId: string, bill: Bill): Observable<Bill> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    if (bill.id === null || bill.id === "") {
      return this.createBill(instructorId, seasonId, bill, headers);
    }
    return this.updateBill(instructorId, bill, headers);
  }

  private createBill(instructorId: string, seasonId: string, bill: Bill, headers: HttpHeaders): Observable<Bill> {
    bill.id = null;
    return this.http.post<Bill>(`${this.instructorsUrl}/${instructorId}/bills?seasonId=${seasonId}`, bill, { headers })
      .pipe(
        tap(data => console.log('createBill: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private updateBill(instructorId: string, bill: Bill, headers: HttpHeaders): Observable<Bill> {
    const url = `${this.instructorsUrl}/${instructorId}/bills/${bill.id}`;
    return this.http.put<Bill>(url, bill, { headers })
      .pipe(
        tap(data => console.log('updateBill: ' + bill.id)),
        catchError(this.handleError)
      );
  }

  deleteBill(instructorId: string, billId: string): Observable<boolean> {
    const url = `${this.instructorsUrl}/${instructorId}/bills/${billId}`;
    return this.http.delete(url).pipe(map((res) => {
      console.log(res);
      return true;
    }));
  }

  deletePayment(instructorId: string, paymentId: string): Observable<boolean> {
    const url = `${this.instructorsUrl}/${instructorId}/payments/${paymentId}`;
    return this.http.delete(url).pipe(map((res) => {
      console.log(res);
      return true;
    }));
  }

  saveInstructor(instructor: Instructor): Observable<Instructor> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    if (instructor.id === "") {
      return this.createInstructor(instructor, headers);
    }
    return this.updateInstructor(instructor, headers);
  }

  deleteInstructor(id: string): Observable<{}> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    const url = `${this.instructorsUrl}/${id}`;
    return this.http.delete<Instructor>(url, { headers })
      .pipe(
        tap(data => console.log('deleteInstructor: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private createInstructor(instructor: Instructor, headers: HttpHeaders): Observable<Instructor> {
    instructor.id = null;
    return this.http.post<Instructor>(this.instructorsUrl, instructor, { headers })
      .pipe(
        tap(data => console.log('createInstructor: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private updateInstructor(instructor: Instructor, headers: HttpHeaders): Observable<Instructor> {
    const url = `${this.instructorsUrl}/${instructor.id}`;
    return this.http.put<Instructor>(url, instructor, { headers })
      .pipe(
        tap(data => console.log('updateInstructor: ' + instructor.id)),
        catchError(this.handleError)
      );
  }

  private initializeInstructor(): Instructor {
    // Return an initialized object
    return {
      id: '',
      firstName: '',
      lastName: '',
      dateOfBirth: null
      // approvalRating: null,
      // description: '',
      // director: '',
      // imageurl: '',
      // mpaa: '',
      // price: null,
      // releaseDate: '',
      // starRating: null,
      // title: '',
      // category: '',
      // tags: []
    };
  }

  savePayment(instructorId: string, seasonId: string, payment: Payment): Observable<Payment> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    if (payment.id === null || payment.id === "") {
      return this.createPayment(instructorId, seasonId, payment, headers);
    }
    return this.updatePayment(instructorId, payment, headers);
  }

  private createPayment(instructorId: string, seasonId: string, payment: Payment, headers: HttpHeaders): Observable<Payment> {
    payment.id = null;
    return this.http.post<Payment>(`${this.instructorsUrl}/${instructorId}/payments?seasonId=${seasonId}`, payment, { headers })
      .pipe(
        tap(data => console.log('createPayment: ' + JSON.stringify(data))),
        catchError(this.handleError)
      );
  }

  private updatePayment(instructorId: string, payment: Payment, headers: HttpHeaders): Observable<Payment> {
    const url = `${this.instructorsUrl}/${instructorId}/payments/${payment.id}`;
    return this.http.put<Payment>(url, payment, { headers })
      .pipe(
        tap(data => console.log('updatePayment: ' + payment.id)),
        catchError(this.handleError)
      );
  }

  private handleError(err: any) {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage: string;
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      errorMessage = `Backend returned code ${err?.status}: ${err?.body?.error}`;
    }
    console.error(err);
    return throwError(errorMessage);
  }

}
