import { Component, OnInit } from '@angular/core';
import { AuthService, LoginModel } from '../shared/auth.service';
import { Router } from "@angular/router";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    public errorMessage: string = "";

    constructor(
        private authService: AuthService,
        private router: Router
    ) {
        if (authService.isLoggedIn()) {
            router.navigate([""])
        }
    }

    public creds: LoginModel = {
        username: "",
        password: "",
        rememberMe: false
    };

    onLogin() {
        this.authService.login(this.creds)
            .subscribe(success => {
                if (success) {
                    this.router.navigate([""])
                } else {
                    this.router.navigate(["/"])
                }

            }, err => this.errorMessage = "Failed to login")
    }

    ngOnInit() {
    }
}
