import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ClientGroup } from '../../../clients/client';

@Component({
  templateUrl: './client-group-detail-info.component.html'
})
export class ClientGroupDetailInfoComponent implements OnInit {
  pageTitle = 'Client Group Detail';
  clientGroup: ClientGroup;
  errorMessage: string;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.parent.data.subscribe(data => {
      const dataName = 'clientGroup';
      this.clientGroup = data[dataName];
    });
  }
}