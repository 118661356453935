import { Injectable } from '@angular/core';
import { Resolve, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { Bundle } from '../../bundle';
import { SeasonService } from '../../season.service';

@Injectable({
  providedIn: 'root'
})
export class BundleResolver implements Resolve<Bundle> {

  constructor(
    private seasonService: SeasonService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<Bundle> {
    const bundleId = route.paramMap.get('bundleId');
    const seasonId = route.paramMap.get('seasonId');
    return this.seasonService.getBundle(seasonId, bundleId);
  }
}
