<div class="col-xs-12 no-padding">
    <div class="col-md-4 col-md-offset-2 login-box">
        <div *ngIf="errorMessage">{{errorMessage}}</div>
        <form id="login-form" method="post" (submit)="onLogin()" #theForm="ngForm" novalidate>
            <div></div>
            <div class="form-group">
                <label for="username">login</label>
                <input id="username" name="username" class="form-control custom-form-control"
                    [(ngModel)]="creds.username" #username="ngModel" required />
                <div class="text-danger" *ngIf="username.touched && username.invalid">Pole wymagane</div>
            </div>
            <div class="form-group">
                <label for="password">hasło</label>
                <input id="password" name="password" type="password" class="form-control custom-form-control"
                    [(ngModel)]="creds.password" #password="ngModel" required />
                <div class="text-danger" *ngIf="password.touched && password.invalid">Pole wymagane</div>
            </div>
            <div class="form-group">
                <div class="form-check">
                    <input id="remember-me" type="checkbox" class="form-check-input" />
                    <label for="remember-me" class="form-check-label">zapamiętać?</label>
                </div>
            </div>
            <div class="form-group">
                <input id="login-submit-button" type="submit" value="Zaloguj" [disabled]="theForm.invalid"
                    class="btn btn-success button-round color-blue" />
            </div>
        </form>
    </div>
</div>