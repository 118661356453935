<div class="card-body">

  <div class="wizard">
    <a [routerLink]="['info']" [routerLinkActive]="['is-active']">
      Info
    </a>
    |
    <a [routerLink]="['bundles']" routerLinkActive="is-active">
      Pakiety
    </a>
  </div>
  <br>
  <router-outlet></router-outlet>