import { Injectable } from '@angular/core';
import { Resolve, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { Payment } from '../../../seasons/season';
import { ClientService } from '../../client.service';

@Injectable({
  providedIn: 'root'
})

//TODO: Change to generic payments resolver
export class ClientGroupPaymentResolver implements Resolve<Payment> {

  constructor(
    private clientService: ClientService) { }

  resolve(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<Payment> {
    const paymentId = route.paramMap.get('paymentId');
    const ownerId = route.parent?.paramMap.get('id');
    // const instructorId = route.parent.data["instructor"].id;
    return this.clientService.getPayment(ownerId, paymentId);
  }
}
