import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';

import { Training } from '../training';
import { TrainingType } from '../../trainings/trainingType';
import { TrainingService } from '../training.service';
import { InstructorTraining, ToInstructorTraining } from '../instructor';
import { Instructor } from '../../instructors/instructor';
import { InstructorService } from '../../instructors/instructor.service';
import { Currency, PaymentInfo } from '../../models/paymentInfo'

//TODO: Change to ClientTraining model 
import { Client } from '../../clients/client';
import { ClientTraining, ToClientTraining } from '../client';
import { ClientService } from '../../clients/client.service';
import { ClientTrainingStatus, getStatus, absent } from '../client-training-status';

import { PlaceService } from '../../places/place.service';
import { Place } from '../../places/place';

@Component({
  templateUrl: './training-edit.component.html'
})

export class TrainingEditComponent implements OnInit {
  pageTitle = 'Training Edit';
  errorMessage = '';

  private currentTraining: Training;
  private originalTraining: Training;
  private seasonId: string;
  private dataIsValid: boolean = false;

  private allInstructors: InstructorTraining[];
  public filteredInstructors: InstructorTraining[];

  private allClients: ClientTraining[];
  public filteredClients: ClientTraining[];

  public allPlaces: Place[];

  newInstructor: InstructorTraining = new InstructorTraining();
  newClient: ClientTraining = new ClientTraining();

  get isDirty(): boolean {
    return JSON.stringify(this.originalTraining) !== JSON.stringify(this.currentTraining);
  }

  get training(): Training {
    return this.currentTraining;
  }

  set training(value: Training) {
    value.instructors.forEach(x => {
      if (!x.paymentCorrection) {
        x.paymentCorrection = new PaymentInfo();
      }
    });
    this.currentTraining = value;
    // Clone the object to retain a copy
    this.originalTraining = Object.assign({}, value);
  }

  public trainingTypes = Object.values(TrainingType);
  public currencies = Object.values(Currency);

  public clientStatuses = Object.values(ClientTrainingStatus);

  public getPresentClientsNumber() {
    var filtered = this.training.clients.filter((client: ClientTraining) =>
      !absent(client)
    );
    return filtered.length;
  }

  public levels = [1, 2.1, 2.2, 3.1, 3.2, 4, 5, 100];
  public getNumberOfClients(level: number) {
    var filtered = this.training.clients.filter((client: ClientTraining) =>
      client.level == level && !absent(client)
    );
    return filtered.length;
  }

  public updateClientStatusFront(client: ClientTraining, event: Event): ClientTraining {
    let status = (event.target as HTMLInputElement).value;
    return this.updateClientStatus(client, status as ClientTrainingStatus);
  }

  public updateClientStatus(client: ClientTraining, status: ClientTrainingStatus): ClientTraining {
    if (status === ClientTrainingStatus.Unspecified) {
      client.presence = null;
      client.catchUp = false;
      client.excused = false;
    }
    else if (status === ClientTrainingStatus.Absent) {
      client.presence = false;
      client.catchUp = false;
      client.excused = false;
    }
    else if (status === ClientTrainingStatus.CatchUp) {
      client.presence = true;
      client.catchUp = true;
      client.excused = false;
    }
    else if (status === ClientTrainingStatus.Excused) {
      client.presence = false;
      client.catchUp = false;
      client.excused = true;
    }
    else if (status === ClientTrainingStatus.Present) {
      client.presence = true;
      client.catchUp = false;
      client.excused = false;
    }
    return client;
  }

  public calculateEnd(from: Date): Date {
    var to = new Date(from);
    //Adds 2 hours
    to.setHours(to.getHours() + 2);
    return new Date(this.datePipe.transform(to, 'yyyy-MM-ddTHH:mm'));
  }

  public getClientStatus(client: ClientTraining): ClientTrainingStatus {
    return getStatus(client);
  }

  public absent(client: ClientTraining): boolean {
    return absent(client);
  }

  constructor(public route: ActivatedRoute,
    private router: Router,
    private trainingService: TrainingService,
    private clientService: ClientService,
    private placeService: PlaceService,
    private instructorService: InstructorService,
    private datePipe: DatePipe) { }

  removeInstructor(instructor: InstructorTraining): void {
    const index = this.training.instructors.indexOf(instructor, 0);
    if (index > -1) {
      this.training.instructors.splice(index, 1);
      this.performInstructorsFilter();
    }
  }

  addInstructor(): void {
    if (!this.newInstructor.id) {
      this.errorMessage = "Did not provide all instructor information.";
      return;
    }
    // if(this.newRate.salary.amount < 0){
    //   this.errorMessage = "Amount needs to be greater than 0.";
    //   return;
    // }
    if (this.training.instructors.find(r => r.id === this.newInstructor.id)) {
      this.errorMessage = `Instructor ${this.newInstructor.firstName} ${this.newInstructor.lastName} is already added to this training.`;
      return;
    }
    this.training.instructors.push(this.newInstructor);
    this.newInstructor = new InstructorTraining();
    this.performInstructorsFilter();
  }

  removeClient(client: ClientTraining): void {
    const index = this.training.clients.indexOf(client, 0);
    if (index > -1) {
      this.training.clients.splice(index, 1);
      this.performClientsFilter();
    }
  }

  addClient(): void {
    if (!this.newClient.id) {
      this.errorMessage = "Did not provide all client information.";
      return;
    }
    // if(this.newRate.salary.amount < 0){
    //   this.errorMessage = "Amount needs to be greater than 0.";
    //   return;
    // }
    if (this.training.clients.find(r => r.id === this.newClient.id)) {
      this.errorMessage = `Client ${this.newClient.firstName} ${this.newClient.lastName} is already added to this training.`;
      return;
    }
    this.training.clients.push(this.newClient);
    this.newClient = new ClientTraining();
    this.performClientsFilter();
  }

  ngOnInit(): void {
    // Watch for changes to the resolve data
    this.route.data.subscribe(data => {
      const dataName = 'training';
      this.onTrainingRetrieved(data[dataName]);
      if (this.isAddPage()) {
        this.route.queryParams.subscribe(params => {
          this.seasonId = params['seasonId'];
        });
      }
      this.getAllInstructors();
      this.getAllClients();
      this.getPlaces();
    });
  }

  getAllInstructors(): void {
    this.instructorService.getInstructors()
      .subscribe(
        (instructors: Instructor[]) => {
          this.allInstructors = instructors.map(x => ToInstructorTraining(x));
          this.performInstructorsFilter();
        },
        (error: any) => this.errorMessage = error
      );
  }

  getAllClients(): void {
    this.clientService.getAllClients()
      .subscribe(
        (clients: Client[]) => {
          this.allClients = clients.map(x => ToClientTraining(x));
          this.performClientsFilter();
        },
        (error: any) => this.errorMessage = error
      );
  }

  getPlaces(): void {
    this.placeService.getPlaces()
      .subscribe(
        (places: Place[]) => {
          this.allPlaces = places;
        },
        (error: any) => this.errorMessage = error
      );
  }

  getColor(level: number, opacity = 0.5) {
    switch (level) {
      case 0: {
        return `rgb(144, 12, 63, ${opacity})`;
      }
      case 1: {
        return `rgb(88, 24, 69, ${opacity})`;
      }
      case 2: {
        return `rgb(18, 55, 76, ${opacity})`;
      }
      case 2.1: {
        return `rgb(205, 92, 92, ${opacity})`;
      }
      case 2.2: {
        return `rgb(0, 139, 139, ${opacity})`;
      }
      case 3: {
        return `rgb(0, 102, 102, ${opacity})`;
      }
      case 3.1: {
        return `rgb(128, 0, 128, ${opacity})`;
      }
      case 3.2: {
        return `rgb(25, 25, 112, ${opacity})`;
      }
      case 4: {
        return `rgb(85, 111, 36, ${opacity})`;
      }
      case 5: {
        return `rgb(200, 179, 26, ${opacity})`;
      }
      case 6: {
        return `rgb(66, 40, 14, ${opacity})`;
      }
      default: {
        return `rgb(0, 0, 0, ${opacity})`;
      }
    }
  }

  performClientsFilter(): void {
    var filtered = this.allClients.filter((client: ClientTraining) =>
      !this.training.clients.some((trainingClient) => trainingClient.id === client.id)
    );
    filtered.forEach(x => this.updateClientStatus(x, ClientTrainingStatus.Unspecified));
    this.filteredClients = filtered;
  }

  // Local filter
  performInstructorsFilter(): void {
    var filtered = this.allInstructors.filter((instructor: InstructorTraining) =>
      !this.training.instructors.some((trainingInstructor) => trainingInstructor.id === instructor.id)
    );
    this.filteredInstructors = filtered;
  }
  //TODO: Add same error handling as with adding training to all pages (proper passing server errors)
  onTrainingRetrieved(training: Training): void {
    if (training === undefined) {
      this.training = {
        id: "",
        from: new Date(),
        to: new Date(),
        instructorTicketPrice: new PaymentInfo(),
        instructors: new Array<InstructorTraining>(),
        clients: new Array<ClientTraining>()
      } as Training;
      this.pageTitle = 'Dodaj trening';
    }
    else {
      this.training = training;
      if (this.training.instructorTicketPrice === null) {
        this.training.instructorTicketPrice = new PaymentInfo();
      }
      this.pageTitle = `Edytuj trening: ${this.training.id}`;
    }
  }

  isAddPage(): boolean {
    return this.training.id === "";
  }

  getReturnRoute(): string {
    let navigateTo = '../..';
    if (this.isAddPage()) {
      navigateTo = '..'
    }
    return navigateTo;
  }

  deleteTraining(): void {
    if (!this.training.id) {
      // Don't delete, it was never saved.
      this.onSaveComplete(`${this.training.id} was deleted`);
    } else {
      if (confirm(`Czy na pewno usunąć trening '${this.training.id}'?`)) {
        this.trainingService.deleteTraining(this.training.id).subscribe(
          () => this.onSaveComplete(`${this.training.id} was deleted`),
          (error) => this.errorMessage = error
        );
      }
    }
  }

  isValid(): boolean {
    this.validate();
    return this.dataIsValid;
  }

  compareFn = this._compareFn.bind(this);

  _compareFn(a, b) {
    // Handle compare logic (eg check if unique ids are the same)
    return (a && b && a.id === b.id) || (!a && !b);
  }

  onPlaceChange(place: Place) {
    this.training.place = place;
    this.training.instructorTicketPrice = JSON.parse(JSON.stringify(place.singleTicketPrice));
  }

  saveTraining(): void {
    if (this.isValid()) {
      this.trainingService.saveTraining(this.seasonId, this.training).subscribe(
        () => this.onSaveComplete(`${this.training.id} was saved`),
        (error) => this.errorMessage = error
      );
    } else {
      this.errorMessage = 'Please correct the validation errors.';
    }
  }

  onSaveComplete(message?: string): void {
    console.log(message);
    let returnTo = this.getReturnRoute();
    this.reset();
    // Navigate back to the season list
    this.router.navigate([returnTo], { relativeTo: this.route });
  }

  // Reset the data
  // Required after a save so the data is no longer seen as dirty.
  reset(): void {
    this.dataIsValid = null;
    this.currentTraining = null;
    this.originalTraining = null;
  }

  validate(): void {
    // Clear the validation object
    this.dataIsValid = false;
    if (true
    ) {
      //TODO: Add validation!
      this.dataIsValid = true;
    }
    // else {
    //   this.dataIsValid = false;
    // }
  }
}
