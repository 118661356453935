import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Currency, PaymentInfo } from '../../../models/paymentInfo'
import { Client } from '../../client';
import { ClientService } from '../../client.service';
import { parseDate } from '../../../utils';

@Component({
  templateUrl: './client-group-detail-persons-edit.component.html'
})
export class ClientGroupDetailClientEditComponent implements OnInit {
  pageTitle = 'Client Edit';
  errorMessage = '';

  private currentClient: Client;
  private originalClient: Client;
  private dataIsValid: boolean = false;

  public clientGroupId: string;

  parseDate(event: Event): Date | null {
    let value = (event.target as HTMLInputElement).value;
    return parseDate(value)
  }

  get isDirty(): boolean {
    return JSON.stringify(this.originalClient) !== JSON.stringify(this.currentClient);
  }

  get client(): Client {
    return this.currentClient;
  }

  set client(value: Client) {
    this.currentClient = value;
    // Clone the object to retain a copy
    this.originalClient = Object.assign({}, value);
  }

  constructor(public route: ActivatedRoute,
    private router: Router,
    private clientService: ClientService) { }

  public currencies = Object.values(Currency);

  ngOnInit(): void {
    // Watch for changes to the resolve data
    this.route.parent.data.subscribe(data => {
      this.clientGroupId = data['clientGroup'].id;
    })
    this.route.data.subscribe(data => {
      const dataName = 'client';
      this.onClientRetrieved(data[dataName]);
    });
  }

  onClientRetrieved(client: Client): void {
    if (client === undefined) {
      this.client = {
        id: "",
        firstName: "",
        lastName: ""
      } as Client;
      this.pageTitle = 'Nowa osoba';
    }
    else {
      this.client = client;
      this.pageTitle = `Edytuj osobę: ${this.client.firstName} ${this.client.lastName}`;
    }
  }

  isAddPage(): boolean {
    return this.client.id === null || this.client.id === "";
  }

  getReturnRoute(): string {
    let navigateTo = '../..';
    if (this.isAddPage()) {
      navigateTo = '..'
    }
    return navigateTo;
  }

  deleteClient(): void {
    if (!this.client.id) {
      // Don't delete, it was never saved.
      this.onSaveComplete(`${this.client.firstName} ${this.client.lastName} was deleted`);
    } else {
      if (confirm(`Czy na pewno usunąć osobę '${this.client.firstName} ${this.client.lastName}'?`)) {
        this.clientService.deleteClient(this.clientGroupId, this.client.id).subscribe(
          () => this.onSaveComplete(`${this.client.firstName} ${this.client.lastName} was deleted`),
          (error) => this.errorMessage = error
        );
      }
    }
  }

  isValid(): boolean {
    this.validate();
    return this.dataIsValid;
  }

  saveClient(): void {
    if (this.isValid()) {
      this.clientService.saveClient(this.clientGroupId, this.client).subscribe(
        () => this.onSaveComplete(`${this.client.firstName} ${this.client.lastName} was saved`),
        (error) => this.errorMessage = error
      );
    } else {
      this.errorMessage = 'Please correct the validation errors.';
    }
  }

  onSaveComplete(message?: string): void {
    console.log(message);
    var goBackTo = this.getReturnRoute();
    this.reset();
    // Navigate back to the clients list
    this.router.navigate([goBackTo], { relativeTo: this.route });
  }

  // Reset the data
  // Required after a save so the data is no longer seen as dirty.
  reset(): void {
    this.dataIsValid = null;
    this.currentClient = null;
    this.originalClient = null;
  }

  validate(): void {
    // Clear the validation object
    this.dataIsValid = false;
    if (this.client.firstName &&
      this.client.firstName.length >= 3 &&
      this.client.firstName.length <= 50 &&
      this.client.lastName &&
      this.client.lastName.length >= 3 &&
      this.client.lastName.length <= 50
      //TODO: Add validation
    ) {
      this.dataIsValid = true;
    } else {
      this.dataIsValid = false;
    }
  }
}
