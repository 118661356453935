<div class="card">
  <div class="card-header">
    {{pageTitle}}
  </div>
  <br>
  <div class="card-body">
    <div class="row">
      <label class="col-md-2">Kogo</label>
      <select class="jumpcamp-input" matNativeControl [(ngModel)]="recommendation.whom.id">
        <option *ngFor="let clientGroup of filteredClientGroups" [value]="clientGroup.id">
          {{clientGroup.name}}
        </option>
      </select>
    </div>
    <br>

    <div class="row">
      <div class="col-md-4">
        <button class="btn btn-success mr-3" type="button" style="width:80px" [disabled]="!isValid()"
          (click)="saveRecommendation()">
          Zapisz
        </button>
        <button class="btn btn-warning mr-3" type="button" style="width:80px" [routerLink]="[getReturnRoute()]"
          [relativeTo]="route">
          Anuluj
        </button>
        <button class="btn btn-danger" type="button" style="width:80px" (click)="deleteRecommendation()"
          *ngIf="!isAddPage()">
          Usuń
        </button>
      </div>
    </div>
  </div>
</div>

<div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
</div>