<div class="col-xs-12 no-padding">
    <div class="col-xs-12 col-sm-6 col-md-6 offer-content holiday-content" style="height: 80vh; padding-top: 0px;">
        <div class="row style-2" style="overflow: auto; height: 100%; direction: rtl; padding-bottom: 20px;">
            <div style="direction: ltr; padding-left: 10px;">
                <br />
                <h1 class="holiday-title" style="color: #0071b9;">12-19.03.2023</h1>
                <h2 class="holiday-title" style="color: #0071b9">
                    rodzinna przygoda polarna za kołem podbiegunowym
                    czyli skiturowa Norwegia w Lyngen
                </h2>
                <br />
                <p>
                    Gdy u nas zacznie się już wiosna, my ruszamy na daleką północ gdzie czekają na nas:
                <ul>
                    <li>
                        zakwaterowanie w <a href="https://xlyngen.no/shop/produktkategori/apartments/">domkach</a>
                        6-osobowych nad wodą z widokiem na zatokę i góry
                    </li>
                    <li>transport z lotniska z przeprawą promową</li>
                    <li>wycieczki skiturowe</li>
                    <li>
                        ocena stabilności pokrywy śnieżnej z wykorzystaniem profilu śnieżnego
                    </li>
                    <li>
                        zabawy z detektorem lawinowym dla dzieci i dorosłych
                    </li>
                    <li>
                        wycieczka na odległą latarnię morską
                    </li>
                    <li>budowa jamy śnieżnej</li>
                    <li>zbieranie i gotowanie muli</li>
                    <li>morsowanie w morzu i sauna</li>
                    <li>polowanie na Zorzę Polarną</li>
                    <li>zwiedzanie Tromso</li>
                    <li>opieka trenerów</li>
                </ul>

                <p>
                    Cena:<br />
                    &ensp;<span class="hidden-xs">&emsp;&emsp;</span> 4970 pln / osoba *<br />
                    Dla tych, którzy góry planują podziwiać tylko znad morza i wygrzewać się w saunie:<br />
                    &ensp;<span class="hidden-xs">&emsp;&emsp;</span> 2970 pln / osoba *<br />
                    <br />
                    Ubezpieczenie oraz transport na miejscu wliczone w cenie.<br />
                    *Cena przy pełnym obłozeniu domku. Cena nie obejmuje lotu do Tromso oraz wyżywienia.
                </p>
                <br>
                Jakbyście chcieli zobaczyć jak było rok temu, to zajrzyjcie do naszej <a
                    href='/Home/Gallery'>galerii</a>.
                <br>
                <br>
                <button style="display: block; margin-left: auto; margin-right: auto;" id="join-us-button"
                    class="button-round color-blue">jadę!</button>
            </div>
        </div>
    </div>
    <div class="col-sm-6 col-md-6 hidden-xs" style="height: 80vh; padding-left: 0px;">
        <img style="display: block; width:100%; height: 100%; object-fit: cover; margin: auto; margin-top: 0px; mari !important;"
            class="hidden-xs" src="assets/images/icons/lyngen_1.jpg" alt="lyngen">
        <br />
    </div>
</div>