<div class="card">
  <div class="card-header">
    {{pageTitle}}
  </div>
  <br>
  <div class="card-body">
    <div>Cena karnetu: {{ place.singleTicketPrice.amount }} {{ place.singleTicketPrice.currency }}</div>
  </div>
</div>

<div class="alert alert-danger" *ngIf="errorMessage">{{errorMessage}}
</div>